// import React, {useState, useEffect, useMemo} from 'react';
// import {Input, Form, Modal, Button, List, Typography} from 'antd';
// import { useGetData } from './useAxios';

// export default (props) => {
// 	let {record, setRecord, name, title} = props;
// 	let [show,setShow] = useState(false);
// 	let [attendees, loading,,refresh] = useGetData('attendees');
// 	let [joins, setJoins] = useState(null);

// 	useEffect(() => {
// 		if(joins != record[name]){
// 			setJoins(record[name]);
// 		}
// 	}, [record, name]);

// 	useEffect(() => {
// 		if(joins != null && joins.length > 0)
// 			refresh({sort: '_id', fields: ['name', '_id'], query:{_id: {$in: joins.map(x => x.id)} }});
// 	}, [joins]);

// 	let atten

// 	if(!record._id) return null;
// 	else return  <Form.Item >
// 		{(record[name] && attendees) && <>
// 			<Modal visible={show} title='Katılımcılar'
// 				footer={[<Button key='close' onClick={() => setShow(false)} >Kapat</Button>]} >
// 				<List dataSource={attendees.rows} key='_id' style={{margin: 20}}
// 					renderItem={(item,i) => 
// 						<List.Item 
// 						><span style={{fontWeight: 'bold'}}>{(i+1) + '. '}</span>{item._id + ' \t ' + item.name}</List.Item>
// 						// <List.Item actions={[<Button icon='delete' type='link' style={{color: '#ff4d4f'}}
// 						// 	onClick={() => setRecord({...record, [name] : record[name].filter(x => x !== item._id ) })} >{languagesCms.DELETE}</Button>]}
// 						// ><span style={{fontWeight: 'bold'}}>{(i+1) + '. '}</span>{item._id + ' \t ' + item.name}</List.Item>
// 					} />
// 			</Modal>
// 			<Button onClick={() => setShow(true)} >Katılımcıları Göster</Button>
// 		</>
// 		}
// 		{!record[name] && <Typography.Text>Katılımcı bulunmuyor</Typography.Text>}
			
// 	</Form.Item>;
// };