import { Layout, Menu } from 'antd';
import React, { useEffect, useGlobal } from 'reactn';
import {
  MdAddLocationAlt,
  MdCircleNotifications,
  MdEditCalendar,
  MdEvent,
  MdEventAvailable,
  MdEventSeat,
  MdMeetingRoom,
  MdNotificationImportant,
  MdOutlineShareLocation,
  MdSettingsSuggest,
  MdSupervisedUserCircle
} from 'react-icons/md';
import { Link } from 'react-router-dom';

import { GiSaloon } from 'react-icons/gi';
import { RiQrCodeFill,RiAdminFill } from 'react-icons/ri';

import { MdCorporateFare } from 'react-icons/md';
import { TbReportAnalytics } from 'react-icons/tb';
import lang from "../lang"

const { Sider } = Layout;

const menu_items = [
  {
    type: "companies",
    name: lang.companies,
    order: 0,
    icon: <MdCorporateFare />,
  },
  {
    type: 'room',
    name: lang.rooms,
    order: 1,
    icon: <GiSaloon />,
  },
  {
    type: 'user',
    name: lang.users,
    order: 2,
    icon: <MdSupervisedUserCircle />,
  },
  {
    type: 'admin',
    name: lang.managers,
    order: 3,
    icon: <RiAdminFill />,
  },
  {
    type: 'qr',
    name: lang.Report,
    order: 4,
    icon: <RiQrCodeFill />,
  },
];

const SideMenu = ({ testFunc }) => {
  const [collapsed, setCollapsed] = useGlobal('collapsed');
  let [isSmall] = useGlobal('isSmall');
  let [modules] = useGlobal('modules');
  let [user] = useGlobal('user');
  let [me] = useGlobal('me');


  let _menu_items = [];
  
  if(user.superAdmin) {
    _menu_items = menu_items
  } else { 
    _menu_items = menu_items.filter(x => x.type != "companies")
  }

  _menu_items.forEach( (m,i) => {
    m.order = i;
  })


  let page = window.location.pathname.split("/")[1];
  let index = _menu_items.map(a => a.type).indexOf(page);
  if( index == -1)
    index = 0;

  // function tabTitle(titleName) {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  //   document.title = 'Elektron - ' + titleName;
  // }

  const getMenu = () => {
    function getItem(label, key, icon, children, type) {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    }

    const arr = [];



    // grup olmayanları döner
    for (const item of _menu_items) {
      arr.push(
        getItem(
          <Link to={'/' + item.type}>{item.name}</Link>,
          item.order,
          item.icon,
        ),
      );
    }
    return arr;
  };

  console.log({me})

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      theme="light"
      width={isSmall ? '300' : '260'}
    >
      <div className="logo-wrap">
        <div className="logo">
          <a href="/">
            <img alt=""  src={me.company_image} />
          </a>
          
          {/* <span className="logo-text">{me.company_name}</span> */}
        </div>
        
        <div className="logomini">
          <a href="/">
            <img alt="" src={me.company_image} />
          </a>
        </div>
      </div>

      <Menu
        defaultSelectedKeys={[ index.toString() ]}
        // defaultOpenKeys={['0']}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
        items={getMenu()}
      />
    </Sider>
  );
};

export default SideMenu;
