import React, { useEffect, useGlobal } from 'reactn';
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import SideMenu from './layout/SideMenu';
import HeaderBar from './layout/HeaderBar';

import {
  Room,
  RoomDetail,
  Admins,
  AdminsDetail,
  User,
  UserDetail,
  NotFoundPage,
  Companies,
  CompaniesDetail,
} from './pages';
import api from './service';
import QR from './pages/QR';

const { Header, Content } = Layout;

const Admin = (props) => {
  let [token, setToken] = useGlobal('token');
  let [user] = useGlobal('user');
  let [me, setMe] = useGlobal('me');
  let [isSmall, setIsSmall] = useGlobal('isSmall');
  let [collapsed, setCollapsed] = useGlobal('collapsed');

  useEffect(() => {
    window.onresize = () => {
      setIsSmall(window.innerWidth < 1024);
    };
  }, [setIsSmall, setToken]);
  useEffect(() => {
    if (isSmall) setCollapsed(true);
    else setCollapsed(false);
  }, [isSmall, setCollapsed]);

  if (!token || !user) {
    props.history.push('/login');
  }

  useEffect(()=> {
    api.post("/rest/admin/me", {}).then(response => {
      setMe(response.data.result)
    })
  }, [token])

  if (me == null) {
    return <div></div>
  }


  return (
    <Switch>
      <Route>
        <Layout>
          <SideMenu collapsed={collapsed} />
          <Layout className="site-layout" style={{ minHeight: '100vh' }}>
            <Header
              className="site-layout-background header"
              style={{ padding: '0 20px' }}
              theme="dark"
            >
              <HeaderBar />
            </Header>

            <Content key="page" className="main-wrap">
              {token && (
                <>
                  <Switch>
                  <Route exact path="/qr" component={QR} />

                    <Route exact path="/user" component={User} />
                    <Route exact path="/user/edit/:id" component={UserDetail} />
                    <Route exact path="/user/add" component={UserDetail} />

                    <Route exact path="/" component={Room} />

                    <Route exact path="/room" component={Room} />
                    <Route exact path="/room/edit/:id" component={RoomDetail} />
                    <Route exact path="/room/add" component={RoomDetail} />

                    <Route exact path="/admin/" component={Admins} />
                    <Route
                      exact
                      path="/admin/edit/:id"
                      component={AdminsDetail}
                    />
                    <Route exact path="/admin/add" component={AdminsDetail} />

                    <Route exact path="/companies" component={Companies} />
                    <Route exact path="/companies/add" component={CompaniesDetail} />
                    <Route exact path="/companies/edit/:id" component={CompaniesDetail} />
                    

                    <Route path="*" component={NotFoundPage} />
                  </Switch>
                </>
              )}
            </Content>
          </Layout>
        </Layout>
      </Route>
    </Switch>
  );
};

export default Admin;
