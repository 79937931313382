import React from 'react';

const noAuthorization = () => {
  return (
    <div
      style={{
        height: '75vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h2
        style={{
          display: 'flex !important',
          justifyContent: 'center',
          alignItems: 'center !important',
          fontSize: '30px',
          color: 'red',
        }}
      >
        Bu Sayfaya Erişim Yetkiniz Bulunmamaktadır !
      </h2>
    </div>
  );
};

export default React.memo(noAuthorization);
