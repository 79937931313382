import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name, mode, disabled } = props;
  let [prices, setPrices] = useState([]);
  let _params = { sort: '-createdAt', page: 1, pageSize: 100, stationId: 1 };
  let languagesCms = props.languagesCms;

  useEffect(() => {
    api.get(`/rest/prices?${qs.stringify(_params)}`).then(({ data }) => {
      if (data && data.result) {
        let dbPrices = data.result.sort((a, b) => a.name.localeCompare(b.name));
        setPrices(dbPrices);
      }
    });
  }, [record]);

  return (
    <Select
      value={record[name] || []}
      showSearch={true}
      mode={mode}
      placeholder={languagesCms.SELECT_PRICES}
      onChange={(v) => setRecord({ ...record, [name]: v, districtId: null })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled || false}
    >
      {prices &&
        prices.map((b) => (
          <Select.Option key={b.name} value={b._id}>
            {b.name + ' — ' + b.price + b.price_type + ' — ' + b.power + 'kW'}
          </Select.Option>
        ))}
    </Select>
  );
};
