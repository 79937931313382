import { DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Checkbox, Col, Divider, Form, Popconfirm, Row, Select, Space, Switch, Table } from 'antd';
import { Excel } from "antd-table-saveas-excel";
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useGlobal, useState } from 'react';
import { TbReportAnalytics } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import Spinner from '../components/Loading';
import api from '../service/index';
import { getPathName, turkishSort } from '../stores/utils';
const { Option } = Select;

function Report(props) {
  const [search, setSearch] = useState(false);
  const [roomReservations, setRoomReservations] = useState([]);
  const [eventReservations, setEventReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [users, setUsers] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [events, setEvents] = useState([]);
  
  let [data, setData] = useState([]);

  let path = props.location.pathname.split('/')[1];
	let pathName = getPathName(path);

  const query = [];


  const get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 1000 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name, email, phone';
    }
  

    api
      .get(`/rest/eventReservation?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          setTotalCount(data.result.total);
          setEventReservations(data.result.rows);
        }
      })
      .then(() => {
        setLoading(false);
      });

    api
      .get(`/rest/roomReservation?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          setTotalCount(data.result.total);
          setRoomReservations(data.result.rows);
        }
      })
      .then(() => {
        setLoading(false);
      });
  };




  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    }
  };

  useEffect(() => {
    setLoading(true);
    get();
  }, []);

  


  const eventReservationReport = async(row) => {
    // 1 aylık raporlar
    let type = row.data === "Etkinlik" ? "event" : "room";
    if(type === "event"){
      api
      .post(`/rest/eventReservation/eventReservationReport`,{data:"weekly"})
      .then(({ data }) => {
        console.log(data.result)
        if (data && data.result ) {
          const excel = new Excel();
          excel
            .addSheet("Haftalık Etkinlik Rezervasyon Raporu")
            .addColumns([
              { title: "Etkinlik Adı", key: "eventName", dataIndex: "eventName",excelRender: (eventName) => eventName ? eventName : "-"},
              { title: "Kullanıcı", key: "name", dataIndex: "name"},
              { title: "Telefon", key: "userPhone", dataIndex: "userPhone", excelRender: (userPhone) => userPhone ? userPhone : "-"},
              { title: "E-posta", key: "userEmail", dataIndex: "userEmail", excelRender: (userEmail) => userEmail ? userEmail : "-"},
              { title: "Ücret", key: "totalprice", dataIndex: "totalprice",excelRender: (totalprice) => totalprice + " ₺"},
              { title: "Ödeme Türü", key: "pay", dataIndex: "pay",excelRender: (pay) => pay ? pay : "-"},
              { title: "Katılım Sayısı", key: "count", dataIndex: "count",excelRender: (count) => count + " Kişi"},
              { title: "Rezervasyon Tarihi", key: "createdAt", dataIndex: "createdAt",excelRender: (createdAt) => moment(createdAt).format('DD.MM.YYYY HH:mm')},
              { title: "Etkinlik Lokasyonu", key: "eventlocation", dataIndex: "eventlocation",excelRender: (eventlocation) => eventlocation ? eventlocation.address : "-"},
              { title: "Ödeme Tarihi", key: "payDate", dataIndex: "payDate",excelRender: (payDate) => payDate ? moment(payDate).format('DD.MM.YYYY HH:mm') : "-"},
              { title: "Rezervasyon Numarası", key: "reservationNumber", dataIndex: "reservationNumber",excelRender: (reservationNumber) => reservationNumber ? reservationNumber : "-"},
            ])
            .addDataSource(data.result, {
              excelStyle: (item) => {
                return {
                  alignment: {
                    vertical: "middle",
                    horizontal: "center",
                  },
                };
              }
            })
            .saveAs(`Haftalık Etkinlik Rezervasyon Raporu.xlsx`);
                
        }
      })
    } else {
      api
      .post(`/rest/eventReservation/roomReservationReport`,{data:"weekly"})
      .then(({ data }) => {
        if(data && data.result && data.result.rows){
          const excel = new Excel();
          excel.addSheet("Haftalık Oda Rezervasyon Raporu")
            .addColumns([
              { title: "Oda Adı", key: "roomName", dataIndex: "roomName"},
              { title: "Kullanıcı", key: "name", dataIndex: "name"},
              { title: "Telefon", key: "userPhone", dataIndex: "userPhone", excelRender: (userPhone) => userPhone ? userPhone : "-"},
              { title: "E-posta", key: "userEmail", dataIndex: "userEmail", excelRender: (userEmail) => userEmail ? userEmail : "-" },
              { title: "Başlangıç Saati", key: "startTime", dataIndex: "startTime",excelRender: (startTime) => startTime ? moment(startTime).format("DD.MM.YYYY HH:mm") === "Invalid date" ?  startTime : moment(startTime).format("DD.MM.YYYY HH:mm") : "-"},
              { title: "Bitiş Saati", key: "endTime", dataIndex: "endTime",excelRender: (endTime) => endTime ? moment(endTime).format("DD.MM.YYYY HH:mm") === "Invalid date" ? endTime : moment(endTime).format("DD.MM.YYYY HH:mm") : "-"},
              { title: "Ücret", key: "totalprice", dataIndex: "totalprice",excelRender: (totalprice) => totalprice + " ₺"},
              { title: "Ödeme Yapıldı Mı?", key: "pay", dataIndex: "pay",excelRender: (pay) => pay ? "Evet" : "Hayır"}, 
              { title: "Oda Lokasyonu", key: "address", dataIndex: "address",excelRender: (address) => address ? address : "-"},
              { title: "Ödeme Tarihi", key: "payDate", dataIndex: "payDate",excelRender: (payDate) => payDate ? moment(payDate).format("DD.MM.YYYY HH:mm") === "Invalid date" ? payDate : moment(payDate).format("DD.MM.YYYY HH:mm") : "-"},
              { title: "Rezervasyon Numarası", key: "reservationNumber", dataIndex: "reservationNumber",excelRender: (reservationNumber) => reservationNumber ? reservationNumber : "-"},
            ])
            .addDataSource(roomReservations, {
              str2Percent: true,
            })
            .saveAs("Haftalık Oda Rezervasyon Raporu.xlsx");
          }

      })
    }
    
  }

  const oneMonthReport = async(row) => {
    let type = row.data === "Etkinlik" ? "event" : "room";
    let _params = { type: type, period: "month" };
    if(type === "event"){
      api
      .post(`/rest/eventReservation/eventReservationReport`,{data:"onemonth"})
      .then(({ data }) => {
        if (data && data.result) {
          const excel = new Excel();
          excel
            .addSheet("1 Aylık Etkinlik Rezervasyon Raporu")
            .addColumns([
              { title: "Etkinlik Adı", key: "eventName", dataIndex: "eventName",excelRender: (eventName) => eventName ? eventName : "-"},
              { title: "Kullanıcı", key: "name", dataIndex: "name"},
              { title: "Telefon", key: "userPhone", dataIndex: "userPhone", excelRender: (userPhone) => userPhone ? userPhone : "-"},
              { title: "E-posta", key: "userEmail", dataIndex: "userEmail", excelRender: (userEmail) => userEmail ? userEmail : "-"},
              { title: "Ücret", key: "totalprice", dataIndex: "totalprice",excelRender: (totalprice) => totalprice + " ₺"},
              { title: "Ödeme Türü", key: "pay", dataIndex: "pay",excelRender: (pay) => pay ? pay : "-"},
              { title: "Katılım Sayısı", key: "count", dataIndex: "count",excelRender: (count) => count + " Kişi"},
              { title: "Rezervasyon Tarihi", key: "createdAt", dataIndex: "createdAt",excelRender: (createdAt) => moment(createdAt).format('DD.MM.YYYY HH:mm')},
              { title: "Etkinlik Lokasyonu", key: "eventlocation", dataIndex: "eventlocation",excelRender: (eventlocation) => eventlocation ? eventlocation.address : "-"},
              { title: "Ödeme Tarihi", key: "payDate", dataIndex: "payDate",excelRender: (payDate) => payDate ? moment(payDate).format('DD.MM.YYYY HH:mm') : "-"},
              { title: "Rezervasyon Numarası", key: "reservationNumber", dataIndex: "reservationNumber",excelRender: (reservationNumber) => reservationNumber ? reservationNumber : "-"},
            ])
            .addDataSource(data.result, {
              str2Percent: true,
            })
            .saveAs(`1 Aylık Etkinlik Rezervasyon Raporu.xlsx`);
                
        }
      })
    } else {
      api
      .post(`/rest/eventReservation/roomReservationReport`,{data:"onemonth"})
      .then(({ data }) => {
        if(data && data.result && data.result.rows){
          const excel = new Excel();
          excel.addSheet("1 Aylık Oda Rezervasyon Raporu")
            .addColumns([
              { title: "Oda Adı", key: "roomName", dataIndex: "roomName"},
              { title: "Kullanıcı", key: "name", dataIndex: "name"},
              { title: "Telefon", key: "userPhone", dataIndex: "userPhone", excelRender: (userPhone) => userPhone ? userPhone : "-"},
              { title: "E-posta", key: "userEmail", dataIndex: "userEmail", excelRender: (userEmail) => userEmail ? userEmail : "-" },
              { title: "Başlangıç Saati", key: "startTime", dataIndex: "startTime",excelRender: (startTime) => startTime ? moment(startTime).format("DD.MM.YYYY HH:mm") === "Invalid date" ?  startTime : moment(startTime).format("DD.MM.YYYY HH:mm") : "-"},
              { title: "Bitiş Saati", key: "endTime", dataIndex: "endTime",excelRender: (endTime) => endTime ? moment(endTime).format("DD.MM.YYYY HH:mm") === "Invalid date" ? endTime : moment(endTime).format("DD.MM.YYYY HH:mm") : "-"},
              { title: "Ücret", key: "totalprice", dataIndex: "totalprice",excelRender: (totalprice) => totalprice + " ₺"},
              { title: "Ödeme Yapıldı Mı?", key: "pay", dataIndex: "pay",excelRender: (pay) => pay ? "Evet" : "Hayır"}, 
              { title: "Oda Lokasyonu", key: "address", dataIndex: "address",excelRender: (address) => address ? address : "-"},
              { title: "Ödeme Tarihi", key: "payDate", dataIndex: "payDate",excelRender: (payDate) => payDate ? moment(payDate).format("DD.MM.YYYY HH:mm") === "Invalid date" ? payDate : moment(payDate).format("DD.MM.YYYY HH:mm") : "-"},
              { title: "Rezervasyon Numarası", key: "reservationNumber", dataIndex: "reservationNumber",excelRender: (reservationNumber) => reservationNumber ? reservationNumber : "-"},
            ])
            .addDataSource(roomReservations, {
              str2Percent: true,
            })
            .saveAs("1 Aylık Oda Rezervasyon Raporu.xlsx");
          }

      })
    }
  }

  const threeMonthReport = async(row) => {
    let type = row.data === "Etkinlik" ? "event" : "room";
    let _params = { type: type, period: "threemonth" };
    if(type === "event"){
      api
      .post(`/rest/eventReservation/eventReservationReport`,{data:"threemonth"})
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          const excel = new Excel();
          excel
            .addSheet("3 Aylık Etkinlik Rezervasyon Raporu")
            .addColumns([
              { title: "Etkinlik Adı", key: "eventName", dataIndex: "eventName",excelRender: (eventName) => eventName ? eventName : "-"},
              { title: "Kullanıcı", key: "name", dataIndex: "name"},
              { title: "Telefon", key: "userPhone", dataIndex: "userPhone", excelRender: (userPhone) => userPhone ? userPhone : "-"},
              { title: "E-posta", key: "userEmail", dataIndex: "userEmail", excelRender: (userEmail) => userEmail ? userEmail : "-"},
              { title: "Ücret", key: "totalprice", dataIndex: "totalprice",excelRender: (totalprice) => totalprice + " ₺"},
              { title: "Ödeme Türü", key: "pay", dataIndex: "pay",excelRender: (pay) => pay ? pay : "-"},
              { title: "Katılım Sayısı", key: "count", dataIndex: "count",excelRender: (count) => count + " Kişi"},
              { title: "Rezervasyon Tarihi", key: "createdAt", dataIndex: "createdAt",excelRender: (createdAt) => moment(createdAt).format('DD.MM.YYYY HH:mm')},
              { title: "Etkinlik Lokasyonu", key: "eventlocation", dataIndex: "eventlocation",excelRender: (eventlocation) => eventlocation ? eventlocation.address : "-"},
              { title: "Ödeme Tarihi", key: "payDate", dataIndex: "payDate",excelRender: (payDate) => payDate ? moment(payDate).format('DD.MM.YYYY HH:mm') : "-"},
              { title: "Rezervasyon Numarası", key: "reservationNumber", dataIndex: "reservationNumber",excelRender: (reservationNumber) => reservationNumber ? reservationNumber : "-"},
            ])
            .addDataSource(data.result, {
              str2Percent: true,
            })
            .saveAs(`3 Aylık Etkinlik Rezervasyon Raporu.xlsx`);
                
        }
      })
    } else {
      api
      .post(`/rest/eventReservation/roomReservationReport`,{data:"threemonth"})
      .then(({ data }) => {
        if(data && data.result && data.result.rows){
          const excel = new Excel();
          excel.addSheet("3 Aylık Oda Rezervasyon Raporu")
            .addColumns([
              { title: "Oda Adı", key: "roomName", dataIndex: "roomName"},
              { title: "Kullanıcı", key: "name", dataIndex: "name"},
              { title: "Telefon", key: "userPhone", dataIndex: "userPhone", excelRender: (userPhone) => userPhone ? userPhone : "-"},
              { title: "E-posta", key: "userEmail", dataIndex: "userEmail", excelRender: (userEmail) => userEmail ? userEmail : "-" },
              { title: "Başlangıç Saati", key: "startTime", dataIndex: "startTime",excelRender: (startTime) => startTime ? moment(startTime).format("DD.MM.YYYY HH:mm") === "Invalid date" ?  startTime : moment(startTime).format("DD.MM.YYYY HH:mm") : "-"},
              { title: "Bitiş Saati", key: "endTime", dataIndex: "endTime",excelRender: (endTime) => endTime ? moment(endTime).format("DD.MM.YYYY HH:mm") === "Invalid date" ? endTime : moment(endTime).format("DD.MM.YYYY HH:mm") : "-"},
              { title: "Ücret", key: "totalprice", dataIndex: "totalprice",excelRender: (totalprice) => totalprice + " ₺"},
              { title: "Ödeme Yapıldı Mı?", key: "pay", dataIndex: "pay",excelRender: (pay) => pay ? "Evet" : "Hayır"}, 
              { title: "Oda Lokasyonu", key: "address", dataIndex: "address",excelRender: (address) => address ? address : "-"},
              { title: "Ödeme Tarihi", key: "payDate", dataIndex: "payDate",excelRender: (payDate) => payDate ? moment(payDate).format("DD.MM.YYYY HH:mm") === "Invalid date" ? payDate : moment(payDate).format("DD.MM.YYYY HH:mm") : "-"},
              { title: "Rezervasyon Numarası", key: "reservationNumber", dataIndex: "reservationNumber",excelRender: (reservationNumber) => reservationNumber ? reservationNumber : "-"},
            ])
            .addDataSource(roomReservations, {
              str2Percent: true,
            })
            .saveAs("3 Aylık Oda Rezervasyon Raporu.xlsx");
          }

      })
    }
  }

  const sixMonthReport = async(row) => {
    let type = row.data === "Etkinlik" ? "event" : "room";
    let _params = { type: type, period: "sixMonth" };
    if(type === "event"){
      api
      .post(`/rest/eventReservation/eventReservationReport`,{data:"sixmonth"})
      .then(({ data }) => {
        if (data && data.result) {
          const excel = new Excel();
          excel
            .addSheet("6 Aylık Etkinlik Rezervasyon Raporu")
            .addColumns([
              { title: "Etkinlik Adı", key: "eventName", dataIndex: "eventName",excelRender: (eventName) => eventName ? eventName : "-"},
              { title: "Kullanıcı", key: "name", dataIndex: "name"},
              { title: "Telefon", key: "userPhone", dataIndex: "userPhone", excelRender: (userPhone) => userPhone ? userPhone : "-"},
              { title: "E-posta", key: "userEmail", dataIndex: "userEmail", excelRender: (userEmail) => userEmail ? userEmail : "-"},
              { title: "Ücret", key: "totalprice", dataIndex: "totalprice",excelRender: (totalprice) => totalprice + " ₺"},
              { title: "Ödeme Türü", key: "pay", dataIndex: "pay",excelRender: (pay) => pay ? pay : "-"},
              { title: "Katılım Sayısı", key: "count", dataIndex: "count",excelRender: (count) => count + " Kişi"},
              { title: "Rezervasyon Tarihi", key: "createdAt", dataIndex: "createdAt",excelRender: (createdAt) => moment(createdAt).format('DD.MM.YYYY HH:mm')},
              { title: "Etkinlik Lokasyonu", key: "eventlocation", dataIndex: "eventlocation",excelRender: (eventlocation) => eventlocation ? eventlocation.address : "-"},
              { title: "Ödeme Tarihi", key: "payDate", dataIndex: "payDate",excelRender: (payDate) => payDate ? moment(payDate).format('DD.MM.YYYY HH:mm') : "-"},
              { title: "Rezervasyon Numarası", key: "reservationNumber", dataIndex: "reservationNumber",excelRender: (reservationNumber) => reservationNumber ? reservationNumber : "-"},
            ])
            .addDataSource(data.result, {
              str2Percent: true,
            })
            .saveAs(`6 Aylık Etkinlik Rezervasyon Raporu.xlsx`);
                
        }
      })
    } else {
      api
      .post(`/rest/eventReservation/roomReservationReport`,{data:"sixmonth"})
      .then(({ data }) => {
        if(data && data.result && data.result.rows){
          const excel = new Excel();
          excel.addSheet("6 Aylık Oda Rezervasyon Raporu")
            .addColumns([
              { title: "Oda Adı", key: "roomName", dataIndex: "roomName"},
              { title: "Kullanıcı", key: "name", dataIndex: "name"},
              { title: "Telefon", key: "userPhone", dataIndex: "userPhone", excelRender: (userPhone) => userPhone ? userPhone : "-"},
              { title: "E-posta", key: "userEmail", dataIndex: "userEmail", excelRender: (userEmail) => userEmail ? userEmail : "-" },
              { title: "Başlangıç Saati", key: "startTime", dataIndex: "startTime",excelRender: (startTime) => startTime ? moment(startTime).format("DD.MM.YYYY HH:mm") === "Invalid date" ?  startTime : moment(startTime).format("DD.MM.YYYY HH:mm") : "-"},
              { title: "Bitiş Saati", key: "endTime", dataIndex: "endTime",excelRender: (endTime) => endTime ? moment(endTime).format("DD.MM.YYYY HH:mm") === "Invalid date" ? endTime : moment(endTime).format("DD.MM.YYYY HH:mm") : "-"},
              { title: "Ücret", key: "totalprice", dataIndex: "totalprice",excelRender: (totalprice) => totalprice + " ₺"},
              { title: "Ödeme Yapıldı Mı?", key: "pay", dataIndex: "pay",excelRender: (pay) => pay ? "Evet" : "Hayır"}, 
              { title: "Oda Lokasyonu", key: "address", dataIndex: "address",excelRender: (address) => address ? address : "-"},
              { title: "Ödeme Tarihi", key: "payDate", dataIndex: "payDate",excelRender: (payDate) => payDate ? moment(payDate).format("DD.MM.YYYY HH:mm") === "Invalid date" ? payDate : moment(payDate).format("DD.MM.YYYY HH:mm") : "-"},
              { title: "Rezervasyon Numarası", key: "reservationNumber", dataIndex: "reservationNumber",excelRender: (reservationNumber) => reservationNumber ? reservationNumber : "-"},
            ])
            .addDataSource(roomReservations, {
              str2Percent: true,
            })
            .saveAs("6 Aylık Oda Rezervasyon Raporu.xlsx");
          }

      })
    }
  }

  const columns = [
    {
      title: "Rapor Türü",
      render: (row) => {
        return <span>{row.data === "Etkinlik" ? "Etkinlik Rezervasyon Raporları" : "Oda Rezervasyon Raporları"}</span>;
      }
    },
    {
      title: "Aksiyon",
      render: (row) => {
        // 1 haftalık, 1 aylık, 3 aylık, 6 aylık, 1 yıllık raporlar
        return (
          <>
          <Space size="middle">
            <Badge 
              status="success" 
              count="1 Haftalık Rapor"
              className="site-badge-count-109"
              style={{
                backgroundColor: '#52c41a',
              }}
              //clickable
              onClick={() => eventReservationReport(row)}
              onMouseEnter={() => {
                // change mouse enter style cursor
                document.body.style.cursor = 'pointer';
              }}
              onMouseLeave={() => {
                // change mouse leave style cursor
                document.body.style.cursor = 'default';
              }}
            />
            <Badge 
              status="success" 
              count="1 Aylık Rapor"
              className="site-badge-count-109"
              style={{
                backgroundColor: '#9e9e9e',
              }}
              //clickable
              onClick={() => oneMonthReport(row)}
              onMouseEnter={() => {
                // change mouse enter style cursor
                document.body.style.cursor = 'pointer';
              }}
              onMouseLeave={() => {
                // change mouse leave style cursor
                document.body.style.cursor = 'default';
              }}
            />
            <Badge 
              status="success" 
              count="3 Aylık Rapor"
              className="site-badge-count-109"
              style={{
                backgroundColor: '#52c41a',
              }}
              //clickable
              onClick={() => threeMonthReport(row)}
              onMouseEnter={() => {
                // change mouse enter style cursor
                document.body.style.cursor = 'pointer';
              }}
              onMouseLeave={() => {
                // change mouse leave style cursor
                document.body.style.cursor = 'default';
              }}
            />
            <Badge 
              status="success" 
              count="6 Aylık Rapor"
              className="site-badge-count-109"
              style={{
                backgroundColor: '#9e9e9e',
              }}
              //clickable
              onClick={() => sixMonthReport(row)}
              onMouseEnter={() => {
                // change mouse enter style cursor
                document.body.style.cursor = 'pointer';
              }}
              onMouseLeave={() => {
                // change mouse leave style cursor
                document.body.style.cursor = 'default';
              }}
            />

            

          </Space>
          <Alert message="Raporları indirmek için tıklayınız." type="info" showIcon size="small" style={{marginTop: '1rem'}} />
          </>
        );
      }
    }
  ];

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  }

  let defaultData = () => {
    let data = [];
    data.push({
      data: "Etkinlik",
    });
    data.push({
      data: "Oda",
    });
    return data;
  }


  const getEvents  = () => {
    api
      .get(
        '/rest/event?' +
          qs.stringify({ page: 1, pageSize: 10000, sort: 'name' }),
      )
      .then(({ data: { result, result_message } }) => {
        if (result && result.rows) {
          setEvents(result.rows);
        }
      });
  };

  const getRooms = () => {
    api
      .get(
        '/rest/room?' +
          qs.stringify({ page: 1, pageSize: 10000, sort: 'name' }),
      )
      .then(({ data: { result, result_message } }) => {
        if (result && result.rows) {
          setRooms(result.rows);
        }
      });
  };

  useEffect(() => {
    getRooms();
    getEvents();
  }, []);

  console.log(data, "data")

  const getEventReport = async(row) => {
    let enumData = {
      "weekly": "1 Haftalık Rapor",
      "monthly": "1 Aylık Rapor",
      "threeMonth": "3 Aylık Rapor",
      "sixMonth": "6 Aylık Rapor",
    }
    await api.post(`/rest/eventReservation/eventReservationReportByEvent`,{data: row.reportPeriod, event: row.event})
    .then(({data: {result, result_message}}) => {
      if(result && result.length > 0){
        const excel = new Excel();
          excel
            .addSheet(`${row.event.split("-")[1]} ${enumData[row.reportPeriod]}`)
            .addColumns([
              { title: "Etkinlik Adı", key: "eventId", dataIndex: "eventId",excelRender: (eventId) => {
                if(eventId){
                  return events.find(event => event._id === eventId).name
                }
              }},
              { title: "Kullanıcı", key: "name", dataIndex: "name"},
              { title: "Telefon", key: "userPhone", dataIndex: "userPhone", excelRender: (userPhone) => userPhone ? userPhone : "-"},
              { title: "E-posta", key: "userEmail", dataIndex: "userEmail", excelRender: (userEmail) => userEmail ? userEmail : "-"},
              { title: "Ücret", key: "totalprice", dataIndex: "totalprice",excelRender: (totalprice) => totalprice + " ₺"},
              { title: "Ödeme Türü", key: "pay", dataIndex: "pay",excelRender: (pay) => pay ? pay : "-"},
              { title: "Katılım Sayısı", key: "count", dataIndex: "count",excelRender: (count) => count + " Kişi"},
              { title: "Rezervasyon Tarihi", key: "createdAt", dataIndex: "createdAt",excelRender: (createdAt) => moment(createdAt).format('DD.MM.YYYY HH:mm')},
              { title: "Etkinlik Lokasyonu", key: "eventlocation", dataIndex: "eventlocation",excelRender: (eventlocation) => eventlocation ? eventlocation.address : "-"},
              { title: "Ödeme Tarihi", key: "payDate", dataIndex: "payDate",excelRender: (payDate) => payDate ? moment(payDate).format('DD.MM.YYYY HH:mm') : "-"},
              { title: "Rezervasyon Numarası", key: "reservationNumber", dataIndex: "reservationNumber",excelRender: (reservationNumber) => reservationNumber ? reservationNumber : "-"},
            ])
            .addDataSource(result, {
              str2Percent: true,
            })
            .saveAs(`${row.event.split("-")[1]} ${enumData[row.reportPeriod]}.xlsx`);
      }
    })
  }
  const getRoomReport = async(row) => {
    let enumData = {
      "weekly": "1 Haftalık Rapor",
      "monthly": "1 Aylık Rapor",
      "threeMonth": "3 Aylık Rapor",
      "sixMonth": "6 Aylık Rapor",
    }
    await api.post(`/rest/roomReservation/roomReservationReportByRoomId`,{data: row.reportPeriod, room: row.room})
    .then(({data: {result, result_message}}) => {
      let roomName = rooms.find(room => room._id === row.room).name
      if(result && result.length > 0){
        const excel = new Excel();
          excel
            .addSheet(`${roomName} ${enumData[row.reportPeriod]}`)
            .addColumns([
              { title: "Oda Adı", key: "roomName", dataIndex: "roomName",excelRender: (roomName) => roomName ? roomName : "-"},
              { title: "Kullanıcı", key: "name", dataIndex: "name"},
              { title: "Telefon", key: "userPhone", dataIndex: "userPhone", excelRender: (userPhone) => userPhone ? userPhone : "-"},
              { title: "E-posta", key: "userEmail", dataIndex: "userEmail", excelRender: (userEmail) => userEmail ? userEmail : "-"},
              { title: "Ücret", key: "totalprice", dataIndex: "totalprice",excelRender: (totalprice) => totalprice + " ₺"},
              { title: "Ödeme", key: "pay", dataIndex: "pay",excelRender: (pay) => pay ? pay : "-"},
              { title: "Ödeme Türü", key: "payStatus", dataIndex: "payStatus",excelRender: (payStatus) => payStatus ? payStatus : "-"},
              { title: "Rezervasyon Tarihi", key: "createdAt", dataIndex: "createdAt",excelRender: (createdAt) => moment(createdAt).format('DD.MM.YYYY HH:mm')},
              { title: "Etkinlik Lokasyonu", key: "eventlocation", dataIndex: "eventlocation",excelRender: (eventlocation) => eventlocation ? eventlocation.address : "-"},
              { title: "Ödeme Tarihi", key: "payDate", dataIndex: "payDate",excelRender: (payDate) => payDate ? moment(payDate).format('DD.MM.YYYY HH:mm') : "-"},
              { title: "Rezervasyon Numarası", key: "reservationNumber", dataIndex: "reservationNumber",excelRender: (reservationNumber) => reservationNumber ? reservationNumber : "-"},
            ])
            .addDataSource(result, {
              str2Percent: true,
            })
            .saveAs(`${roomName} ${enumData[row.reportPeriod]}.xlsx`);
      }
    })
  }

  const getFutureReportRoom = async(row) => {
    console.log(row,"row")
    let enumData = {
      "futureOneWeek": "1 Haftalık Rapor",
      "futureOneMonth": "1 Aylık Rapor",
      "futureThreeMonth": "3 Aylık Rapor",
      "futureSixMonth": "6 Aylık Rapor",
    }
    await api.post(`/rest/roomReservation/futureRoomReservationReportByRoomId`,{data: row.reportPeriod, room: row.room})
    .then(({data: {result, result_message}}) => {
      let roomName = rooms.find(room => room._id === row.room).name
      if(result && result.length > 0){
        const excel = new Excel();
          excel
            .addSheet(`${roomName} ${enumData[row.reportPeriod]}`)
            .addColumns([
              { title: "Oda Adı", key: "roomName", dataIndex: "roomName",excelRender: (roomName) => roomName ? roomName : "-"},
              { title: "Kullanıcı", key: "name", dataIndex: "name"},
              { title: "Telefon", key: "userPhone", dataIndex: "userPhone", excelRender: (userPhone) => userPhone ? userPhone : "-"},
              { title: "E-posta", key: "userEmail", dataIndex: "userEmail", excelRender: (userEmail) => userEmail ? userEmail : "-"},
              { title: "Ücret", key: "totalprice", dataIndex: "totalprice",excelRender: (totalprice) => totalprice + " ₺"},
              { title: "Ödeme", key: "pay", dataIndex: "pay",excelRender: (pay) => pay ? pay : "-"},
              { title: "Ödeme Türü", key: "payStatus", dataIndex: "payStatus",excelRender: (payStatus) => payStatus ? payStatus : "-"},
              { title: "Rezervasyon Tarihi", key: "createdAt", dataIndex: "createdAt",excelRender: (createdAt) => moment(createdAt).format('DD.MM.YYYY HH:mm')},
              { title: "Etkinlik Lokasyonu", key: "eventlocation", dataIndex: "eventlocation",excelRender: (eventlocation) => eventlocation ? eventlocation.address : "-"},
              { title: "Ödeme Tarihi", key: "payDate", dataIndex: "payDate",excelRender: (payDate) => payDate ? moment(payDate).format('DD.MM.YYYY HH:mm') : "-"},
              { title: "Rezervasyon Numarası", key: "reservationNumber", dataIndex: "reservationNumber",excelRender: (reservationNumber) => reservationNumber ? reservationNumber : "-"},
            ])
            .addDataSource(result, {
              str2Percent: true,
            })
            .saveAs(`${roomName} ${enumData[row.reportPeriod]}.xlsx`);
      }
    })
  }
  const getFutureReportEvent = async(row) => {
    console.log(row,"row")
    let enumData = {
      "futureOneWeek": "1 Haftalık Rapor",
      "futureOneMonth": "1 Aylık Rapor",
      "futureThreeMonth": "3 Aylık Rapor",
      "futureSixMonth": "6 Aylık Rapor",
    }
    await api.post(`/rest/eventReservation/futureEventReservationReportByEventId`,{data: row.reportPeriod, event: row.event})
    .then(({data: {result, result_message}}) => {
      if(result && result.length > 0){
        const excel = new Excel();
          excel
            .addSheet(`${row.event.split("-")[1]} ${enumData[row.reportPeriod]}`)
            .addColumns([
              { title: "Etkinlik Adı", key: "eventId", dataIndex: "eventId",excelRender: (eventId) => {
                if(eventId){
                  return events.find(event => event._id === eventId).name
                }
              }},
              { title: "Kullanıcı", key: "name", dataIndex: "name"},
              { title: "Telefon", key: "userPhone", dataIndex: "userPhone", excelRender: (userPhone) => userPhone ? userPhone : "-"},
              { title: "E-posta", key: "userEmail", dataIndex: "userEmail", excelRender: (userEmail) => userEmail ? userEmail : "-"},
              { title: "Ücret", key: "totalprice", dataIndex: "totalprice",excelRender: (totalprice) => totalprice + " ₺"},
              { title: "Ödeme Türü", key: "pay", dataIndex: "pay",excelRender: (pay) => pay ? pay : "-"},
              { title: "Katılım Sayısı", key: "count", dataIndex: "count",excelRender: (count) => count + " Kişi"},
              { title: "Rezervasyon Tarihi", key: "createdAt", dataIndex: "createdAt",excelRender: (createdAt) => moment(createdAt).format('DD.MM.YYYY HH:mm')},
              { title: "Etkinlik Lokasyonu", key: "eventlocation", dataIndex: "eventlocation",excelRender: (eventlocation) => eventlocation ? eventlocation.address : "-"},
              { title: "Ödeme Tarihi", key: "payDate", dataIndex: "payDate",excelRender: (payDate) => payDate ? moment(payDate).format('DD.MM.YYYY HH:mm') : "-"},
              { title: "Rezervasyon Numarası", key: "reservationNumber", dataIndex: "reservationNumber",excelRender: (reservationNumber) => reservationNumber ? reservationNumber : "-"},
            ])
            .addDataSource(result, {
              str2Percent: true,
            })
            .saveAs(`${row.event.split("-")[1]} ${enumData[row.reportPeriod]}.xlsx`);
      }
    })
  }

  return (
    <React.Fragment>
      {loading && <Spinner />}
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {pathName ? pathName : ""}{' '}
          </h1>
        </div>
        <div className="list-buttons py-5">
          

          
        </div>
      </div>

      <div className="table-wrap">
        <div className="table-wrapper">
          <Table
            dataSource={defaultData()}
            columns={columns}
            onChange={handleTableChange}
            pagination={{
              total: totalCount,
            }}
            rowSelection={{ ...rowSelection }}
          />
          <Divider type='horizontal' style={{ marginTop: '0.5rem' }}>
            <p>Gelecek Dönem Raporları</p>
          </Divider>
          <Form layout="horizontal" size="large">
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Rapor Türü"
                  name="futureReportType"
                  >
                  <Select
                    placeholder="Rapor Türü Seçiniz"
                    onChange={(e) => setData({ ...data, futureReportType: e })}
                    allowClear
                    name="futureReportType"
                    >
                    <Option value="Etkinlik">Etkinlik</Option>
                    <Option value="Oda">Oda</Option>
                  </Select>
                </Form.Item>
              </Col>
              {data.futureReportType && data.futureReportType === "Etkinlik" && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Etkinlik Seçiniz"
                    name="futureEvent"
                    >
                    <Select
                      placeholder="Etkinlik Seçiniz"
                      onChange={(e) => setData({ ...data, futureEvent: e })}
                      allowClear
                      name="futureEvent"
                      >
                      {events.map((event) => (
                        <Option value={event._id}>{event.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {data.futureReportType && data.futureReportType === "Oda" && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Oda Seçiniz"
                    name="futureRoom"
                    >
                    <Select
                      placeholder="Oda Seçiniz"
                      onChange={(e) => setData({ ...data, futureRoom: e })}
                      allowClear
                      name="futureRoom"
                      >
                      {rooms.map((room) => (
                        <Option value={room._id}>{room.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {data.futureReportType === "Oda" && data.futureRoom && (
                <>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Rapor Dönemi"
                    name="futureReportPeriod"
                    >
                    <Select
                      placeholder="Rapor Dönemi Seçiniz"
                      onChange={(e) => setData({ ...data, futureReportPeriod: e })}
                      allowClear
                      name="futureReportPeriod"
                      >
                      <Option value="futureOneWeek">1 Haftalık</Option>
                      <Option value="futureOneMonth">1 Aylık</Option>
                      <Option value="futureThreeMonth">3 Aylık</Option>
                      <Option value="futureSixMonth">6 Aylık</Option>

                    </Select>
                  </Form.Item>
                </Col>
                {data.futureReportPeriod && data.futureReportType && (
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Button
                      type="primary"
                      onClick={() => {
                        getFutureReportRoom(data);
                      }}
                      >
                      Rapor Oluştur
                    </Button>
                  </Col>
                )}
                </>
              )}
              {data.futureReportType === "Etkinlik" && data.futureEvent && (
                <>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Rapor Dönemi"
                    name="futureReportPeriod"
                    >
                    <Select
                      placeholder="Rapor Dönemi Seçiniz"
                      onChange={(e) => setData({ ...data, futureReportPeriod: e })}
                      allowClear
                      name="futureReportPeriod"
                      >
                      <Option value="futureOneWeek">1 Haftalık</Option>
                      <Option value="futureOneMonth">1 Aylık</Option>
                      <Option value="futureThreeMonth">3 Aylık</Option>
                      <Option value="futureSixMonth">6 Aylık</Option>

                    </Select>
                  </Form.Item>
                </Col>
                {data.futureReportPeriod && data.futureReportType && (
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Button
                      type="primary"
                      onClick={() => {
                        getFutureReportEvent(data);
                      }}
                      >
                      Rapor Oluştur
                    </Button>
                  </Col>
                )}
                </>
              )}
              
            </Row>

          </Form>
          <Divider type='horizontal' style={{ marginTop: '0.5rem' }}>
            <p>Detaylı Rapor Oluştur</p>
          </Divider>
          <Form layout="horizontal" size="large">
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Rapor Türü"
                  name="reportType"
                  >
                  <Select
                    placeholder="Rapor Türü Seçiniz"
                    onChange={(e) => setData({ ...data, reportType: e })}
                    allowClear
                    name="reportType"
                    >
                    <Option value="Etkinlik">Etkinlik</Option>
                    <Option value="Oda">Oda</Option>
                  </Select>
                  </Form.Item>
              </Col>
              {data.reportType && data.reportType === "Etkinlik" && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={`Etkinlik Seçiniz`}
                  name="event"
                  >
                  <Select
                    placeholder={`Etkinlik Seçiniz`}
                    onChange={(e) => setData({ ...data, event: e })}
                    allowClear
                    name="event"
                    >
                      {
                        events && events.length > 0 && events.map((event, index) => {
                          return <Option value={event._id + "-" + event.name}>{event.name}</Option>
                        })
                      }
                      

                  </Select>
                  </Form.Item>
              </Col>

              )}
              {data.reportType && data.reportType === "Oda" && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={`Oda Seçiniz`}
                    name="room"
                    >
                      <Select
                        placeholder={`Oda Seçiniz`}
                        onChange={(e) => setData({ ...data, room: e })}
                        allowClear
                        name="room"
                        >
                          {
                            rooms && rooms.length > 0 && rooms.map((room, index) => {
                              return <Option value={room._id}>{room.name}</Option>
                            })
                          }

                      </Select>
                    </Form.Item>
                  </Col>
              )}
              {data.reportType && data.room && data.reportType === "Oda" && (
                <>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Rapor Periyodu"
                    name="reportPeriod"
                    >
                      <Select
                        placeholder="Rapor Periyodu Seçiniz"
                        onChange={(e) => setData({ ...data, reportPeriod: e })}
                        allowClear
                        name="reportPeriod"
                        > 
                          <Option value="weekly">Haftalık</Option>
                          <Option value="onemonth">1 Aylık</Option>
                          <Option value="threemonth">3 Aylık</Option>
                          <Option value="sixmonth">6 Aylık</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {data.reportPeriod && (
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Button 
                        type="primary" 
                        onClick={() => getRoomReport(data)}
                        icon={<TbReportAnalytics />}
                        style={{ 
                          borderRadius: '0.5rem',
                          fontWeight: 'bold',
                          color: '#fff',
                        }}
                        
                        >Raporu Getir</Button>
                    </Col>
                  )}
                </>
              )}
              {data.reportType && data.event && data.reportType === "Etkinlik" && (
                <>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Rapor Periyodu"
                    name="reportPeriod"
                    >
                      <Select
                        placeholder="Rapor Periyodu Seçiniz"
                        onChange={(e) => setData({ ...data, reportPeriod: e })}
                        allowClear
                        name="reportPeriod"
                        > 
                          <Option value="weekly">Haftalık</Option>
                          <Option value="onemonth">1 Aylık</Option>
                          <Option value="threemonth">3 Aylık</Option>
                          <Option value="sixmonth">6 Aylık</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {data.reportPeriod && (
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Button 
                        type="primary" 
                        onClick={() => getEventReport(data)}
                        icon={<TbReportAnalytics />}
                        style={{ 
                          borderRadius: '0.5rem',
                          fontWeight: 'bold',
                          color: '#fff',
                        }}
                        
                        >Raporu Getir</Button>
                    </Col>
                  )}
                </>
              )}
              
              
            </Row>
          </Form>
          
        </div>
      </div>
    </React.Fragment>
  )
}

export default Report;