import { DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Button, Checkbox, DatePicker, Input, Popconfirm, Space, Table } from 'antd';
import { Excel } from "antd-table-saveas-excel";
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../components/Loading';
import api from '../service/index';
import locale from 'antd/es/date-picker/locale/tr_TR';
import { getPathName } from '../stores/utils';
moment.locale('tr');

function RoomReservation(props) {
  const { Search } = Input;
  const [search, setSearch] = useState(false);
  const [roomReservations, setRoomReservations] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [rooms, setRooms] = useState([]);
  let path = props.location.pathname.split('/')[1];
	let pathName = getPathName(path);

  const query = [];

  let columns = [
    {
      title: "Oda ID",
      dataIndex: "roomId",
      key: "roomId",
    },
    {
      title: 'Oda',
      dataIndex: 'roomName',
      key: 'roomName',
    },
    {
      title: 'Kullanıcı',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: "Konum",
      render (row) {
        if(row.roomId) {
          const tmp = rooms.find((room) => room._id === row.roomId);
          if (tmp && tmp.name) {
            return tmp.name;
          }
          return '-';
        }
      }
    },
    {
      title: 'Başlangıç Saati',
      dataIndex: 'startTime',
      key: 'startTime',
      render (startTime) {
        return startTime ? startTime : '-'
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <>
        <div style={{ padding: 8 }}>
          <DatePicker
            locale={locale}
            onChange={(date, dateString) => {
              setSelectedKeys(dateString ? [dateString] : []);
            }}
            format={'DD/MM/YYYY'}
            placeholder='Tarih Seçiniz'
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            allowClear={() => {
              clearFilters();
            }}
            value={selectedKeys[0] ? moment(selectedKeys[0], 'DD/MM/YYYY') : null}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Ara
            </Button>
            <Button onClick={() => {
              setSelectedKeys([]);
            }} size="small" style={{ width: 90 }}>
              Temizle
            </Button>
          </Space>
        </div>
        
        
        </>
      ),


      
    },
    {
      title: 'Bitiş Saati',
      dataIndex: 'endTime',
      key: 'endTime',
      render (endTime) {
        return endTime ? endTime : '-'
      }
    },
    {
      title: 'Ücret',
      dataIndex: 'totalprice',
      key: 'totalprice',
      render (totalprice) {
        return totalprice ? totalprice + ' ₺' : '-'
      }
    },
    {
      title: 'Ücret Durumu',
      render (row) {
        if(row.pay && row.pay === true){
          return <Badge
            className="site-badge-count-109"
            count="Ödendi"
            style={{
              backgroundColor: '#52c41a',
            }}
        />
        }else{
          return <Badge
            className="site-badge-count-109"
            count={row.payStatus ? row.payStatus : 'Ödenmedi'}
            style={{
              backgroundColor: '#f5222d',
            }}
        />
        }
      }
    },
    {
      title: "Rezervasyon Numarası",
      dataIndex: "reservationNumber",
      key: "reservationNumber",
      render: (reservationNumber) => {
        return <span>{reservationNumber ? reservationNumber : '-'}</span>;
      }
    },
    {
      title: 'Aktiflik',
      key: 'active',
      render (row) {
        return (
            <Badge
              className="site-badge-count-109"
              count={row.active ? 'Aktif' : 'Pasif'}
              style={{
                backgroundColor: row.active ? '#52c41a' : '#f5222d',
              }}
            />
        );
      },
      filters: [
        {
          text: 'Aktif',
          value: true,
        },
        {
          text: 'Pasif',
          value: false,
        }
      ],
      onFilter: (value, record) => record.active === value,
    },
    {
      title: 'İşlemler',
      key: 'action',
      align: 'center',
      render: (row) => (
        <div className="tbl-actions">
          <Space size="small">
            <Link to={'/room-reservation/edit/' + row._id}>
              <Button icon={<EditOutlined />}>Düzenle</Button>
            </Link>
            <Popconfirm
              onConfirm={() => deleteRow(row._id)}
              // onConfirm={() => console.log(row, 'row')}
              title={'Silme işlemini onaylıyor musunuz?'}
              okText={'Evet'}
              cancelText={'İptal'}
            >
              {
                // ödenmeyen rezervasyonları silebilsin
                roomReservations.includes(row) && !row.pay ? (
                  <Button type="danger" icon={<DeleteOutlined />}>
                    Sil
                  </Button>
                ) : (
                  <div></div>
                )
                // roomReservations.map((item) => {
                //   if(!item.pay) {
                //     return <Button type="danger" icon={<DeleteOutlined />}>
                //       Sil
                //     </Button>
                //   } else {
                //     return <div></div>
                //   }
                // })
              }
              
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  const get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 1000 };
    }
    let shortString = '-date';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name, roomName';
    }

    function findUserName(userId) {
      const tmp = users.find((user) => user._id === userId);
      if (tmp && tmp.name) {
        return tmp.name;
      }
      return '-';
    }

    function findRoomName(roomId) {
      const tmp = rooms.find((room) => room._id === roomId);
      if (tmp && tmp.name) {
        return tmp.name;
      }
      return '-';
    }

    api
      .get(`/rest/roomReservation?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          setTotalCount(data.result.total);
          setRoomReservations(
            data.result.rows.map((item, key) => ({
              ...item,
              key: key,
              room: findRoomName(item.roomId),
              user: findUserName(item.userId),
            })),
          );
        }
      })
      .then(() => {
        setLoading(false);
      });
  };

  

  const getRooms = () => {
    api
      .get(
        '/rest/room?' +
          qs.stringify({ page: 1, pageSize: 10000, sort: 'name' }),
      )
      .then(({ data: { result, result_message } }) => {
        if (result && result.rows) {
          setRooms(result.rows);
        }
      });
  };

  const getUsers = () => {
    api.post("/rest/user/getAllUsers",{}).then(({ data: { result, result_message } }) => {
      if (result ) {
        setUsers(result);
      }
    }).then(() => setLoading(false));
  };

  useEffect(() => {
    getRooms();
    getUsers();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (users.length && rooms.length) {
      get();
    }
  }, [users, rooms]);

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, rows) => {
      setSelectedRows(rows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const deleteRow = async (item_id) => {
    await api.delete(`/rest/roomReservation/${item_id}`, ({ data }) => {});
    get();
  };

  let deleteSelectedRows = async() => {
    selectedRows.forEach(async(item) => {
      await api.delete(`/rest/roomReservation/${item._id}`, ({ data }) => {});
      let newData = roomReservations.filter((el) => el._id !== item._id);
      setRoomReservations(newData);
      get();
    });
  };

  const downloadExcel = () => {
    const excel = new Excel();
    excel
      .addSheet("Oda Rezervasyonu")
      .addColumns([
        { title: "Oda Adı", key: "roomName", dataIndex: "roomName"},
        { title: "Kullanıcı", key: "user", dataIndex: "user"},
        { title: "Telefon", key: "userPhone", dataIndex: "userPhone", excelRender: (userPhone) => userPhone ? userPhone : "-"},
        { title: "E-posta", key: "userEmail", dataIndex: "userEmail", excelRender: (userEmail) => userEmail ? userEmail : "-" },
        { title: "Başlangıç Saati", key: "startTime", dataIndex: "startTime",excelRender: (startTime) => startTime ? moment(startTime).format("DD.MM.YYYY HH:mm") === "Invalid date" ?  startTime : moment(startTime).format("DD.MM.YYYY HH:mm") : "-"},
        { title: "Bitiş Saati", key: "endTime", dataIndex: "endTime",excelRender: (endTime) => endTime ? moment(endTime).format("DD.MM.YYYY HH:mm") === "Invalid date" ? endTime : moment(endTime).format("DD.MM.YYYY HH:mm") : "-"},
        { title: "Ücret", key: "totalprice", dataIndex: "totalprice",excelRender: (totalprice) => totalprice + " ₺"},
        { title: "Ödeme Yapıldı Mı?", key: "pay", dataIndex: "pay",excelRender: (pay) => pay ? "Evet" : "Hayır"}, 
        { title: "Oda Lokasyonu", key: "address", dataIndex: "address",excelRender: (address) => address ? address : "-"},
        { title: "Ödeme Tarihi", key: "payDate", dataIndex: "payDate",excelRender: (payDate) => payDate ? moment(payDate).format("DD.MM.YYYY HH:mm") === "Invalid date" ? payDate : moment(payDate).format("DD.MM.YYYY HH:mm") : "-"},
        { title: "Rezervasyon Numarası", key: "reservationNumber", dataIndex: "reservationNumber",excelRender: (reservationNumber) => reservationNumber ? reservationNumber : "-"},


      ])
      .addDataSource(roomReservations, {
        str2Percent: true,
      })
      .saveAs("Oda_Rezervasyonu.xlsx");
  };

  // debounce fonksiyonu oluştur.
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const onChange = async (e) => {
    setSearch(e.target.value);
    await get();
  };
  const onSearch = async(value) => {
    setSearch(value);
    await get();
  }



  return (
    <div>
      {loading && <Spinner />}
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {pathName ? pathName : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons py-5">       
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            size="large"
            onClick={deleteSelectedRows}
            style={{
              fontSize: '14px',
              height: '100%',
              marginRight: '10px',
            }}
          >
            Seçilenleri Sil
          </Button>

          <Button
            size={'large'}
            onClick={downloadExcel}
            style={{ marginRight: '5px' }}
            icon={<DownloadOutlined />}
          >
            {'Excel Rapor'}
          </Button>

          <Link to="/room-reservation/add" style={{ marginRight: '5px' }}>
            <Button
              type="light"
              style={{ fontSize: '14px', height: '100%' }}
              icon={<PlusOutlined />}
              size="large"
            >
              Rezervasyon Ekle
            </Button>
          </Link>
        </div>
      </div>
      <div className="table-wrap">
        <Search
          placeholder={"Ara"}
          onSearch={onSearch}
          onChange={onChange}
        />
        <Table
          dataSource={roomReservations}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
            showSizeChanger: false
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
}

export default RoomReservation;
