import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
;

export default (props) => {
    let { record, setRecord, name } = props;

    const stats = [
        {
            "type":"available",
            "text":"Müsait",
            "color":"#25C022"
        },
        {
            "type":"almostFull",
            "text":"Neredeyse Dolu",
            "color":"#25C022"
        },
        {
            "type":"full",
            "text":"Dolu",
            "color":"#25C022"
        },
        {
            "type":"closed",
            "text":"Kapalı",
            "color":"#25C022"
        }
    ];

    let featureChange = (v) => {
        let feature = stats.find((value) => {
            return { type: value.type, text: value.text, color: value.color}
        })
        setRecord({ ...record, [name]: feature });
    }


    return <Select
        // defaultValue={record[name] ? record[name].map((value) => value.type) : {}}
        defaultValue={record[name] ? record[name].text : {}}
        placeholder={"Lütfen Oda Durumu Seçiniz"}
        onChange={featureChange}>
        {(stats) && stats.map(b =>
            <Select.Option key={b.type} value={b.type}>{b.text}</Select.Option>)}
    </Select>
}