import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let { record, setRecord, name } = props;
	let [attendee, setAttendee] = useState([]);
	let _params = { sort: "-createdAt", page: 1, pageSize: 100000 };
	let languagesCms = props.languagesCms;
	useEffect(() => {
		api.post("/rest/user/getAllUsers",{}).then(({data}) => {
			if(data && data.result){
				let dbAttendee = data.result.sort((a, b) => a.name.localeCompare(b.name))
				setAttendee(dbAttendee);
			}
		})
	}, [])

	let mentionedUsers = (v) => {
		let mentionedUser = attendee.find(e => e.name === v)
		setRecord({ ...record, [name]:   mentionedUser._id, "name": v });
	}

	let findAttendeeName = (attendeeId) => {
		const tmp = attendee.find((attendee) => attendee._id === attendeeId);
		return tmp ? tmp.name : [];
	}

	



	return <Select 
		placeholder={"Kullanıcı Seçiniz"}
		defaultValue={record["name"] ? record["name"] : []}
		showSearch={true}
		disabled={record["pay"] ? record["pay"]: false}
		onChange={mentionedUsers}
		>
				   
		{(attendee) && attendee.map(b =>
			<Select.Option key={b._id} value={b.name} >{b.name}</Select.Option>)}
	</Select>;
};
