import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import api from '../../service/index';

const contact = ({ id }) => {
  const [adminInfo, setAdminInfo] = useState(null);

  const stationAdminInfo = async () => {
    await api
      .get(`/rest/station-admin-info/${id}`)
      .then(({ data }) => {
        if (data && data.result) {
          setAdminInfo(data.result);
        }
      })
      .catch((err) => {
        message.error('İletişim Verileri Getirilemedi');
      });
  };
  useEffect(() => {
    stationAdminInfo();
  }, []);

  return (
    <div className="row tabpane">
      <div className="stationinfo">
        <div style={{ float: 'left', fontSize: '13px', margin: 'auto' }}>
          {adminInfo &&
            adminInfo.map((item, index) => (
              <>
                <div
                  style={{
                    width: '50%',
                    height: '25%',
                    display: 'inline-block',
                    float: 'left',
                    margin: 'auto',
                    marginTop: '-1.7rem',
                    marginBottom: '7px',
                    padding: '11px',
                  }}
                >
                  <h6
                    key={index}
                    style={{ textAlign: 'start', padding: '11px' }}
                  >
                    <b>
                      {item.role === 2
                        ? 'e-MSP Admin'
                        : item.role === 6
                        ? 'CP Admin'
                        : 'Bayi'}{' '}
                      : {item.username}
                    </b>
                  </h6>
                  <li>
                    <p>
                      <span>
                        <b>Email Adresi : </b>
                      </span>
                      <span>{item.email ? item.email : '-'}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        <b>Telefon Numarası : </b>
                      </span>
                      <span>{item.phone ? item.phone : '-'}</span>
                    </p>
                  </li>
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(contact);
