import React, { useState, useMemo } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name, mode, url,placeholder } = props;
  let [attendee, setAttendee] = useState([]);
  let _params = { sort: '-createdAt', page: 1, pageSize: 10000 };
  let languagesCms = props.languagesCms;

  useMemo(() => {
    api.post("/rest/user/getAllUsers",{}).then(({data}) => {
			if(data && data.result){
				let dbAttendee = data.result.sort((a, b) => a.name.localeCompare(b.name))
				setAttendee(dbAttendee);
			}
		})
  }, []);


  let findAttendeeName = (attendeeId) => {
		const tmp = attendee.find((attendee) => attendee._id === attendeeId);
		return tmp ? tmp.name : [];
	}

  let setRecordArr = (v) => {
    // let result = cities.filter(x => x._id === v[0]);

    setRecord({...record, [name]: v})
  }

  console.log(record[name].map((e) => findAttendeeName(e)))


    return (
      <Select
        value={record[name] && record[name].map((e) => findAttendeeName(e)) ? record[name].map((e) => findAttendeeName(e)) : []}
        showSearch={true}
        mode={mode}
        placeholder={placeholder}
        onChange={setRecordArr}
        
      >
        {attendee &&
          attendee.map((b) => (
            <Select.Option key={b.name} value={b._id}>
             {b.name} {b.lastname}
            </Select.Option>
          ))}
      </Select>
    );
};
