import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let { record, setRecord, name } = props;
	let [room, setRoom] = useState([]);
	let _params = { query: {deleted: { $ne: true }}, sort: "-createdAt", page: 1, pageSize: 100000 };
	useEffect(() => {
		api.get(`/rest/room?${qs.stringify(_params)}`).then(({ data }) => {
			if(data && data.result){
				let dbRooms = data.result.rows.sort((a, b) => a.name.localeCompare(b.name))
				setRoom(dbRooms);
			}
		})
	}, [])

	let findRoomName = (roomId) => {
		const tmp = room.find((room) => room._id === roomId);
		return tmp ? tmp.name : null;
	}


	let selectedRooms = (v) => {
		let selectedRoom = room.find(e => e._id === v)
		let selectedRoomPrice = selectedRoom.price
		let selectedRoomLocation = selectedRoom.location
		setRecord({ ...record, [name]:  selectedRoom._id, "roomName": selectedRoom.name, "price": selectedRoomPrice, "roomLocation": selectedRoomLocation})
	}


	
	return <Select 
		defaultValue={record[name] ? record["roomName"]: []}
		placeholder={"Oda Seçiniz"}
		showSearch={true}
		disabled={record["pay"] ? record["pay"]: false}
		allowClear={true}
		onChange={selectedRooms}
		>
		
				   
		{(room) && record["roomlocation"] && record["roomlocation"].name && room.filter(e => e.location.name === record["roomlocation"].name).map(b =>
			<Select.Option key={b._id} value={b._id} >{b.name}</Select.Option>)}
	</Select>;
};
