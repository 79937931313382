import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { Modal, Button, Popconfirm, Tabs, message } from 'antd';
import api from '../../service/index';
import { useSelector } from 'react-redux';
import { RedoOutlined } from '@ant-design/icons';
import { DriverPicker } from '../index';
import Contact from './contact';
import FirmwareUpdate from './firmwareUpdateModal';


export default (props) => {
  let { record, languagesCms, isModalVisible, setIsModalVisible } = props;
  let [summary, setSummary] = useState([]);
  const { TabPane } = Tabs;
  const { auth } = useSelector((state) => state.auth);
  let [user] = useGlobal('user');
  const [data, setData] = useState([]);

  const showModal = async () => {
    let stationInfo = {};

    if (record) stationInfo = record;

    let restData = await api
      .get(`/rest/chargepoints?stationId=${record._id}`)
      .then(({ data }) => {
        if (data && data.result) {
          return data.result.map((item, key) => {
            if (item.hasDC) item.hasDC = <div className="true-circle"></div>;
            else item.hasDC = <div className="false-circle"></div>;
            item.key = key;
            return item;
          });
        }
      });

    setSummary({ ...summary, ...stationInfo, connectors: restData });
  };

  useEffect(() => {
    let timeout;

    if (isModalVisible) {
      timeout = setTimeout(() => {
        if (isModalVisible === false) setSummary({});
        if (record && record._id) showModal();
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  });

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const resetDevice = async (item_id) => {
    await api
      .get(`/rest/chargepoints/resetdevice/${item_id}`)
      .then(({ data }) => {});
  };

  const startCharge = async (unique_id, connector_id, cardNumber) => {
    if (cardNumber === '' || cardNumber === undefined || cardNumber === null)
      return message.error('Kullanıcı RFID Numarası Alınamadı !');

    const bearer_token =
      'Bearer ' + JSON.parse(localStorage.getItem('user')).token;
    await api
      .post('/rest/startCharge', {
        unique_id,
        connector_id,
        user_token: bearer_token,
        cardNumber: cardNumber,
      })
      .then(({ data }) => {
        if (data) {
          message
            .loading('Şarj Başlatılıyor ...', 2.5)
            .then(() => message.warning(data.result_message.message, 2.5))
            .catch((err) => message.error(err));
        }
      })
      .catch((err) => {
        message.error('test ' + err.message);
      });
  };

  const stopCharge = async (devicesId, connector_id) => {
    const bearer_token =
      'Bearer ' + JSON.parse(localStorage.getItem('user')).token;

    await api
      .post('/rest/stopCharge', {
        unique_id: devicesId,
        connector_id,
        user_token: bearer_token,
      })
      .then(({ data }) => {
        if (data) {
          message
            .loading('Şarj Durduruluyor ...', 2.5)
            .then(() => message.warning(data.result_message.message, 2.5));
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  return (
    <div className="istasyonmodal">
      <Modal
        className="modal__info"
        title="Şarj Noktası Detayları"
        width={1050}
        visible={isModalVisible}
        footer={null}
        onOk={handleOk}
        onCancel={handleOk}
      >
        <div className="modal-background">
          <img src="/images/chargecar.png" alt="chargerbackground" />
        </div>
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Şarj Noktası Bilgileri" key="1">
            <div className="row tabpane">
              <div style={{ marginLeft: '5rem' }} className="stationinfo">
                <ul>
                  <li>
                    <p>
                      <b>Şarj Noktası Adı : </b>
                      {summary && summary.name}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Şarj Noktası Konumu : Lat </b>{' '}
                      {summary && +summary.lat} <b> Lng </b>{' '}
                      {summary && +summary.lng}{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>{languagesCms.ADRESS} : </b>{' '}
                      {summary && summary.address}{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        <b>{languagesCms.COORDINATES} :</b>
                      </span>
                      <span>
                        {summary &&
                          summary.location &&
                          summary.location.coordinates}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        <b>{languagesCms.REGION} :</b>
                      </span>
                      <span>
                        {summary && summary.location && summary.location.region}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        <b>{languagesCms.TIME_PERIOD} :</b>
                      </span>
                      <span>
                        {summary &&
                          summary.location &&
                          summary.location.time_period}
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Cihaz Bilgileri" key="2">
            <div style={{ margin: '5px' }}>
              <DriverPicker
                record={data}
                setRecord={setData}
                name="userCardNumber"
                languagesCms={languagesCms}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <div>
                <Tabs defaultActiveKey="1" centered>
                  {summary &&
                    summary.connectors &&
                    summary.connectors.map((item, index) => {
                      return (
                        <TabPane tab={`Cihaz ${index + 1}`} key={index + 1}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              padding: '2rem',
                              margin: 'auto',
                            }}
                          >
                            <ul style={{ listStyle: 'none' }}>
                              <li
                                style={{
                                  float: 'left',
                                  margin: 'auto',
                                  padding: '0 .5rem',
                                }}
                              >
                                <div>
                                  <b>Cihaz Unique ID : </b>
                                  {item.unique_id}
                                </div>
                              </li>
                              <li
                                style={{
                                  float: 'left',
                                  margin: 'auto',
                                  padding: '0 .5rem',
                                }}
                              >
                                <p>
                                  <b>Cihaz Adı : </b>
                                  {item.name}
                                </p>
                              </li>
                              <li
                                style={{
                                  float: 'left',
                                  margin: 'auto',
                                  padding: '0 .5rem',
                                }}
                              >
                                <p>
                                  <b>Cihaz Konum Türü : </b>
                                  {item.location.location_type === 'Outdoor'
                                    ? 'Dışarıda'
                                    : 'İçeride'}
                                </p>
                              </li>
                              <li
                                style={{
                                  float: 'left',
                                  margin: 'auto',
                                  padding: '0 .5rem',
                                }}
                              >
                                <p>
                                  <b>Cihaz Kat Sayısı : </b>
                                  {item.location.floor_location} <br />
                                  {!(
                                    auth &&
                                    auth
                                      .find((a) => a.number === user.role)
                                      .restraintList.includes('reset')
                                  ) && (
                                    <Popconfirm
                                      onConfirm={() =>
                                        resetDevice(item.unique_id)
                                      }
                                      title={
                                        languagesCms.THE_DEVICE_WILL_BE_RESET
                                      }
                                      okText={'Reset'}
                                      cancelText={'Cancel'}
                                    >
                                      <Button
                                        type="danger"
                                        style={{ borderRadius: '7px' }}
                                        icon={<RedoOutlined />}
                                      >
                                        {'Reset'}
                                      </Button>
                                    </Popconfirm>
                                  )}
                                </p>
                              </li>
                              <li
                                style={{
                                  float: 'left',
                                  margin: 'auto',
                                  padding: '0 .5rem',
                                }}
                              >
                                <p>
                                  <FirmwareUpdate name={record && record.bayi && record.bayi.bayiName } unique_id={ item && item.unique_id } />
                                </p>
                              </li>
                              <li
                                style={{
                                  float: 'left',
                                  margin: 'auto',
                                  padding: '0 .5rem',
                                }}
                              >
                                <p>
                                  <b>Cihaz Durumu : </b>
                                  {item.chargepointStatus === 'unknown'
                                    ? 'Bilinmiyor'
                                    : item.chargepointStatus ===
                                      'suitable_for_charging'
                                    ? 'Şarja Uygun'
                                    : item.chargepointStatus === 'in_use'
                                    ? 'Kullanımda'
                                    : item.chargepointStatus ===
                                      'out_of_service'
                                    ? 'Servis Dışı'
                                    : item.chargepointStatus}
                                </p>
                              </li>
                              <li
                                style={{
                                  float: 'left',
                                  margin: 'auto',
                                  padding: '0 .5rem',
                                }}
                              >
                                <p>
                                  <b>Konnektör Tipi AC : </b>
                                  {item.typeAc ? item.typeAc : '0'}
                                </p>
                              </li>
                              <li
                                style={{
                                  float: 'left',
                                  margin: 'auto',
                                  padding: '0 .5rem',
                                  marginBottom: '3rem',
                                }}
                              >
                                <p>
                                  <b>Konnektör Tipi DC : </b>
                                  {item.typeDc ? item.typeDc : '0'}
                                </p>
                              </li>
                              <br /> <br />
                              <br /> <br />
                              <br /> <br />
                              {item.connector.map((element, index) => {
                                return (
                                  <>
                                    <li
                                      style={{
                                        float: 'left',
                                        margin: 'auto',
                                        padding: '0 .5rem',
                                      }}
                                    >
                                      <div>
                                        <b>
                                          Konnektör No : {element.connector_id}{' '}
                                        </b>{' '}
                                        <br />
                                        {element.status.toLocaleUpperCase(
                                          'en-US',
                                        )}
                                        <b>{' - '}</b>
                                        {element.type
                                          ? element.type
                                          : ' Type Bilinmiyor'}
                                        <b>{' - '}</b>
                                        {element.power
                                          ? `${element.power}  kW`
                                          : ' Güç Bilinmiyor'}{' '}
                                        <br />
                                        <div style={{ margin: 'auto' }}>
                                          <Button
                                            type="primary"
                                            size="sm"
                                            style={{
                                              float: 'left',
                                              marginRight: '5px',
                                            }}
                                            onClick={() =>
                                              startCharge(
                                                item.unique_id,
                                                element.connector_id,
                                                data.userCardNumber,
                                              )
                                            }
                                          >
                                            Start Şarj
                                          </Button>
                                          <Button
                                            type="danger"
                                            size="sm"
                                            onClick={() =>
                                              stopCharge(
                                                item.unique_id,
                                                element.connector_id,
                                              )
                                            }
                                          >
                                            Stop Şarj
                                          </Button>
                                        </div>
                                        <span
                                          style={{
                                            fontWeight: '500',
                                            margin: 'auto',
                                          }}
                                        >
                                          {element.status.toLocaleUpperCase(
                                            'en-US',
                                          )}
                                        </span>
                                        {/* <div style={{margin: "auto", fontWeight: 500}}> { element.price} / kWh</div> */}
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          </div>
                        </TabPane>
                      );
                    })}
                </Tabs>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Hizmetler" key="3">
            <div className="row tabpane">
              <div style={{ marginLeft: '5rem' }} className="stationinfo">
                <li>
                  <p>
                    <span>
                      <b>{languagesCms.CHARGING_CAPACITY} : </b>
                    </span>
                    <span>
                      {summary &&
                        summary.services &&
                        summary.services.charging_capacity}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>{languagesCms.TIME_PERIOD} : </b>
                    </span>
                    <span>
                      {summary &&
                        summary.location &&
                        summary.location.time_period}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>{languagesCms.CHARGING_SERVICE} : </b>
                    </span>
                    <span>
                      {summary &&
                        summary.services &&
                        summary.services.charging_service}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>{languagesCms.FREE_PARKING} : </b>
                    </span>
                    <span>
                      {summary &&
                        summary.services &&
                        summary.services.free_parking}{' '}
                      {languagesCms.HOUR}{' '}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>{languagesCms.PARK_AREA} : </b>
                    </span>
                    <span>
                      {summary &&
                        summary.services &&
                        summary.services.park_area}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>{languagesCms.RESERVATION} : </b>
                    </span>
                    <span>
                      {summary &&
                        summary.services &&
                        summary.services.reservation}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>Diğer : </b>
                    </span>
                    <span>Restoran, Akaryakıt İstasyonu, Otel, Avm</span>
                  </p>
                </li>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Çalışma Saatleri" key="4">
            <div className="row tabpane">
              <div style={{ marginLeft: '5rem' }} className="stationinfo">
                <li>
                  <p>
                    <span>
                      <b>{languagesCms.MONDAY} : </b>
                    </span>
                    <span>
                      {summary && summary.hours && summary.hours.monday}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>{languagesCms.TUESDAY} : </b>
                    </span>
                    <span>
                      {summary && summary.hours && summary.hours.tuesday}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>{languagesCms.WEDNESDAY} : </b>
                    </span>
                    <span>
                      {summary && summary.hours && summary.hours.wednesday}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>{languagesCms.THURSDAY} : </b>
                    </span>
                    <span>
                      {summary && summary.hours && summary.hours.thursday}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>{languagesCms.FRIDAY} : </b>
                    </span>
                    <span>
                      {summary && summary.hours && summary.hours.friday}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>{languagesCms.SATURDAY} : </b>
                    </span>
                    <span>
                      {summary && summary.hours && summary.hours.saturday}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>{languagesCms.SUNDAY} : </b>
                    </span>
                    <span>
                      {summary && summary.hours && summary.hours.sunday}
                    </span>
                  </p>
                </li>

                <li>
                  <p>
                    <span>
                      <b>{languagesCms.HOLIDAYS} : </b>
                    </span>
                    <span>
                      {summary && summary.hours && summary.hours.holidays}
                    </span>
                  </p>
                </li>
              </div>
            </div>
          </TabPane>
          <TabPane tab="İletişim" key="5">
            <Contact id={record._id} />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};
