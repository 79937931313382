import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let { record, setRecord, name } = props;
	let [room, setRoom] = useState([]);
	let [location, setLocation] = useState([]);
	let _params = { query: {deleted: { $ne: true }}, sort: "-createdAt", page: 1, pageSize: 100000 };
	let languagesCms = props.languagesCms;
	useEffect(() => {
		api.get(`/rest/location?${qs.stringify(_params)}`).then(({ data }) => {
			if(data && data.result){
				let dbRooms = data.result.rows.sort((a, b) => a.name.localeCompare(b.name))
				let filteredRooms = dbRooms.filter(e => e.type === "room")
				setRoom(filteredRooms)
				
			}
		})
	}, [])






	let selectedRoomLocation = (v) => {
		let selectedRoomLocation = room.find(e => e.name === v)
		console.log(selectedRoomLocation, "selectedRoomLocation")
		setRecord({ ...record, [name]: selectedRoomLocation})
	}
	
	return <Select 
		defaultValue={record["locationName"] ? record["locationName"] : []}
		placeholder={"Oda Lokasyonu Seçiniz"}
		disabled={record["pay"] === true ? true : false}
		showSearch={true}
		onChange={selectedRoomLocation}
		 filterOption={(input, option) =>
       		option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
			}>
				   
		{(room) && room.map(b =>
			<Select.Option key={b._id} value={b.name} >{b.name}</Select.Option>)}
	</Select>;
};
