import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Select,
  Alert,
  Avatar,
  Badge,
  Breadcrumb,
  DatePicker,
  Switch,
  MinusOutlined,
  PlusOutlined, QuestionOutlined ,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { CityPicker, FileInputSingle } from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/tr_TR';
import 'crypto'
import axios from 'axios';
import { Space } from 'antd/es';
import qrCodeImage from '../components/qrcode/qrCodeImage';
import lang from "../lang";

let secret = process.env.HASH_SECRET;

const { Option } = Select;

const UserDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;

  const [authorizations] = useGlobal('authorizations');
  const [user] = useGlobal('user');
  const [rooms, setRooms] = useState([]);
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const { Option } = Select;

  
  
  let read_qr = () => {
    let first = data[0];
    let headers = ""
    let qr = ""

    if (qr) {
      let keys = Object.keys(first);
      let items = data.map(item => {
        return keys.map(key => item[key]).join(";")
      }).join
    }
  }

  var element = document.createElement("x");
  element.setAttribute("href", `data:text/plain;chartset=utf-8`);
    element.setAttribute("create", "");
    element.style.display = "1";
    document.body.appendChild(element)
    element.click();
    document.body.removeChild(element);

  let newRecord = {
    nameSurname: '',
    isActive: true,
    roomType: [],
    phoneNumber: '',
    pw: '',
    _id:'',
    admin:'',
    createdAt:'',
    updatedAt:'',
    countPerson:'',    
    qr_id:'',
    qr_url:'',
  };
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');

  

  let roles = [
    { id: "admin", name: 'Admin' },
    // { id: "bireysel", name: 'Bireysel' },
    // { id: "kurumsal", name: 'Kurumsal' },
    { id: "personel", name: 'Personel' },

  ]
  //onetime run
  useEffect(() => {
    if (id) {
      setLoading(true);
      api.get('/rest/user/' + id).then(({ data: { result, result_message } }) => {
        setData(result);
        setLoading(false);
      });
    }
  }, [id]);

  useEffect(() => {
    
    api.get('/rest/room').then(({ data: { result, result_message } }) => {
      console.log("rooms", result.rows)
      setRooms(result.rows);
      let room1 = result.rows.map(room => room._id)[0];
      let selected = room1 ? [room1] : []
      setData(data => ({...data, roomType: selected} ));
    })
  }, []);

  let createQr= useCallback((e)=> {
    if (!data.roomType) {
      message.error("Oda seçilmediği için QR kod oluşturulamaz.", 2);
      return;
    } else {
      message.success(lang.qrSave, 2);
    }
    console.log({data})
    let run = async () => {
      e.preventDefault();
      e.stopPropagation();
  
      let response = await api.post("/rest/qr/generate", {
        userId: id,
        rooms: data.rooms
      });
      let qr = response.data.result;
  
      setData({
        ...data,
        qr_url: qr.url, 
        qr_token: qr.token
      })
  
      api
        .put('/rest/user/' + id, {...data, qr_url: qr.url, qr_token: qr.token})
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            //message.success(lang.UptadeQR, 2);
          } else message.error(result_message.message, 2);
        });
    }

    run().catch(console.error)

  }, [data]) 

  let validate = useCallback(() => {
    let errors = {};

    // if(!data.name) errors.name = 'Ad Soyad boş bırakılamaz';
    // if(!data.phone) errors.phone = 'Telefon boş bırakılamaz';
    // if(!data.email) errors.email = 'E-posta boş bırakılamaz';
    // if(!data.role) errors.role = 'Rol boş bırakılamaz';
    // if(!data.code) errors.code = 'Kod boş bırakılamaz';
    // if(!data.password) errors.password = 'Şifre boş bırakılamaz';
    // if(!data.active) errors.active = 'Aktiflik boş bırakılamaz';

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        // if (data.phone && !data.phone.startsWith('+')) {
        //   data.phone = '+' + data.phone;
        // }
        api
          .put('/rest/user/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Düzenle", 2);
              history.push('/user');
            } else message.error(result_message.message, 2);
          });
      } else {
        // if (data.phone && !data.phone.startsWith('+')) {
        //   data.phone = '+' + data.phone;
        // }
        api
          .post('/rest/user', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Kaydet", 2);
              history.push('/user/edit/' + result._id);
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  const sendCode = () => {
    api
      .post('/rest/user/sendCode', { phone: data.phone })
      .then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          message.success("Kod Gönderildi", 2);
          setData({ ...data, code: result.code });
        } else {
          message.error(result_message.message, 2);
        }
      });
  }

  /*const createPassword = () => {
    // create random password
    let password = Math.random().toString(36).slice(-8);
    return password;
  }*/


  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h3 style={{ color: '#fff', marginBottom: '8px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a
                  style={{ color: '#fff', fontSize: '18px' }}
                  href="/user"
                >
                  {lang.users}
                </a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </h3>
        </div>

        <div className="list-buttons">
          <Link to="/user">
            <Button
              type="light"
              icon={<LeftOutlined />}
              size={'large'}
              style={{ fontSize: '14px', height: '100%' }}
            >
              {lang.back}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={lang.edit}
          loading={loading}
        >
          <Form
            disabled={
              authorizations &&
              authorizations
                .find((a) => a.number === user.role)
                .restraintList.includes('edit')
            }
            layout="horizontal"
            size={'large'}
            // onFinish={save}       
               >
             <Row direction="row">
              
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={lang.nameSurname}
                  help={errors.nameSurname}
                  required
                  validateStatus={errors.nameSurname ? 'error' : 'success'}
                >
                 <Input
                    name="nameSurname"
                    value={data.nameSurname}
                    onChange={(e) => setData({ ...data, nameSurname: e.target.value })}
                    placeholder={lang.nameSurname}
                  />
                </Form.Item>
              </Col>
              
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={lang.authorizationgroup}
                  required
                  help={errors.rooms}
                  validateStatus={errors.rooms ? 'error' : 'success'}
                >
                  <Select
                  mode="multiple"
                  style={{right:'57px'}}
                    name="rooms"
                    defaultValue={''}
                    value={data.rooms}
                    onChange={(e) => setData({ ...data, rooms: e })}
                    placeholder={lang.chooseroom}
                  >
                    {rooms.map(room => <Option key={room._id} value={room._id} name={room.name}>{room.name}</Option> )}
                    
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            

            <Row direction="row">

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={lang.phone}
                  required
                  help={errors.phoneNumber}
                  validateStatus={errors.phoneNumber ? 'error' : 'success'}
                >
                 <Input
                    name="phoneNumber"
                    value={data.phoneNumber}
                    onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
                    placeholder={lang.phone}
                  />
                </Form.Item>
                <Form.Item
                  label={lang.companyKey}
                  required
                  readOnly={id ? true : false}
                  help={errors.companyKey}
                  validateStatus={errors.companyKey ? 'error' : 'success'}
                >
                 <Input
                    name={lang.companyKey}
                    value={data.companyKey}
                    onChange={(e) => setData({ ...data, companyKey: e.target.value })}
                    placeholder={lang.companyKey}
                  />
                  
                  
                  
                </Form.Item>
                <Form.Item
                  label={lang.countPerson}
                  required
                  validateStatus={errors.count ? 'error' : 'success'}
                >
                 <Input
                    name={lang.countPerson}
                    value={data.countPerson}
                    defaultValue={data.countPerson}
                    onChange={(e) => setData({ ...data, countPerson: e.target.value})}
                    placeholder={lang.countPerson}
                  />
                </Form.Item>
                <Form.Item
                  label={lang.password}
                  required
                  help={errors.password}
                  validateStatus={errors.password ? 'error' : 'success'}
                >
                 <Input
                    name={lang.password}
                    onChange={(e) => setData({ ...data, password: e.target.value })}
                    placeholder={lang.password}
                  />
                </Form.Item>
              </Col>

    {data._id && 
      <Button htmlType="button"
        onClick={createQr}
        style={{backgroundColor: '#9eb23b', left:'140px', }}
        type="primary" disabled={loading} size="large">
        {lang.qrCreate}
      </Button>
  }
  
  
      <div style={{marginTop: "80px"}} >
        {data.qr_url &&
          <img src={data.qr_url} />
        }
        {data.qr_url == null && <div>{lang.qrError}</div> }              
      </div>
      
                  {/* <Alert 
                    message="Bu şifre kullanıcıya kaydedildiğinde sms olarak gönderilecektir."
                    type="info"
                    showIcon
                    style={{ marginTop: '10px' }}
                  /> */}
                
              
            </Row>

            <Row direction="row">
            


              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={lang.activity}
                  style={{
                    marginTop:-26,
                  }}
                  required
                  help={errors.isActive}
                  validateStatus={errors.isActive ? 'error' : 'success'}
                >
                  <Switch 
                  checked={data.isActive} 
                  checkedChildren={lang.active}
                  unCheckedChildren={lang.passive}
                  onChange={v => setData({ ...data, isActive: v})} />             
                </Form.Item>
              </Col>
              

            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{
                      backgroundColor: '#9eb23b',
                      borderColor: 'transparent',
                    }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                    onClick={save}
                  >
                    {lang.save}
                  </Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default UserDetail;
