import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../service';
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  InputNumber,
  Alert,
  Button,
  DatePicker,
  message,
  Select,
  Card,
  Switch,
  Upload,
  Form,
} from 'antd';
import {
  DeleteOutlined,
  LeftOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { AttendeePicker3,AttendeePicker2,AttendeePicker } from '../components';
import moment from 'moment';
import { useCallback } from 'react';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
moment.locale('tr');

function StaticNotificationDetail(props) {
	const newRecord = {
		name: 		'',
		userId: 	'',
		active: 	false,
		isRead: 	false,
		action: 	{
			name: '',
			url: 	'',
		},
		date: 		moment().format('YYYY-MM-DD HH:mm:ss'),
		order: 		null,
	}




  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [validationCheck, setValidationCheck] = useState(false);
  let [loading, setLoading] = useState(id ? true : false);


  useEffect(() => {
    if (id) {
      setLoading(true);
      api.get('/rest/staticNotification/' + id).then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

	let validate = useCallback(() => {
    let errors = {};

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);


	const save = (mydata) => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/staticNotification/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Başarılı bir şekilde düzenlendi", 2);
              history.push('/static-notification');
            } else message.error(result_message.message, 2);
          });
      } else {
        api
          .post('/rest/staticNotification', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Kaydedildi", 2);
              history.push('/static-notification');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className='list-head'>
				<div className='list-title'>
					<h3 style={{ color: '#fff', marginBottom: '8px' }}>
						<Breadcrumb>
							<Breadcrumb.Item>
							<a 
								style={{ color: '#fff', fontSize: '18px' }}
								href='/staticNotification'
							>
								Sabit Bildirimler
							</a>
							</Breadcrumb.Item>
						</Breadcrumb>
					</h3>
				</div>

				<div className='list-buttons'>
					<Link to='/static-notification'>
						<Button
							type='light'
							icon={<LeftOutlined />}	
							size='large'
							style={{ fontSize: '14px', height: '100%'}}
						>
							Geri Dön
						</Button>
					</Link>
				</div>
			</div>
			<div className='form-wrap'>
				<Card 
					title={id ? 'Sabit Bildirim Düzenle' : 'Sabit Bildirim Ekle'}
					loading={loading}
					>
					<Form
					layout='horizontal'
					size='large'
					onFinish={save}
					>
						<Row direction="row">
								<Col xs={{ span: 24 }} md={{ span: 12 }}>
									<Form.Item
										label='Başlık | Bildirim İçeriği'
										required
										validateStatus={errors.title ? 'error' : ''}
										help={errors.title}
									>
										<Input.TextArea
											value={data.name}
											onChange={(e) => setData({ ...data, name: e.target.value })}
											name='name'
											placeholder='Başlık | Bildirim İçeriği'
										/>
									</Form.Item>
								</Col>

								{data.userId && data.userId !== '' && (
									<Col xs={{ span: 24 }} md={{ span: 12 }}>
									<Form.Item
										label='Kullanıcı'
										required
										validateStatus={errors.title ? 'error' : ''}
										help={errors.title}
									>
										<AttendeePicker2
											value={data.userId}
											onChange={(e) => setData({ ...data, userId: e })}
											name='userId'
											record={data}
											setRecord={setData}
										/>
									</Form.Item>
								</Col>
								)}
						</Row>

						<Row direction="row">
								<Col xs={{ span: 24 }} md={{ span: 12 }}>
									<Form.Item
										label="Aktiflik"
										required
										validateStatus={errors.title ? 'error' : ''}
										help={errors.title}
									>
										<Switch
											checked={data.active ? true : false}
											onChange={(e) => setData({ ...data, active: e })}
											name='active'
											checkedChildren="Aktif"
											unCheckedChildren="Pasif"
										/>
									</Form.Item>
								</Col>

								<Col xs={{ span: 24 }} md={{ span: 12 }}>
									<Form.Item
										label='Okundu mu?'
										required
										validateStatus={errors.title ? 'error' : ''}
										help={errors.title}
									>
										<Switch
											checked={data.isRead ? true : false}
											onChange={(e) => setData({ ...data, isRead: e })}
											name='isRead'
											checkedChildren="Okundu"
											unCheckedChildren="Okunmadı"
										/>
									</Form.Item>
								</Col>
						</Row>

						<Row direction="row">
								<Col xs={{ span: 24 }} md={{ span: 12 }}>
									<Form.Item
										label='Buton Adı'
										required
										validateStatus={errors.title ? 'error' : ''}
										help={errors.title}
									>
										<Input
											value={data.action ? data.action.name : ''}
											onChange={(e) => setData({ ...data, action: { ...data.action, name: e.target.value } })}
											name='action.name'
											placeholder='Buton Adı'
										/>
									</Form.Item>
								</Col>

								<Col xs={{ span: 24 }} md={{ span: 12 }}>
									<Form.Item
										label='Buton Linki'
										required
										validateStatus={errors.title ? 'error' : ''}
										help={errors.title}
									>
										<Input
											value={data.action ? data.action.url : ''}
											onChange={(e) => setData({ ...data, action: { ...data.action, url: e.target.value } })}
											name='action.url'
											placeholder='https://www.google.com'
										/>
									</Form.Item>
								</Col>
								<Col xs={{ span: 24 }} md={{ span: 12 }}>
									<Form.Item
										label='Sıralama'
										required
										validateStatus={errors.order ? 'error' : ''}
										help={errors.order}
									>
										<InputNumber
											value={data.order}
											onChange={(e) => setData({ ...data, order: parseInt(e) })}
											name='order'
											placeholder='Kaçıncı sırada gözüksün?'
										/>
									</Form.Item>
								</Col>


						</Row>

						<Row direction="row">
							<Col span={24}>
								<Form.Item>
									<Button
										type='primary'
										htmlType='submit'
										style={{ backgroundColor: '#9eb23b', borderColor: 'transparent' }}
										block
										size='large'
										disabled={loading}
									>
										{' '}
										{"Kaydet"}{' '}	
									</Button>
								</Form.Item>
							</Col>
						</Row>

					</Form>
				</Card>
			</div>
    </div>
  );
}

export default StaticNotificationDetail;