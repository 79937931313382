import React from 'react';
import { useGlobal } from 'reactn';
import Cookies from 'js-cookie';
import {MdClose, MdLogout } from 'react-icons/md'
import { Button } from 'antd';
import lang from "../lang";
import {ln, set_lang} from "../lang.js"
import { Dropdown, Menu } from 'antd/es';

const HeaderBar = (props) => {
  let [, setToken] = useGlobal('token');


  let logout = (props) => {
    Cookies.remove('token');
    window.localStorage.clear();
    setToken(null);
  };

  return (
    <div className="topMenu">
          <Button  onClick={()=>set_lang("en")}
            type={ln == "en" ? "primary" : "text"}  size={"small"} >EN</Button>
          
          <Button  onClick={()=>set_lang("tr")}
            type={ln == "tr" ? "primary" : "text"}  size={"small"} >TR</Button>

      <Button type="danger" onClick={logout}>{lang.logout}</Button>
    </div>
  );
};

export default HeaderBar;
