import React, { useContext, useEffect, useRef, useState } from 'react';
import api from '../service';
import qs from 'qs';
import Spinner from '../components/Loading';
import { Badge, Button, Popconfirm, Space, Table, message,Form, Input, Alert } from 'antd';
import { turkishSort } from '../stores/utils';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons';
import auth from '../stores/auth';
import moment from 'moment';
import { getPathName } from '../stores/utils';

function Event(props) {
  const { Search } = Input;
  const [search, setSearch] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventReservation, setEventReservation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const EditableContext = React.createContext(null);
  let path = props.location.pathname.split('/')[1];
  const paths = getPathName(path);

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    )
  }

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };
    let childNode = children;
    if(editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const handleSave = async (row) => {
    const newData = [...events];
    const index = newData.findIndex((item) => row._id === item._id);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setEvents(newData);
  }

  const handleAdd = (row) => {
    const newData = [...events];
    newData.push(row);
    api.post('/rest/event', row)
    .then(({ data: { result,result_message } }) => {
      console.log(result_message, result,"--------------")
      if(result_message.type === 'success') {
        message.success('Etkinlik başarıyla kopyalandı.');
      } else {
        message.error('Etkinlik kopyalanamadı.');
      }

    })
    setEvents(newData);
  }

  const query = [];

  const get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 1000 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }
    let _params = { sort: "-createdAt", page: 1, pageSize: 100000 };
    // let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name, location.name,location_tag';
    }

    api
      .get(`/rest/event?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          setEvents(
            data.result.rows.map((item, key) => ({
              ...item,
              key: key,
            })),
          );
        }
      })
      .then(() => {
        setLoading(false);
      });

    api
      .get(`/rest/eventReservation?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          setEventReservation(
            data.result.rows.map((item, key) => ({
              ...item,
              key: key,
            })),
          );
        }
      })
      .then(() => {
        setLoading(false);
      });

  };


  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, rows) => {
      setSelectedRows(rows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  useEffect(() => {
    setLoading(true);
    get();
  }, []);

  const deleteRow = async (item_id) => {
    // eğer bir etkinliğe ait bilet varsa silinmesin
    let query = {
      eventId : item_id
    }
    let eventReservation = await api.get(`/rest/eventReservation?${qs.stringify(query)}`, { query });
    if(eventReservation.data.result.total > 0) {
      message.error('Bu etkinliğe ait bilet bulunmaktadır. Silinemez.');
      return;
    } else if (eventReservation.data.result.total === 0) {
      api.delete(`/rest/event/${item_id}`);
      setLoading(true);
      get();
    }
    
  };

  const deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      let query = {
        eventId : item._id
      }
      let eventReservation = api.get(`/rest/eventReservation?${qs.stringify(query)}`, { query });
      if(eventReservation.data.result.total > 0) {
        message.error('Bu etkinliğe ait bilet bulunmaktadır. Silinemez.');
        return;
      } else if (eventReservation.data.result.total === 0) {
        api.delete(`/rest/event/${item._id}`);
        let newData = events.filter((el) => el._id !== item._id);
        setEvents(newData);
        setLoading(true);
        get();
      }
    });
  };

  let columns = [
    {
      title: 'İsim',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => turkishSort(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Konum',
      dataIndex: 'location',
      key: 'location',
      render : (location) => {
        return location ? location.name : '-'
      }
    },
    {
      title: 'Ücret',
      dataIndex: 'price',
      key: 'price',
      render : (price) => {
        return price ? price + ' ₺' : '-'
      }
    },
    {
      title: "Satılan Bilet Sayısı",
      render : (row) => {
        let count = 0;
        eventReservation.forEach((item) => {
          if(item.eventId === row._id){
            // item.count string olarak geliyor bu yüzden parseInt ile sayıya çeviriyoruz ve tüm count ları topluyoruz
            count += parseInt(item.count);

          }
        })
        return count;
      }
    },
    {
      title: 'Kalan Bilet Sayısı',
      render : (row) => {
        let count = 0;
        eventReservation.forEach((item) => {
          if(item.eventId === row._id){
            // item.count string olarak geliyor bu yüzden parseInt ile sayıya çeviriyoruz ve tüm count ları topluyoruz
            count += parseInt(item.count);

          }
        })
        return row.total_limit - count;
      }
    },
    {
      title: 'Toplam Bilet Sayısı',
      dataIndex: 'total_limit',
      key: 'total_limit',
      render : (total_limit) => {
        return total_limit ? total_limit : '-'
      }
    },
    {
      title: 'Başlangıç Tarihi',
      dataIndex: 'startTime',
      key: 'startTime',
      render : (startTime) => {
        return startTime ? startTime : '-'
      }
    },
    {
      title: 'Bitiş Tarihi',
      dataIndex: 'endTime',
      key: 'endTime',
      render : (endTime) => {
        return endTime ? endTime : '-'
      }
    },
    {
      title: 'Etkinlik Durumu',
      render : (row) => {
        let now = moment(new Date(),'DD-MM-YYYY HH:mm').toDate();
        let end = moment(row.endTime,'DD-MM-YYYY HH:mm').toDate();
        let start = moment(row.startTime,'DD-MM-YYYY HH:mm').toDate();
        if(now < end && now > start){
          return <Badge 
            status="success" 
            count="Etkinlik Devam Ediyor" 
            className="site-badge-count-109"
            style={{
              backgroundColor: '#52c41a',
            }}
          />
        } else if(now < start){
          return <Badge 
            status="success" 
            count="Etkinlik Devam Ediyor" 
            className="site-badge-count-109"
            style={{
              backgroundColor: '#52c41a',
            }}
          />
        }
        else{
          return <Badge 
            status="success" 
            count="Etkinlik Bitti" 
            className="site-badge-count-109"
            style={{
              backgroundColor: '#f5222d',
            }}
          />
        }
      
      },
      filters : [
        {
          text : 'Etkinlik Devam Ediyor',
          value : 'active'
        },
        {
          text : 'Etkinlik Bitti',
          value : 'passive'
        }
      ],
      onFilter : (value, record) => {
        let now = new Date();
        let end = new Date(record.endTime);
        if(value === 'active'){
          return now < end
        }else{
          return now > end
        }
      }
    },
    // {
    //   title: 'Kontenjan',
    //   dataIndex: 'total_limit',
    //   key: 'total_limit',
    // },
    // {
    //   title: 'Boş Kontenjan',
    //   dataIndex: 'available_count',
    //   key: 'available_count',
    // },
    {
      title: 'İşlemler',
      key: 'action',
      render: (row) => (
        <>
        <div className="tbl-actions">
          <Space size="small">
            <Link to={'/event/edit/' + row._id}>
              <Button icon={<EditOutlined />}>Düzenle</Button>
            </Link>
            <Popconfirm
              onConfirm={() => deleteRow(row._id)}
              title={'Silme işlemini onaylıyor musunuz?'}
              okText={'Evet'}
              cancelText={'İptal'}
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                Sil
              </Button>
            </Popconfirm>
          </Space>
        </div>
        <div className="tbl-actions">
          <Space size="small">
            <Button 
              icon={<CopyOutlined />}
              style={{backgroundColor: '#7d5fff', color: '#fff', marginTop: '10px'}}
              onClick={
                () => {
                  handleAdd(row)
              }}
            >
              Bu Etkinliği Kopyala
            </Button>
          </Space>
          {/* <Alert
              message="Kopyalanan etkinlik listenin en üstüne eklenecektir."
              type="info"
              style={{marginTop: '10px', fontSize: '11px'}}
            /> */}
        </div>
        </>
      ),
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    }
  }

  

  const myColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    }
  });


  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  


  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  return (
    <div>
      {loading && <Spinner />}
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {paths ? paths : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons py-5">
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            size="large"
            onClick={deleteSelectedRows}
            style={{
              fontSize: '14px',
              height: '100%',
              marginRight: '10px',
            }}
          >
            Seçilenleri Sil
          </Button>

          <Link to="/event/add" style={{ marginRight: '5px' }}>
            <Button
              type="light"
              style={{ fontSize: '14px', height: '100%' }}
              icon={<PlusOutlined />}
              size="large"
            >
              Etkinlik Ekle
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <div className="table-wrapper">
          <Search
            placeholder="Arama..."
            onChange={onChange}
            onSearch={(v) => {
              setSearch(v);
              get();
            }}
          />
          <Table
            dataSource={events}
            columns={myColumns}
            components={components}
            onChange={handleTableChange}
            pagination={{
              total: totalCount,
              showSizeChanger: false
            }}
            rowSelection={{ ...rowSelection }}
          />
        </div>
      </div>
    </div>
  );
}

export default Event;
