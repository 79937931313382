import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index'
import qs from 'qs';
import moment from 'moment';

export default (props) => {
	let { record, setRecord, name } = props;
	let [event, setEvent] = useState([]);
	let _params = { query: {status: {$ne:false}}, sort: "-createdAt", page: 1, pageSize: 100000 };
	useEffect(() => {
		api.get(`/rest/event?${qs.stringify(_params)}`).then(({ data }) => {
			if(data && data.result){
				let dbEvents = data.result.rows.sort((a, b) => a.name.localeCompare(b.name))
				setEvent(dbEvents);
			}
		})
	}, [])

	let findEventName = (eventId) => {
		const tmp = event.find((event) => event._id === eventId);
		return tmp ? tmp.name : null;
	}



	let selectedEvents = (v) => {
		let selectedEvent = event.find(e => e._id === v)
		let selectedEventPrice = selectedEvent.price
		let selectedEventLocation = selectedEvent.location
		setRecord({ ...record, [name]:  selectedEvent._id, "eventName": selectedEvent.name, "totalprice": selectedEventPrice, "eventlocation": selectedEventLocation})
	}


	
	return <Select 
		defaultValue={record[name] ? record["eventName"]: []}
		placeholder={"Etkinlik Seçiniz"}
		showSearch={true}
		// disabled={record[name] ? record["pay"]: false}
		allowClear={true}
		onChange={selectedEvents}
		>
		
		{(event) && record["eventlocation"] && record["eventlocation"].name && event.filter(e => {
			let now = moment(new Date(),'DD-MM-YYYY HH:mm').toDate();
			let end = moment(e.endTime,'DD-MM-YYYY HH:mm').toDate();
			let start = moment(e.startTime,'DD-MM-YYYY HH:mm').toDate();
			if(e.status === false) return false;
			return e.location.name === record["eventlocation"].name && now < end && now > start
		}).map(b =>
			<Select.Option key={b._id} value={b._id} >{b.name}</Select.Option>)}
	</Select>;
};
