import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
;

export default (props) => {
    let { record, setRecord, name } = props;
    let [feature, setFeature] = useState([]);
    const tags = [
        {
            "_id":"wifi",
            "name":"Wifi",
            "icon":"http://ismekan-api.arneca.com/icons/wifi.png"
        },
        {
            "_id":"tea",
            "name":"Çay",
            "icon":"http://ismekan-api.arneca.com/icons/wifi.png"
        },
        {
            "_id":"coffee",
            "name":"Kahve",
            "icon":"http://ismekan-api.arneca.com/icons/wifi.png"
        },
        {
            "_id":"food",
            "name":"Yemek",
            "icon":"http://ismekan-api.arneca.com/icons/wifi.png"
        }
    ];

    let featureChange = (v) => {
        let feature = tags.filter((value) => {
            return v.includes(value._id)
        })
        setRecord({ ...record, [name]: feature });
    }


    return <Select
        defaultValue={record[name] ? record[name].map((value) => value._id) : []}
        placeholder={"Lütfen Oda Özelliği Seçiniz"}
        mode="multiple"
        onChange={featureChange}>
        {(tags) && tags.map(b =>
            <Select.Option key={b._id} value={b._id}>{b.name}</Select.Option>)}
    </Select>
}