import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name, mode, disabled } = props;
  let [station, setStation] = useState([]);
  let _params = { sort: '-createdAt', page: 1, pageSize: 100000, cmsQuery: { dealerId: record && record.dealerId } };
  let languagesCms = props.languagesCms;

  useEffect(() => {
    api
      .get(`/rest/station-get?${qs.stringify(_params)}`)
      .then(async ({ data }) => {
        if (data && data.result) {
          const dbStation = data.result.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });

          setStation(dbStation);
        }
      });
  }, [record]);

  return (
    <Select
      value={ (record && record[name]) || [] }
      showSearch={true}
      mode={mode}
      placeholder={languagesCms.SELECT_STATION}
      onChange={(v) => setRecord({ ...record, [name]: v })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled || false}
    >
      {station &&
        station.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
