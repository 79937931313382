import React, { useState,  useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let { record, setRecord, name, mode, disabled } = props;
	let [district, setDistrict] = useState([]);
	let _params = { sort: "-createdAt", page: 1, pageSize: 100, city_id: record.cityId };
	let languagesCms = props.languagesCms;

	useEffect(() => {
		api.get(`/rest/district?${qs.stringify(_params)}`).then(({ data }) => {
			if (data && data.result) {
				let dbDistrict = data.result.sort((a, b) => a.name.localeCompare(b.name))
				setDistrict(dbDistrict);
			}
		})
	}, [record])

	return <Select value={district.length > 0 ? record[name] : []}
		showSearch={true}
		mode={mode}
		placeholder={languagesCms.SELECT_DISTRICT}
		onChange={v => setRecord({ ...record, [name]: v })}
		filterOption={(input, option) =>
			option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
		}
		disabled={disabled || false}
	>
		{(district) && district.map(b =>
			<Select.Option key={b.name} value={b._id} >{b.name}</Select.Option>)}

	</Select>


};