import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import api from '../../service';

const firmwareUpload = ({ name, setRefresh }) => {
  const [fileList, setFileList] = useState(null);
  const [uploading, setUploading] = useState(false);


  const handleUpload = () => {
  const extension = fileList.name.split('.').reverse()[0];
    if (extension !== 'bin') {
      setFileList(null);
      return message.error('Lütfen .bin uzantılı dosya seçiniz !');
    }
    else {
      setUploading(true);
      const formData = new FormData();

      formData.append('files', fileList);
      formData.append('key', name);
      api
        .post('/rest/upload-file-group', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (res.data.result_message.type === 'success') {
            message.success(res.data.result_message.message);
          } else {
            message.error(res.data.result_message.message);
          }
          setFileList([]);
          setRefresh(true);
        })
        .catch((err) => {
          message.error('Yükleme Sırasında Hata Çıktı.');
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList(file);
      return false;
    },
    fileList,
  };
  return (
    <>
      {/* yükleme işlemi başladığında butonu disable ediyoruz */}
      {!fileList && (
        <Upload name={name} {...props}>
          <Button icon={<UploadOutlined />}>Dosya Seçin</Button>
        </Upload>
      )}
      {/* yükleme onayı */}
      {fileList && (
        <Button
          type="dashed"
          danger
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
        >
          {uploading ? 'Yükleniyor' : 'Yüklemeyi Onayla'}
        </Button>
      )}
    </>
  );
};
export default firmwareUpload;
