import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Select,
  Breadcrumb,
  Switch,
  InputNumber} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import 'react-phone-input-2/lib/style.css';
import lang from "../lang";



const { Option } = Select;

const RoomDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;


  const [authorizations] = useGlobal('authorizations');
  const [room] = useGlobal('room');
  let [langCms] = useGlobal("langCms"); 
  let [langCode] = useGlobal("langCode");





  let newRecord = {
    name: '',
    limit : null,
    status: true,
    feature: "",
    type: null,
  };
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');

  let [modules] = useGlobal('modules');

  //onetime run
  useEffect(() => {
    if (id) {
      setLoading(true)    
       api.get('/rest/room/' + id)
       .then(({ data: { result, result_message } }) => {
        console.log(result, "result")
        setData(result);
        setLoading(false);
      });
    }
  }, [id]);

  console.log("data", data);

  let validate = useCallback(() => {
    let errors = {};

    // if (!data.name) errors.name = 'Lütfen bu alanı doldurunuz';
    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/room/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Düzenle", 2);
              history.push('/room');
            } else message.error(result_message.message, 2);
          });
      } else {
        api
          .post('/rest/room', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Kaydet", 2);
              history.push('/room');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return <div>
  <div className="list-head">
    <div className="list-title">
      <h3 style={{ color: '#fff', marginBottom: '8px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a
              style={{ color: '#fff', fontSize: '18px' }}
              href="/room"
            >
              {
              lang.rooms
              }
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>
      </h3>
    </div>

    <div className="list-buttons">
      <Link to="/room">
        <Button
          type="light"
          icon={<LeftOutlined />}
          size={'large'}
          style={{ fontSize: '14px', height: '100%' }}
        >
          {
            lang.back
          }
        </Button>
      </Link>
    </div>
  </div>
  <div className="form-wrap">
    <Card
      title={lang.edit}
      loading={loading}
    >
      <Form
        disabled={
          authorizations &&
          authorizations
            .find((a) => a.number === room.role)
            .restraintList.includes('edit')
        }
        layout="horizontal"
        size={'large'}
        onFinish={save}
      >
          <Row direction="row">
          
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label= {lang.roomname}
              required
              help={errors.name}
              validateStatus={errors.name ? 'error' : 'success'}
            >
              
              <Input
                name="name"
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                placeholder={lang.roomname}
              />
            </Form.Item>
          </Col>

          

        </Row>

        <Row direction="row">

          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label={lang.limit}
              help={errors.limit}
              required
              validateStatus={errors.limit ? 'error' : 'success'}
            >
              <InputNumber
                name="limit"
                value={data.limit}
                onChange={(e) => setData({ ...data, limit: e })}
                placeholder="limit"
                type="number"
              />
              
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label={lang.status}
              required
              help={errors.status}
              validateStatus={errors.status ? 'error' : 'success'}
            >
              <Switch
                onChange={(e) => setData({ ...data, status: e })}
                checkedChildren="Aktif"
                unCheckedChildren="Pasif"
                defaultChecked
                
                
              />
            </Form.Item>
          </Col>
        </Row>

        
        <Row direction="row">
          <Col span={24}>
            <Form.Item>
              <Button
                style={{
                  backgroundColor: '#9eb23b',
                  borderColor: 'transparent',
                }}
                type="primary"
                disabled={loading}
                htmlType="submit"
                size="large"
                block
              >
                {lang.save}
                {' '}
               {lang.sav} {' '}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  </div>
</div>



  
};
export default RoomDetail;
