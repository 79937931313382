import { configureStore } from '@reduxjs/toolkit';

import auth from './auth';
import alert from './alert';

const store = configureStore({
  reducer: {
    auth,
    alert
  },
});

export default store;
