import React, { useEffect, useState } from 'react';
import { Button, message, Modal, Tree } from 'antd';
import FirmwareUpload from '../uploads/firmwareUpload';
import api from '../../service';
import './station.css';

const { DirectoryTree } = Tree;

// fonksiyon alanı
const firmwareUpdate = ({ name, unique_id }) => {
  const [selectFile, setSelectFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firmwareUpdateFolders, setFirmwareUpdateFolders] = useState([]);
  const [resfresh, setRefresh] = useState(false);

  // seçileni setleme
  const onSelect = (keys, info) => {
    setSelectFile({ folder: info.node.folder, file: info.node.title });
  };

  // data çekme
  const getFirmwareUpdateFolders = () => {
    api
      .get('/rest/get-firmware-update-folders')
      .then((res) => {
        setFirmwareUpdateFolders(res.data.result);
      })
      .catch((err) => {
        message.error('Kayıtlar Getirilemedi !');
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const update = () => {
    if (selectFile && selectFile.folder) {
      api
        .post('/rest/update-firmware', {
          key: name,
          folder: selectFile.folder,
          file: selectFile.file,
          chargeBoxId: unique_id
        })
        .then((res) => {
          if (res.data.result_message.type === 'success') {
            message.success(res.data.result_message.message);
          } else {
            message.error(res.data.result_message.message);
          }
        })
        .catch((err) => {
          message.error('Güncelleme Gönderilemedi !');
        });
    } else {
      message.error('Lütfen Güncelleme Dosyası Seçiniz !');
    }
  };

  useEffect(() => {
    getFirmwareUpdateFolders();
  }, [resfresh]);

  return (
    <div className='firmware'>
      <Button onClick={() => showModal()} danger type="dashed">
        Firmware Update
      </Button>
      <Modal
        title="Cihaz Güncelleme"
        visible={isModalOpen}
        icon={
          <i
            style={{ color: '#ff4d4f', fontSize: '1.7rem' }}
            className="fa-solid fa-arrow-trend-up"
          ></i>
        }
        onOk={handleOk}
        onCancel={handleOk}
      >
        <>
          <div className="body-div">
            <FirmwareUpload name={name} setRefresh={setRefresh} />
          </div>
          <div className="body-div">
            <Button
              type="dashed"
              disabled={selectFile && selectFile.folder ? false : true}
              onClick={() => {
                update();
              }}
              danger
              icon={<i className="fa-solid fa-paper-plane"></i>}
            >
              Güncelleme Gönder
            </Button>
          </div>
          <div style={{ width: '100%', marginTop: '1rem' }}>
            <DirectoryTree
              style={{ width: '97vh', padding: '1rem', margin: 'auto' }}
              singleExpand={true}
              defaultExpandParent={false}
              onSelect={onSelect}
              treeData={firmwareUpdateFolders}
            />
          </div>
        </>
      </Modal>
    </div>
  );
};

export default React.memo(firmwareUpdate);
