import React from 'react';
import { Input, Alert } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import LocationPicker from 'react-location-picker';
import axios from 'axios';
import useInterval from 'react-useinterval';
let key = 'AIzaSyC1NyStYSSVdxiUaiT26GNYb3jkN-W-Uq4';

export default (props) => {
	let { record, setRecord, preview, name, disabled, required } = props;
	let [search, setSearch] = React.useState('');
	let [lastSearch, setLastSearch] = React.useState(record[name] ? record[name].address : '');
	let location = record && record[name];
	let languagesCms = props.languagesCms;
	let route = async () => {
		let _search = search.length === 0
			? (record[name] ? record[name].address : '')
			: search;

		if (_search != null && _search.length > 3 && _search !== lastSearch) {
			setLastSearch(_search);
			//googleMapsClient.geocode({address=search}).asPromise()
			await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${_search}&key=${key}&sensor=false`)
				.then(({ data: { results } }) => {
					if (results.length > 0) {
						setRecord({
							...record,
							location: {
								address: results[0].formatted_address ? results[0].formatted_address : '',
								position: results[0].geometry.location,
								latitude: results[0].geometry.location.lat,
								longitude: results[0].geometry.location.lng,
								name: record["name"] ? record["name"] : '',
								// name: results[0].address_components[0].long_name ? results[0].address_components[0].long_name : '',
							},
						});
					}
				});
		}
	};

	useInterval(route, 3000, 5);

	return <div>
		{preview &&
			<div style={{
				width: '100%', border: "1px solid #ddd", padding: 10,
				position: 'absolute', marginLeft: 510
			}}>
				<h6 style={{ position: 'absolute', marginTop: - 50 }}>Ön İzleme</h6>
				<p style={{ whiteSpace: "pre-wrap" }}>Adres: {(location && location.address) || ''}</p>
				<br />
				<p>Tel: {record.phone}</p>
			</div>
		}

		{/* <Alert message={"Adreslerin genelden özele dogru yazılması en dogru konumu verir."} banner /> */}
		<Input.TextArea value={(location && location.address) || ''}
			required={required} style={{ width: '100%' }}
			placeholder={"Aramak istediğiniz konumu aşağıdaki kutucuğa giriniz. Adres otomatik olarak doldurulacaktır."}
			readOnly={true}
			disabled={record["pay"] ? true : false}
			onChange={e => setRecord({
				...record,
				[name]: { ...location, address: e.target.value }
			})
			} />

		{!disabled && <div>

			<Input 
				value={search} 
				onChange={e => setSearch(e.target.value)}
				addonAfter={<SearchOutlined />}
				style={{ width: '100%' }} 
				placeholder={"Aramak istediğiniz konumu giriniz."} 
				disabled={record["pay"] ? true : false}
			/>
			<LocationPicker
				containerElement={<div style={{ height: '100%' }} />}
				mapElement={<div style={{ height: 300, width: '100%' }} />}
				defaultPosition={(location && location.position) ? location.position : { lat: 40.9637815, lng: 28.7239388 }}
				onChange={x => {
					setRecord({
						...record,
						location: {
							address: (location && location.address) ? location.address : '',
							position: x.position,
							latitude: x.position.lat,
							longitude: x.position.lng,
							name: (location && location.name) ? location.name : '',
							plan : {
								"url" : "",
								"thumb" : "",
								"type" : "IMAGE",
								"width" : 1600,
								"height" : 900
							},
							plan_address : (location && location.address) ? location.address : '',
						}
					});
				}}
			/>
		</div>}
	</div>;
};