import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';

export default (props) => {
  let { record, setRecord, name, mode, disabled } = props;
  let [times, setTimes] = useState([]);

  useEffect(() => {
    api
      .get(`/rest/times`)
      .then(async ({ data }) => {
        if (data && data.result) {
          const dbTimes = data.result.sort((a, b) => {
            return a.timeId - b.timeId;
          });

          setTimes(dbTimes);
        }
      });
  }, [record]);

  return (
    <Select
      value={ (record && record[name]) || [] }
      showSearch={true}
      mode={mode}
      placeholder={"Rezervasyon Saatleri Seçiniz"}
      onChange={(v) => setRecord({ ...record, [name]: v })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled || false}
    >
      {times &&
        times.map((b) => (
          <Select.Option key={b._id} value={b.timeId}>
            {b.time}
          </Select.Option>
        ))}
    </Select>
  );
};
