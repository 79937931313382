import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../service';
import moment from 'moment';
import {
  LeftOutlined,
} from '@ant-design/icons';
import { EventTypePicker,FileInput,EventLocationPicker } from '../components';
import {
  Button,
  Card,
  DatePicker,
  message,
  Switch,
  Form,
  Col,
  Row,
  Input,
  Select,
  Alert} from 'antd';
import { useCallback } from 'react';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
moment.locale('tr');

function EventDetail() {

  let newRecord = {
    content:            null,
    status:             false,
    startTime:          null,
    is_favorite:        false,
    medias:             [],
    price:              null,
    location:           null,
    date:               null,
    location_tag:       'Nişantaşı',
    tag:                null,
    endTime:            null,
    available_count:    null,
    currency:           'TL',
    name:               null,
    total_limit:        null,
    startTime:          "",
    endTime:            "",
  }


  const params = useParams();
  const history = useHistory();
  const id = params.id !== 'add' ? params.id : false;
  let [data, setData] = useState(id ? [] : newRecord);
  const [loading, setLoading] = useState(id ? true : false);
  let [errors, setErrors] = useState([]);
  let [validationCheck, setValidationCheck] = useState(false);
  const [startDate, setStartDate] = useState();
  const [locations, setLocations] = useState([]);



  const save = (mydata) => {

    setValidationCheck(true);
    let err = validate()
    if(err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        // data.date = moment(startDate).format('DD-mm-yyyy');
        // data.startTime = moment(data.startTime).format('DD-mm-yyyy HH:mm');
        // data.endTime = moment(data.endTime).format('DD-mm-yyyy HH:mm');
        api
          .put('/rest/event/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success('Başarıyla kaydedilmiştir', 2);
              history.push('/event');
            } else message.error(result_message.message, 2);
          });
      } else {
        // data.date = moment(startDate).format('DD-mm-yyyy');
        // data.startTime = moment(data.startTime).format('DD-mm-yyyy HH:mm');
        // data.endTime = moment(data.endTime).format('DD-mm-yyyy HH:mm');
        
        api
          .post('/rest/event', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success('Başarıyla kaydedilmiştir', 2);
              history.push('/event');
            } else message.error(result_message.message, 2);
          });
      }
    }
  };


  useEffect(() => {

    const params = { page: 1, pageSize: 1000 };
    api.get(`/rest/location`, { params })
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          setLocations(
            data.result.rows.filter(location => location.type === 'event').map((item, key) => ({
              key: item._id,
              value: item._id,
              label: item.name,
            })),
          );
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      api
        .get('/rest/event/' + id).then(({ data: { result, result_message } }) => {
          if (result) {
            setLoading(true)
            setData(result);
            setLoading(false);
          }
            // setData(result);
            // setLoading(false);
          });
      }
    }, [id]);


    let validate = useCallback(() => {
      let errors = {};

      if (!data.name)               errors.name             = 'Etkinlik adı boş bırakılamaz';
      if (!data.status)             errors.status           = 'Etkinlik durumu boş bırakılamaz';
      if (!data.price)              errors.price            = 'Etkinlik fiyatı boş bırakılamaz';
      if (!data.total_limit)        errors.total_limit      = 'Etkinlik toplam kontenjan boş bırakılamaz';
      if (!data.available_count)    errors.available_count  = 'Etkinlik müsait kontenjan boş bırakılamaz';
      if (!data.startTime)          errors.startTime        = 'Etkinlik başlangıç saati boş bırakılamaz';
      if (!data.endTime)            errors.endTime          = 'Etkinlik bitiş saati boş bırakılamaz';
      if (!data.tag)                errors.tag              = 'Etkinlik etiketi boş bırakılamaz';
      if (!data.qrStartTime)        errors.qrStartTime      = 'Etkinlik QR başlangıç saati boş bırakılamaz';
      if (!data.location)           errors.location         = 'Etkinlik lokasyonu boş bırakılamaz';
      if (!data.content)            errors.content          = 'Etkinlik açıklaması boş bırakılamaz';
      if (!data.medias)             errors.medias           = 'Etkinlik medyası boş bırakılamaz';



  
      errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
      errors.hasError = errors.all.length > 0;
      return errors;
    }, [data]);
  
    useEffect(() => {
      if (validationCheck) setErrors(validate());
    }, [validationCheck, data, validate]);

   

    return (
      <div>
        <div className="list-head">
          <div className="list-title">
            <h1>{module ? module.name: ""}</h1>
          </div>
          <div className="list-buttons">
            <div className="list-buttons py-5">
              <Link to="/event">
                <Button 
                  type='danger'
                  icon={<LeftOutlined />} 
                  style={{
                    fontSize: '14px',
                    height: '100%',
                    marginRight: '10px',
                  }}
                  >
                    Geri Dön
                  </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="form-wrap">
          <Card title={ id ? "Etkinlik Düzenle" : "Etkinlik Ekle"} loading={loading}>
            <Form layout="horizontal" size="large" onFinish={save}>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Etkinlik Adı"
                    required
                    help={errors.name}
                    validateStatus={errors.name ? 'error' : 'success'}
                  >
                    <Input
                      name="name"
                      value={data.name}
                      onChange={(e) => setData({ ...data, name: e.target.value })}
                      placeholder="Etkinlik Adı"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Durum"
                    required
                    help={errors.status}
                    validateStatus={errors.status ? 'error' : 'success'}
                  >
                    <Switch
                      name="status"
                      checked={data.status}
                      checkedChildren="Aktif"
                      unCheckedChildren="Pasif"
                      onChange={(e) => setData({ ...data, status: e })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Ücret"
                    required
                    help={errors.price}
                    validateStatus={errors.price ? 'error' : 'success'}
                  >
                    <Input
                      name="price"
                      value={data.price}
                      onChange={(e) => setData({ ...data, price: Number.isInteger(e.target.value) ? e.target.value : 0 })}
                      placeholder="Ücret"
                      type="number"
                      />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Para Birimi"
                    required
                    help={errors.currency}
                    validateStatus={errors.currency ? 'error' : 'success'}
                  >
                    <Select
                      name="currency"
                      value={data.currency}
                      onChange={(e) => setData({ ...data, currency: e })}
                      placeholder="Para Birimi"
                      >
                      <Select.Option value="TL">TL</Select.Option>
                      {/* <Option value="USD">USD</Option>
                      <Option value="EUR">EUR</Option> */}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Kontenjan"
                    required
                    help={errors.total_limit}
                    validateStatus={errors.total_limit ? 'error' : 'success'}
                  >
                    <Input
                      name="total_limit"
                      value={data.total_limit}
                      onChange={(e) => setData({ ...data, total_limit: Number.isInteger(e.target.value) ? e.target.value : 0 })}
                      placeholder="Kontenjan"
                      type="number"
                      />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                    label="Boş Kontenjan"
                    required
                    help={errors.available_count}
                    validateStatus={errors.available_count ? 'error' : 'success'}
                  >
                    <Input
                      name="available_count"
                      value={data.available_count}
                      onChange={(e) => setData({ ...data, available_count: Number.isInteger(e.target.value) ? e.target.value : 0 })}
                      placeholder="Boş Kontenjan"
                      type="number"
                      />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Başlangıç Tarihi"
                    required
                    help={errors.startTime}
                    validateStatus={errors.startTime ? 'error' : 'success'}
                  >
                    <DatePicker
                      name="startTime"
                      defaultValue={()=>moment(data.startTime,"DD-MM-YYYY HH:mm")}
                      value={data.startTime ? moment(data.startTime,"DD-MM-YYYY HH:mm") : null}
                      onChange={(e) => setData({ ...data, startTime:moment(e).format("DD-MM-YYYY HH:mm") })}
                      placeholder="Başlangıç Tarihi"
                      format="DD-MM-YYYY HH:mm"
                      showTime={{ format: 'HH:mm' }}
                      locale={locale}
                      />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Bitiş Tarihi"
                    required
                    help={errors.endTime}
                    validateStatus={errors.endTime ? 'error' : 'success'}
                  >
                    <DatePicker
                      name="endTime"
                      defaultValue={()=>moment(data.endTime,"DD-MM-YYYY HH:mm")}
                      value={data.endTime ? moment(data.endTime,"DD-MM-YYYY HH:mm") : null}
                      onChange={(e) => {
                        // bitiş tarihi başlangıç tarihinden büyük olmalı
                        if(moment(e).isAfter(moment(data.startTime,"DD-MM-YYYY HH:mm"))){
                          setData({ ...data, endTime:moment(e).format("DD-MM-YYYY HH:mm") })
                        } else {
                          message.error("Bitiş tarihi başlangıç tarihinden büyük olmalıdır.")
                        }

                      }}
                      placeholder="Bitiş Tarihi"
                      format="DD-MM-YYYY HH:mm"
                      showTime={{ format: 'HH:mm' }}
                      locale={locale}
                      />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Etkinlik Çeşidi"
                    required
                    help={errors.tag}
                    validateStatus={errors.tag ? 'error' : 'success'}
                  >
                    <EventTypePicker
                      onChange={(e) => setData({ ...data, tag: e })}
                      record={data}
                      setRecord={setData}
                      name="tag"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="QR Okutma Ne Zaman Başlasın?"
                    required
                    help={errors.qrStartTime}
                    validateStatus={errors.qrStartTime ? 'error' : 'success'}
                  >
                    <DatePicker
                      name="qrStartTime"
                      defaultValue={()=>moment(data.qrStartTime,"DD-MM-YYYY HH:mm")}
                      value={data.qrStartTime || data.startTime ? moment(data.qrStartTime || data.startTime,"DD-MM-YYYY HH:mm").subtract(1, 'days') : null}
                      onChange={(e) => setData({ ...data, qrStartTime: moment(e).format("DD-MM-YYYY HH:mm") })}
                      placeholder='QR Okutma Ne Zaman Başlasın?'
                      format="DD-MM-YYYY HH:mm"
                      showTime={{ format: 'HH:mm' }}
                      locale={locale}
                      disabledDate={(current) => {
                        // çok önce olmamalı, data.startTime ve aynı günün 00:01 zamanı başlangıç kabul edilebilir
                        return current && current < moment(data.startTime || moment().format("DD-MM-YYYY 00:01"),"DD-MM-YYYY HH:mm").subtract(1, 'days');
                      }}
                      disabledTime={(current) => {
                        // çok önce olmamalı, data.startTime ve aynı günün 00:01 zamanı başlangıç kabul edilebilir
                        return current && current < moment(data.startTime || moment().format("DD-MM-YYYY 00:01"),"DD-MM-YYYY HH:mm").subtract(1, 'days');
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label="Konum"
                    help={errors.location}
                    required
                    validateStatus={errors.location ? 'error' : 'success'}
                  >
                    <EventLocationPicker
                      setRecord={setData}
                      record={data}
                      name="location"
                      value={data}
                      onChange={(e) => setData({ ...data, location: e })}
                    />
                    {/* <MapInput  
                      record={data} 
                      setRecord={setData} 
                      name="location" 
                      disabled={false} 
                      title="coordinate" 
                      required={false} 
                    /> */}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label="Açıklama"
                    required
                    help={errors.content}
                    validateStatus={errors.content ? 'error' : 'success'}
                  >
                    <Input.TextArea
                      name="content"
                      value={data.content ? data.content : ''}
                      onChange={(e) => setData({ ...data, content: e.target.value })}
                      placeholder="Açıklama"
                      />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label="Medya"
                    required
                    help={errors.medias}
                    validateStatus={errors.medias ? 'error' : 'success'}
                  >
                    <FileInput
                      name='medias' 
                      title='Medyalar' 
                      type='file' 
                      multi={true} 
                      ext={['image']} 
                      record={data} 
                      setRecord={setData} 
                    />

                    {/* <FileInput
                      name='medias'
                      title='Medyalar'
                      type='file'
                      multi={true}
                      record={data}
                      setRecord={setData}
                    /> */}
                    {/* <FileInputSingleArray
                      name='medias' 
                      title='Medyalar' 
                      type='file' 
                      multi={false} 
                      record={data} 
                      setRecord={setData} 
                    /> */}
                    <Alert message="Yüklenecek görüntü 328 x 160 çözünürlüğünde olmalıdır." type="info" />

                  </Form.Item>
                </Col>
              </Row>
              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button
                      style={{
                        backgroundColor: '#9eb23b',
                        borderColor: 'transparent',
                      }}
                      type="primary"
                      disabled={loading}
                      htmlType="submit"
                      size="large"
                      block
                    >
                      {' '}
                      {"Kaydet"}{' '}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            
          </Card>
        </div>
      </div>
    );
}

export default EventDetail;
