import React, { useState, useEffect } from 'react';
import { Select,DatePicker } from 'antd';
;

export default (props) => {
    let { record, setRecord, name } = props;
    let [time, setTime] = useState([]);
    let times = [
        {
            start: "09:00",
            end: "09:30",
            active: true
        },
        {
            start: "09:30",
            end: "10:00",
            active: true
        },
        {
            start: "10:00",
            end: "10:30",
            active: true
        },
        {
            start: "10:30",
            end: "11:00",
            active: true
        },
        {
            start: "11:00",
            end: "11:30",
            active: true
        },
        {
            start: "11:30",
            end: "12:00",
            active: true
        },
        {
            start: "12:00",
            end: "12:30",
            active: true
        },
        {
            start: "12:30",
            end: "13:00",
            active: true
        },
        {
            start: "13:00",
            end: "13:30",
            active: true
        },
        {
            start: "13:30",
            end: "14:00",
            active: true
        },
        {
            start: "14:00",
            end: "14:30",
            active: true
        },
        {
            start: "14:30",
            end: "15:00",
            active: true
        },
        {
            start: "15:00",
            end: "15:30",
            active: true
        },
        {
            start: "15:30",
            end: "16:00",
            active: true
        },
        {
            start: "16:00",
            end: "16:30",
            active: true
        },
        {
            start: "16:30",
            end: "17:00",
            active: true
        },
        {
            start: "17:00",
            end: "17:30",
            active: true
        },
        {
            start: "17:30",
            end: "18:00",
            active: true
        },
        {
            start: "18:00",
            end: "18:30",
            active: true
        },
        {
            start: "18:30",
            end: "19:00",
            active: true
        },
        {
            start: "19:00",
            end: "19:30",
            active: true
        },
        {
            start: "19:30",
            end: "20:00",
            active: true
        },
        {
            start: "20:00",
            end: "20:30",
            active: true
        },
        {
            start: "20:30",
            end: "21:00",
            active: true
        },
        {
            start: "21:30",
            end: "22:00",
            active: true
        },
        
    ]

    let timeChange = (v) => {
        console.log(v);
        // let feature = v.map((value) => {
        //     return ({ _id: value._id, name: value.name, icon: value.icon });
        // })
        // setRecord({ ...record, [name]: feature });
    }

    return <DatePicker.RangePicker
        defaultValue={record[name] ? record[name].map((value) => value._id) : []}
        format="HH:mm"
        onChange={timeChange}>
        {(times) && times.map(b =>
            <Select.Option key={b.start} value={b.end}>Hafta İçi: {b.start} - {b.end}    |   Hafta Sonu: 10:00 - 19:30</Select.Option>)}
    </DatePicker.RangePicker>

    // return <Select
    //     defaultValue={record[name] ? record[name].map((value) => value._id) : []}
    //     placeholder={"Lütfen Çalışma Saatlerini Seçiniz"}
    //     onChange={timeChange}>
    //     {(times) && times.map(b =>
    //         <Select.Option key={b.start} value={b.end}>Hafta İçi: {b.start} - {b.end}    |   Hafta Sonu: 10:00 - 19:30</Select.Option>)}
    // </Select>
}