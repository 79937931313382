import { LeftOutlined } from '@ant-design/icons';
import {
  Alert, Button,
  Card, Col, Collapse,
  DatePicker, Form, Input, message, Row,Select, Switch, TimePicker
} from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';
import moment from 'moment';
import 'moment/locale/tr';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AttendeePicker2, RoomLocationPicker, RoomPicker, RoomReservationTime } from '../components';
import api from '../service';
moment.locale('tr');
const { RangePicker } = DatePicker;
const { Option } = Select;

// ödeme durumları için enum oluştur
const paymentStatus = {
  0: 'Ödendi',
  1: 'Ödenmedi',
  2: 'Rezervasyon Sonrası Ödeme',
  3: 'Nakit Ödeme',
  4: 'Pos Ödeme',
};


function RoomReservationDetail() {

  let newRecord = {
    totalprice:     '',
    pay:            null,
    payStatus:      null,
    date:           '',
    startTime:      '',
    endTime:        '',
    currency:       'TL',
    slots:          [],
    userId:         '',
    roomId:         '',
    active:         null,
  }




  const params            = useParams();
  const history           = useHistory();
  const id = params.id    !== 'add' ? params.id : false;
  let [data, setData]     = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [validationCheck, setValidationCheck] = useState(false);
  const [loading, setLoading] = useState(id ? true : false);
  const [users, setUsers] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [slots, setSlots] = useState([]);
  const { Panel } = Collapse;



  useEffect(() => {
    getUsers();
    getRooms();
  }, []);



  useEffect(() => {
    if (id) {
      setLoading(true);
      api.get('/rest/roomReservation/' + id).then(({ data: { result, result_message } }) => {
        if (result) {
          setData(result);
          setLoading(false);
        }
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if(!data.roomId)              errors.roomId               = 'Lütfen bir oda seçiniz';
    if(!data.userId)              errors.userId               = 'Lütfen bir kullanıcı seçiniz';
    if(!data.startTime)           errors.startTime            = 'Lütfen bir başlangıç tarihi seçiniz';
    if(!data.endTime)             errors.endTime              = 'Lütfen bir bitiş tarihi seçiniz';
    if(!data.totalprice)          errors.totalprice           = 'Lütfen bir fiyat giriniz';
    if(!data.pay)                 errors.pay                  = 'Lütfen bir ödeme durumu seçiniz';
    if(!data.location)            errors.location             = 'Lütfen bir lokasyon seçiniz';
    if(!data.active)              errors.active               = 'Lütfen bir aktiflik durumu seçiniz';
    if(!data.roomreservationtime) errors.roomreservationtime  = 'Lütfen bir rezervasyon zamanı seçiniz';
    if(!data.restime)             errors.restime              = 'Lütfen bir rezervasyon zamanı seçiniz';


    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  

  
  const getRooms = () => {
    api
      .get(
        '/rest/room?' +
          qs.stringify({ page: 1, pageSize: 10000, sort: 'name' }),
      )
      .then(({ data: { result, result_message } }) => {
        setRooms(
          result.rows.map((i) => ({
            value: i._id,
            label: i.name,
          })),
        );
      });
  };

  const getUsers = () => {
    api
      .get(
        '/rest/user?' +
          qs.stringify({ page: 1, pageSize: 10000, sort: 'name' }),
      )
      .then(({ data: { result, result_message } }) => {
        setUsers(
          result.rows.map((i) => ({
            value: i._id,
            label: i.name,
          })),
        );
      });
  };

  


  const save = () => {
    // TODO : rezervasyon eklenirken rezervasyonSlot kısmına da eklenecek.
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api.put('/rest/roomReservation/' + id, data)
        .then(({ data: { result, result_message } }) => { 
          if (result_message.type === 'success') {
            message.success('Rezervasyon başarıyla güncellendi', 3);
            history.push('/room-reservation');
          } else {
            message.error('Rezervasyon güncellenemedi', 3);
          }
        });
      } else {
        api.post('/rest/roomReservation', data)
        .then(({ data: { result, result_message } }) => { 
          if (result_message.type === 'success') {
            message.success('Rezervasyon başarıyla eklendi', 3);
            history.push('/room-reservation');
          } else {
            message.error('Rezervasyon eklenemedi', 3);
          }
        });
      }
    }
          
  };

 




  const setSlot = (slot) => {
    if (slot.expired) {
      return;
    }
    setSlots(
      slots.map((i) => {
        if (i.start === slot.start) {
          return {
            ...i,
            selected: !slot.selected,
          };
        }
        return i;
      }),
    );
  };

  const onChange = (value, dateString) => {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
    if(data && data.roomId) {
      api.post('/rest/roomReservation/index' ,{date: dateString, id: data.roomId})
      .then((response) => {
        if(response.data.result) {
          setData({
            ...data,
            activeSlots: response.data.result,
            date: dateString,
          })
        }
      })
    }
  };

  const activeSlotsTime = () => {
    let myTimes = []
    let defaultDisabled = ["00","01","02","03","04","05","06","07","08","20","21","22","23","24"]
    if(data && data.activeSlots) {
      data.activeSlots.filter((item) => {
        if(item.active === false) {
          myTimes.push(item.start.split(':')[0])
        }
      })
    }
    myTimes = myTimes.concat(defaultDisabled)
    let myTimesInt = myTimes.map((item) => parseInt(item))
    return myTimesInt
  }

  const disabledTime = (current) => {
    if (current) {
      let myTimes = activeSlotsTime()
      return {
        disabledHours: () => myTimes,
      };
    }
  }


  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name: ""}</h1>
        </div>
        <div className="list-buttons">
          <div className="list-buttons py-5">
            <Link to="/room-reservation">
              <Button 
                type='danger'
                icon={<LeftOutlined />} 
                style={{
                  fontSize: '14px',
                  height: '100%',
                  marginRight: '10px',
                }}
                >
                  Geri
                </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={ id ? "Rezervasyon Düzenle" : "Rezervasyon Ekle"} loading={loading}>
          <Form layout="horizontal" size="large" onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Konum"
                  required
                  help={errors.location}
                  validateStatus={errors.location ? 'error' : 'success'}
                  >
                    <RoomLocationPicker
                      setRecord={setData}
                      record={data}
                      name="roomlocation"
                      // value={data.location}
                      onChange={(e) => setData({ ...data, location: e })}
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Oda"
                  required
                  help={errors.roomId}
                  validateStatus={errors.roomId ? 'error' : 'success'}
                >

                  
      
                  <RoomPicker
                    name="roomId"
                    record={data}
                    setRecord={setData}
                    onChange={(e) => setData({ ...data, roomId: e })}
                    placeholder="Oda Seçiniz"
                    />
                    <Alert message="Seçtiğiniz konuma göre odalar fitrelenecektir." type="info" showIcon />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Kullanıcı"
                  required
                  help={errors.userId}
                  validateStatus={errors.userId ? 'error' : 'success'}
                >
                  <AttendeePicker2
                    name="userId"
                    record={data}
                    setRecord={setData}
                    onChange={(e) => setData({ ...data, userId: e })}
                    />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Oda Ücreti"
                  required
                  help={errors.totalprice}
                  validateStatus={errors.totalprice ? 'error' : 'success'}
                >
                  <Input
                    name="totalprice"
                    value={data.price ? data.price : data.totalprice}
                    // TODO : varsayılan olarak oda fiyatı 400₺ olacak.
                    disabled={true}
                    readOnly={true}
                    onChange={(e) => setData({ ...data, totalprice: e.target.value })}
                    placeholder="Oda Ücreti"
                    type="number"
                    />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Ödeme Durumu"
                  required
                  help={errors.pay}
                  validateStatus={errors.pay ? 'error' : 'success'}
                >
                  <Select
                    name="payStatus"
                    value={data.payStatus}
                    onChange={(e) => setData({ ...data, payStatus: e })}
                    placeholder="Ödeme Durumu"
                    >
                      <Option value={paymentStatus[0]}>Ödendi</Option>
                      <Option value={paymentStatus[1]}>Ödenmedi</Option>
                      <Option value={paymentStatus[2]}>Rezervasyon Sonrası Ödeme</Option>
                      <Option value={paymentStatus[3]}>Nakit Ödeme</Option>
                      <Option value={paymentStatus[4]}>Pos Ödemesi</Option>
                  </Select>
                </Form.Item>
              </Col>
              
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Aktiflik Durumu"
                  required
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    name="active"
                    checked={data.active}
                    disabled={data.pay ? true : false}
                    checkedChildren="Aktif"
                    unCheckedChildren="Pasif"
                    onChange={(e) => setData({ ...data, active: e })}
                    />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Tarih"
                  required
                  help={errors.date}
                  validateStatus={errors.date ? 'error' : 'success'}
                >
                  <DatePicker
                    name="date"
                    value={data.date ? moment(data.date,"DD-MM-YYYY") : null}
                    onChange={onChange}
                    placeholder={"Tarih Seçiniz"}
                    allowClear={true}
                    format="DD-MM-YYYY"
                    locale={locale}
                  />
                </Form.Item>
              </Col>
              {
                data.activeSlots && data.activeSlots.length > 0 && (
                <>
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                      label="Müsait Zamanlar"
                      // required
                      help={errors.roomreservationtime}
                      validateStatus={errors.roomreservationtime ? 'error' : 'success'}
                    >
                        <RoomReservationTime
                          setRecord={setData}
                          record={data}
                          name="roomreservationtime"
                          // value={data.location}
                          onChange={(e) => setData({ ...data, time: e })}
                        />
                        <Alert message="Müsait Zamanlara Göre Aşağıdan Seçim Yapınız." type="info" />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                      label="Rezervasyon Zamanı"
                      required
                      help={errors.restime}
                      validateStatus={errors.restime ? 'error' : 'success'}
                    >
                      <TimePicker.RangePicker
                        locale={locale}
                        name="restime"
                        value={"restime" in data ? [moment(data.restime[0]),moment(data.restime[1])] : null}
                        onChange={(e) => setData({ ...data, restime: e })}
                        allowClear={true}
                        format="HH:mm"
                        minuteStep={60}
                        disabledTime={disabledTime}
                        showNow={false}
                        size="large"
                        placeholder={["Başlangıç Saati","Bitiş Saati"]}

                        />
                    </Form.Item>
                  </Col>
                </>
                )
              }
              
              

            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{
                      backgroundColor: '#9eb23b',
                      borderColor: 'transparent',
                    }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {"Kaydet"}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          
        </Card>
      </div>
    </div>
  );
}


export default RoomReservationDetail;
