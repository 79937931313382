// create seat picker component

import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../../service/index'
import qs from 'qs';
import {returnTimesByDay} from '../../stores/utils'




export default(props) => {
  let { record, setRecord, name } = props;
  let [room, setRoom] = useState([]);
  let _params = { query: {deleted: { $ne: true }}, sort: "-createdAt", page: 1, pageSize: 100000 };
  let languagesCms = props.languagesCms;
  useEffect(() => {
    setRoom(returnTimesByDay(6))
  }, [])

  let selectedRooms = (v) => {
    let selectedRoom = room.find(e => e._id === v)
    setRecord({ ...record, [name]: selectedRoom})
  }
  return <Select
  defaultValue={record[name] ? record[name].name : null}
  placeholder={"Oda Seçiniz"}
  showSearch={true}
  onChange={selectedRooms}
    filterOption={(input, option) =>
        option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
    }>

    {(room) && room.map(b =>
      <Select.Option key={b.start} value={b.start + " " + b.end} >{b.start} {b.end}</Select.Option>)}
</Select>

}