import React, { useState, useEffect } from 'react';
import { Row, Col, Space, Progress, Card, Statistic } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import api from '../service/index';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const get = async (page, filters, sorter) => {
    await api.get('/rest/dashboard').then(({ data }) => {
      if (data && data.result) {
        setData(data.result);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <div style={{ marginTop: '4.1rem', marginLeft: '1.5rem' }}>
      <div className="table-wrap">
        <Row direction="row" style={{fontFamily: "Ariel", fontWeight: "700"}}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
              }}
            >
              <h5 style={{ fontWeight: '500' }}>
                En Çok Kullanılan Şarj Noktası
              </h5>
              <Space
                wrap
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                <Progress type="circle" percent={77} />
              </Space>
              <p
                style={{
                  textAlign: 'center',
                  marginTop: '11px',
                  fontWeight: '700',
                  color: '#3f8600',
                  fontSize: '1rem',
                }}
              >
                GreenMotion
              </p>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
              }}
            >
              <h5 style={{ fontWeight: '500' }}>Aktif Çalışan Cihaz Sayısı</h5>
              <Space
                wrap
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                <Statistic
                  value={data && data.activeDevice}
                  valueStyle={{
                    color: '#3f8600',
                    fontWeight: 'medium',
                    fontSize: '4.5rem',
                  }}
                  prefix={<ArrowUpOutlined />}
                />
              </Space>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
              }}
            >
              <h5 style={{ fontWeight: '500' }}>Aktif Şarj</h5>
              <Space
                wrap
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                <Progress
                  percent={43}
                  steps={11}
                  size="large"
                  strokeColor={['red', 'red', 'red']}
                />
              </Space>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
              }}
            >
              <h5 style={{ fontWeight: '500' }}>Toplam Kullanıcı Sayısı</h5>
              <Space
                wrap
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                <Statistic
                  value={data && data.user && data.user.totalUser}
                  valueStyle={{
                    color: '#3f8600',
                    fontWeight: 'medium',
                    fontSize: '4.5rem',
                  }}
                  prefix={<ArrowUpOutlined />}
                />
              </Space>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
              }}
            >
              <h5 style={{ fontWeight: '500' }}>Rezervasyon Yapma Oranı</h5>
              <Space
                wrap
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                <Progress
                  percent={data && data.reservationRate}
                  status="active"
                  strokeColor={{
                    from: '#108ee9',
                    to: '#87d068',
                  }}
                  type="circle"
                />
              </Space>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
