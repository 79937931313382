import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth: null,
};

const authorizations = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
  },
});

export const { setAuth } = authorizations.actions;
export default authorizations.reducer;
