import React, { useState, useEffect } from 'react';
import { Select } from 'antd';


export default (props) => {
    let { record, setRecord, name } = props;
    const tags = [
        {
            "id": 1,
            "name": "Finansal",
        },
        {
            "id": 2,
            "name": "Teknoloji",
        },
        {
            "id": 3,
            "name": "Sağlık",
        },
        {
            "id": 4,
            "name": "Çocuklar",
        },
        {
            "id": 5,
            "name": "Yaşam",
        }
    ];

    // 10ms delay to prevent the error: "Can't perform a React state update on an unmounted component"

    console.log("record[name]", record[name])
 

    let featureChange = (v) => {
        let feature = tags.find((value) => {
            return value.id === v
        })
        setRecord({ ...record, [name]: feature.name });
    }


    return <Select
        defaultValue={record && record[name] ? record[name] : []}
        placeholder={"Lütfen Etkinlik Tipi Seçiniz"}
        onChange={featureChange}>
        {(tags) && tags.map(b =>
            <Select.Option key={b.id} value={b.id}>{b.name}</Select.Option>)}
    </Select>
}