import {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  Loading3QuartersOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Input, Popconfirm, Space, Table } from 'antd';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { useEffect, useGlobal, useState } from 'reactn';
import api from '../service/index';
import { getPathName } from '../stores/utils';
import lang from "../lang"

const Companies = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [currentPage, setCurrentPage] = useState(1);
  let [pageSize, setPageSize] = useState(10);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [user] = useGlobal('user');
  const {auth} = useSelector(state => state.auth)
  // let path = props.location.pathname.split('/')[1];
  // let pathName = getPathName(path);

  let query = [];


  let handleTableChange = async (page, filters, sorter) => {
    setCurrentPage(page.current);
    setPageSize(page.pageSize);
    // get(page, filters, sorter);
  };
  let exportToCSV = () => {
    let first = data[0];
    let headers = ""
    let csv = ""
    if(first) {
      let keys = Object.keys(first);
      headers = keys.join(";");
      let items = data.map(item => {
        return keys.map(key => item[key]).join(";")
      }).join("\n")

      csv = headers + "\n" + items
    }

    // let first = data[0];
    // let headers = "Oda Adı;Oda Özelliği"
    // let csv = ""
    // if(first) {
    //   console.log(first)
    //   let items = data.map(item => {
    //     return `${item.nameSurname};${item.feature}`
    //   }).join("\n")

    //   csv = headers + "\n" + items
    // }
  
    var element = document.createElement("a");
    element.setAttribute("href", `data:text/plain;chartset=utf-8,${csv}`);
    element.setAttribute("download", "companies.csv");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        // alfabetik sıralama
        if (sorter.field === 'name') {
          if (sorter.order === 'ascend') {
            shortString = 'name';
          } else {
            shortString = '-name';
          }
        }
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name, email, phone';
    }
    
    
    let restData = await api
      .get(`/rest/companies?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          console.log(data.result.rows)
          setLoading(false);
          setTotalCount(data.result.total);
          // setCurrentPage(data.result.page);
          return data.result.rows
          // return data.result.rows.map((item, key) => {
          //   if (item.active)
          //     item.active = <div className="true-circle"></div>;
          //   else item.active = <div className="false-circle"></div>;

          //   item.key = key;
          //   return item;
          // });
        }
      });
    // restdata active true olanları setle
    // restData = restData.filter((item) => item.isActive === true);
    setData(restData);
  };

  // let getAllUsers = async () => {
  //   let restData = await api
  //     .post(`/rest/user/getAllUsers`, {})
  //     .then(({ data }) => {
  //       if (data.result) {
  //         return data.result.map((item, key) => {
  //           item.key = key;
  //           return item;
  //         });
  //       }
  //     });
  //   setData(restData);
  // };

  // sayfa ilk yüklendiğinde çalıştır
  // useEffect(() => {
  //   if(data.length === 0){
  //     getAllUsers();
  //   }
  // }, []);


  useEffect(() => {
      setLoading(true);
      get();
  }, []);

  // useEffect(() => {
  //   get();
  // }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (record) => {
    // api.delete(`/rest/user/${item_id}`, ({ data }) => {});
    // if(user.role !== "personel" && user.role !== "admin"){
    //   message.error("Bu işlemi yapmaya yetkiniz yok");
    //   return;
    // }
    api.delete("/rest/companies/" + record._id).then(({ data }) => {});
    let newData = data.filter((el) => el._id !== record._id);
    setData(newData);
  };

  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/companies/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    api.post(`/rest/companies/companiesSearch`, { query: e.target.value }).then(({ data }) => {
      if (data.result) {
        setData(data.result);
      }
    });
  };

  function turkishSort(a, b) {
    var alfabe =
      'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789';
    if (a.length === 0 || b.length === 0) {
      return a.length - b.length;
    }
    for (var i = 0; i < a.length && i < b.length; i++) {
      var ai = alfabe.indexOf(a[i]);
      var bi = alfabe.indexOf(b[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }

  let columns = [
    {
      title: lang.companyname,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => turkishSort(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
      render (name) {
        // baş harfleri büyük yap
        let nameArray = name.split(" ");
        let newName = "";
        nameArray.forEach((el) => {
          newName += el.charAt(0).toUpperCase() + el.slice(1) + " ";
        });
        return <div>{newName}</div>
      }
    },
    {
      title: lang.companyKey,
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.email - b.email,
      sortDirections: ['descend', 'ascend'],
      render (id) {
        return id ? id : "-"
      }
    },
    {
      title: lang.activity,
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: (a, b) => a.phone - b.phone,
      sortDirections: ['descend', 'ascend'],
      render (text, record) {
        if(text){
          return <div className="true-circle"></div>
        } else {
          return <div className="false-circle"></div>
        }
      }
    },
    {
      title: lang.roomlimit,
      dataIndex: 'roomLimit',
      key: 'roomLimit',
      sorter: (a, b) => a.active - b.active,
      sortDirections: ['descend', 'ascend'], 
      render (roomLimit) {
        return roomLimit ? roomLimit : "-"
      }
    },
    {
      title: lang.companyLogo,
      dataIndex: 'image',
      key: 'image',
      sorter: (a, b) => a.active - b.active,
      sortDirections: ['descend', 'ascend'],
      render (image) {
        return image ? <img src={image.url} alt="" style={{ width: '80px', height: '60px', objectFit: "contain"}} /> : "-"
      }
    },
    {
      title: lang.transaction,
      key: 'action',
      align: 'center',
      width: 150,

      render: (text, record) => {
        return <Space size="small">
          { 
            !(auth && auth.find(a => a.number === user.role).restraintList.includes( "edit" )) && (
            <Link to={'/companies/edit/' + record._id}> 
              <Button 
                icon={<EditOutlined />}>
                  {lang.edit}
              </Button>
            </Link>
          )
          }
          {
            
            !(auth && auth.find(a => a.number === user.role).restraintList.includes( "delete" )) && (
              <Popconfirm
                onConfirm={() => deleteRow(record)}
                title={lang.delete}
                okText={lang.delete}
                cancelText={lang.cancel}
              >
              <Button 
                type="danger" icon={<DeleteOutlined />}>
                {
                lang.delete
                }
              </Button>
            </Popconfirm>
            )
          }
          
          
        </Space>
    },
    },
  ];


  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {lang.companies}
          </h1>
        </div>
        {
          user.role !== "personel" && (
          <div className="list-buttons py-5">
                 <Button
                    type="danger"
                    icon={<ExportOutlined />}
                    size="large"
                    onClick={exportToCSV}
                    style={{ fontSize: '14px', height: '100%', marginRight: '10px' }}
                  >
                    {
                    lang.exportout
                    }
                  </Button>
                  
            {(!(auth && auth.find(a => a.number === user.role).restraintList.includes( "delete" )) && !isSmall) && 
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    size="large"
                    onClick={deleteSelectedRows}
                    style={{ fontSize: '14px', height: '100%', marginRight: '10px' }}
                  >
                    {
                    lang.deleteselected
                    }
                  </Button>
            }
          
            {!(auth && auth.find(a => a.number === user.role).restraintList.includes( "add" )) && (
              <Link to="/companies/add" style={{ marginRight: '5px' }}>
                <Button
                  type="light"
                  style={{ fontSize: '14px', height: '100%' }}
                  icon={<PlusOutlined />}
                  size="large"
                >
                  {
                  lang.addcompany
                  }
                </Button>
              </Link>
            )}
        </div>
          )
        }
        
      </div>

      <div className="table-wrap">
      <Search
        placeholder={lang.allsearch}
        onChange={onChange}
        onSearch={(v) => {
          setSearch(v);
          get();
        }}
      />
      <Table
        dataSource={data}
        columns={columns}
        loading={{
          spinning: loading,
          indicator: <Loading3QuartersOutlined spin />,
          size: 'large',
        }}
        onChange={handleTableChange}
        pagination={{
          total: totalCount,
          current: currentPage,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
        rowSelection={{ ...rowSelection }}
        rowKey="_id"
      />
      </div>
    </div>
  );
};

export default Companies;
