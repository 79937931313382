import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm, message, Badge } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import Spinner from '../components/Loading';
import { turkishSort } from '../stores/utils';
import dayjs from 'dayjs';
import { getPathName } from '../stores/utils';
import lang from "../lang"


const Admins = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [excelData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(true);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [companies, setCompanies] = useState([]);

  let [modules] = useGlobal('modules');
  let [room] = useGlobal('room');
  const { auth } = useSelector((state) => state.auth);
  let path = props.location.pathname.split('/')[1];
	let pathName = getPathName(path);

  let query = [];

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };
  let exportToCSV = () => {
    let first = data[0];
    let headers = ""
    let csv = ""
    if(first) {
      let keys = Object.keys(first);
      headers = keys.join(";");
      let items = data.map(item => {
        return keys.map(key => item[key]).join(";")
      }).join("\n")

      csv = headers + "\n" + items
    }

    // let first = data[0];
    // let headers = "Oda Adı;Oda Özelliği"
    // let csv = ""
    // if(first) {
    //   console.log(first)
    //   let items = data.map(item => {
    //     return `${item.nameSurname};${item.feature}`
    //   }).join("\n")

    //   csv = headers + "\n" + items
    // }
  
    var element = document.createElement("a");
    element.setAttribute("href", `data:text/plain;chartset=utf-8,${csv}`);
    element.setAttribute("download", "rooms.csv");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 1000 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name, email, phone';
    }

    let restData = await api
      .get(`/rest/admin?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        console.log(data, 'data');
        if (data.result) {
          setTotalCount(data.result.total);
          setData(data.result.rows)
        }
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);
  
  useEffect(() => {
    api.get('/rest/companies')
    .then(({ data }) => {
      if (data.result) {
        console.log(data.result.rows)        
        var companies = data.result.rows
        setCompanies(companies)
      }
    });
  }, [])

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    // eğer bir odaya ait bir kayıt varsa silinemez
    let query = {
      roomId: item_id,
    }
    let admin = await api.get(`/rest/admin?${qs.stringify(query)}`, { query });
    if (admin) {
      message.success('Yönetici silindi.');
      await api.delete(`/rest/admin/${item_id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item_id);
      setData(newData);
      get();
      return;
    }
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title:  lang.nameSurname,
      dataIndex: 'nameSurname',
      key: 'nameSurname',
      sorter: (a, b) => turkishSort(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
      render (nameSurname) {
        return nameSurname ? nameSurname : '-'
      }
    },
    {
      title: lang.companyname,
      dataIndex: "company",
      key: "company",
      render (id) {
        let company = companies.find(company => company._id == id);
        if (company)
          return company.name
        else return "-"
      }
    },
    {
      title: lang.phoneNumber,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render (phoneNumber) {
        return phoneNumber ? phoneNumber : "-"
      }
    },
    {
      title: lang.activity,
      dataIndex: "isActive",
      key: "isActive",
      render (isActive) {
        return isActive === true ? 
        <Badge 
          status="success" 
          count={lang.active} 
          className="site-badge-count-109" 
          style={{backgroundColor: '#52c41a',}}
        /> : 
        <Badge 
          status="error" 
          count={lang.passive}
          className="site-badge-count-109" 
          style={{backgroundColor: '#7d5fff',}}
        />
      }
    },
    // {
    //   title: "Oda Özelliği",
    //   dataIndex: "feature",
    //   key: "feature",
    //   render (feature) {
    //     return feature === "conference" ? "Konferans" : "-" || feature === "drink" ? "Meşrubat" : "-" || feature === "gift" ? "Hediye" : "-"
    //   }
    // },
    // {
    //   title: "Limit",
    //   dataIndex: "limit",
    //   key: "limit",
    // },
    // {
    //   title: "Açıklama",
    //   dataIndex: "description",
    //   key: "description",
    //   render (description) {
    //     return description ? description : "-"
    //   }
    // },
    // {
    //   title: "Giriş Sayısı",
    //   dataIndex: "joinCount",
    //   key: "joinCount",
    //   render (joinCount) {
    //     return joinCount ? joinCount : "-"
    //   }
    // },
    // {
    //   title: "Giriş Tarihi",
    //   dataIndex: "joinDate",
    //   key: "joinDate",
    //   render (joinDate) {
    //     return joinDate ? dayjs(joinDate).format('DD.MM.YYYY') : "-"
    //   }
    // },
    // {
    //   title: "Çıkış Tarihi",
    //   dataIndex: "lastjoinDate",
    //   key: "lastjoinDate",
    //   render (lastjoinDate) {
    //     return lastjoinDate ? dayjs(lastjoinDate).format('DD.MM.YYYY') : "-"
    //   }
    // },
    // {
    //   title: "Aktiflik",
    //   dataIndex: "status",
    //   key: "status",
    //   render (status) {
    //     return status === true ? 
    //     <Badge 
    //       status="success" 
    //       count="Aktif" 
    //       className="site-badge-count-109" 
    //       style={{backgroundColor: '#52c41a',}}
    //     /> 
    //     : 
    //     <Badge 
    //       status="error" 
    //       count="Pasif" 
    //       className="site-badge-count-109" 
    //       style={{backgroundColor: '#7d5fff',}}
    //     />
    //   }
    // },
    {
      title: lang.transaction,
      key: 'action',
      align: 'center',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          {!(
            auth &&
            auth
              .find((a) => a.number === room.role)
              .restraintList.includes('edit')
          ) && (
            <Link to={'/admin/edit/' + record._id}>
              <Button icon={<EditOutlined />}>{lang.edit}</Button>
            </Link>
          )}
          {!(
            auth &&
            auth
              .find((a) => a.number === room.role)
              .restraintList.includes('delete')
          ) && (
            <Popconfirm
              onConfirm={() => deleteRow(record._id)}
              title={lang.delete}
              okText={lang.delete}
              cancelText={lang.cancel}
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                {lang.delete}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      {loading && <Spinner />}
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {
              lang.managers
            }
          </h1>
        </div>
        <div className="list-buttons py-5">
        <Button
                type="danger"
                icon={<ExportOutlined />}
                size="large"
                onClick={exportToCSV}
                style={{
                  fontSize: '14px',
                  height: '100%',
                  marginRight: '10px',
                }}
              >
                 {
                  lang.exportout
                 }
        </Button>

          {!(
            auth &&
            auth
              .find((a) => a.number === room.role)
              .restraintList.includes('add')
          ) && (
            <Link to="/admin/add" style={{ marginRight: '5px' }}>
              <Button
                type="light"
                style={{ fontSize: '14px', height: '100%' }}
                icon={<PlusOutlined />}
                size="large"
              >
                {lang.addadmin}
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder={lang.search}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
          rowKey="_id"
        />
      </div>
    </div>
  );
};

export default Admins;
