import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
;

export default (props) => {
    let { record, setRecord, name } = props;

    const roomType = [
        {
            "_id":"rahatoda",
            "name":"Rahat Oda"
        },
        {
            "_id":"formaloda",
            "name":"Formal Oda"
        }
    ];

    let featureChange = (v) => {
        let feature = roomType.find((value) => {
            return { _id: value._id, name: value.name}
        })
        setRecord({ ...record, [name]: feature._id });
    }


    return <Select
        defaultValue={record[name] ? record[name] : null}
        placeholder={"Lütfen Oda Tipini Seçiniz"}
        onChange={featureChange}>
        {(roomType) && roomType.map(b =>
            <Select.Option key={b._id} value={b._id}>{b.name}</Select.Option>)}
    </Select>
}