var browserLang = navigator.language || navigator.userLanguage; 
browserLang = browserLang.split("-")[0];

if(!(browserLang == "tr" || browserLang == "en"))
    browserLang = "en"
    
export let ln = localStorage.getItem("ln") || browserLang;

let tr = {
    "users": "Kullanıcılar",
    "companies": "Şirketler",
    "rooms": "Salonlar",
    "managers": "Yöneticiler",
    "logout": "Çıkış Yap",
    "exportout": "Dışa Aktar",
    "deleteselected": "Seçilenleri Sil",
    "addcompany": " Şirket Ekle",
    "companyname": " Şirket Adı",
    "companyKey": " Şirket ID",
    "activity": " Aktiflik",
    "roomlimit": " Oda Limiti",
    "companyLogo": " Şirket Logo",
    "transaction": "İşlemler",
    "edit": "Düzenle",
    "delete": "Sil",
    "cancel": "İptal",
    "addroom": " Salon Ekle",
    "adduser": "Kullanıcı Ekle",
    "addadmin": "Yönetici Ekle",
    "back": "Geri Dön",
    "roomname": "Salon Adı",
    "limit": "Limit",
    "status": "Durum",
    "save": "Kaydet",
    "name": "Ad",
    "surname": "Soyad",
    "phone": "Telefon",
    "search":"Arama",
    "nameSurname":"Adı Soyadı",
    "allsearch":"Ad , Soyad , Mail,Telefon",
    "authorizationgroup":"Odalar",
    "chooseroom":"Oda Seçiniz",
    "qrdownload":"QR İndir",
    "password":"Şifre",
    "nodata" :"Veri Yok",
    "addadmin" :"Yönetici Ekle",
    "loginError": "Kullanıcı adı veya sifre yanlış",
    "roomFeature": "Oda Özelliği",
    "statement" :"Açıklama",
    "numberOfEntries":"Giriş Sayısı",
    "EntrieDate":"Giriş Tarihi",
    "OutDate":"Çıkış Tarihi",
    "phoneNumber":"Telefon Numarası",
    "active": "Aktif",
    "createdAt": "Olusturma Tarihi",
    "count": "Sayim",
    "room": "Oda",
    "qrCreate":"Qr kod Oluştur",
    "qrError":"Qr Bulunmuyor",
    "passive": "Pasif",
    "Report":"Raporlama",
    "qrSave":"Qr Kaydedildi",
    "All":"Tümü",
    "countPerson":"Davetli Sayısı"





}
let en = {
    "users": "Attendees",
    "companies": "Companies",
    "rooms": "Rooms",
    "managers": "Admins",
    "logout": "Logout",
    "exportout": "Export Out",
    "deleteselected": "Delete",
    "addcompany": " Add Company",
    "companyname": " Company Name",
    "companyKey": " Company Name",
    "activity": "Is Active",
    "roomlimit": " Room Limit",
    "companyLogo": " Company Logo",
    "transaction": "Transaction",
    "edit": "Edit",
    "delete": "Delete",
    "cancel": "Cancel",
    "addroom": " Add Room",
    "adduser": "Add User",
    "addadmin": "Add Admin",
    "back": "Back",
    "roomname": "Room Name",
    "limit": "Limit",
    "status": "Status",
    "save": "Save",
    "name": "Name",
    "surname": "Surname",
    "phone": "Phone",
    "search":"Search",
    "allsearch":"Name , Surname , Mail, Phone",
    "nameSurname":"Full name",
    "authorizationgroup":"Rooms",
    "chooseroom":"Choose Room",
    "qrdownload":"QR Download",
    "password":"Password",
    "nodata" :"No Data",
    "addadmin" :"Add Admin",
    "loginError": "Invalid credentials",
    "roomFeature": "Room Feature",
    "statement" :"Statement",
    "numberOfEntries":"Number Of Entries",
    "EntrieDate":"Entrie Date",
    "OutDate":"Out Date",
    "phoneNumber":"Phone Number",
    "active": "Active",
    "createdAt": "Created At",
    "peopleCount": "People Count",
    "room": "Room",
    "qrCreate":"Generate QR code",
    "qrError":"No Qr",
    "passive": "Passive",
    "Report":"Report",
    "qrSave":"Qr Save",
    "All":"All",
    "countPerson":"Update Guests"
    





    
}

let selected = ln == "tr" ? tr : en;

export const set_lang = (_ln) => {
    localStorage.setItem("ln", _ln);
    window.location.reload()
}

export default selected;
