import { LeftOutlined } from '@ant-design/icons';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message
} from 'antd';
import 'crypto';
import { useCallback } from 'react';
import 'react-phone-input-2/lib/style.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useGlobal, useState } from 'reactn';
import { FileInputSingle } from '../components';
import api from '../service/index';
import lang from "../lang"
const { Option } = Select;

const CompaniesDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;

  const [authorizations] = useGlobal('authorizations');
  const [user] = useGlobal('user');

  let newRecord = {
    name: '',
    id: '',
    isActive: true,
    image: '',
    roomLimit: ''

  };
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');

  //onetime run
  useEffect(() => {
    if (id) {
      setLoading(true);
      api.get('/rest/companies/' + id).then(({ data: { result, result_message } }) => {
          delete result.password;
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};


    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/companies/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Düzenle", 2);
            } else message.error(result_message.message, 2);
          });
      } else {
        data.image = data.image.url
        api
          .post('/rest/companies', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Kaydet", 2);
              history.push('/companies');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };


  const createPassword = () => {
    // create random password
    let password = Math.random().toString(36).slice(-8);
    return password;
  }

  console.log(data, "data")


  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h3 style={{ color: '#fff', marginBottom: '8px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a
                  style={{ color: '#fff', fontSize: '18px' }}
                  href="/companies"
                >
                  {
                  lang.users
                  }
                </a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </h3>
        </div>

        <div className="list-buttons">
          <Link to="/companies">
            <Button
              type="light"
              icon={<LeftOutlined />}
              size={'large'}
              style={{ fontSize: '14px', height: '100%' }}
            >
              {lang.back}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? "Düzenle" : "Ekle"}
          loading={loading}
        >
          <Form
            disabled={
              authorizations &&
              authorizations
                .find((a) => a.number === user.role)
                .restraintList.includes('edit')
            }
            layout="horizontal"
            size={'large'}
            onFinish={save}
          >
             <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={lang.companyname}
                  help={errors.name}
                  required
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                 <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    placeholder={lang.companyname}
                  />
                </Form.Item>
              </Col>
              {/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label= {lang.companyID}
                  help={errors.id}
                  required
                  validateStatus={errors.id ? 'error' : 'success'}
                >
                 <Input
                    name="id"
                    value={data.id}
                    onChange={(e) => setData({ ...data, id: e.target.value })}
                    placeholder={lang.companyID}
                  />
                </Form.Item>
              </Col> */}
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={lang.companyLogo}
                  help={errors.image}
                  required
                  validateStatus={errors.image ? 'error' : 'success'}
                >
                  
                  <FileInputSingle
                  languagesCms={"Yükle"}
                    name="image"
                    record={data}
                    setRecord={setData}
                    placeholder={lang.companyLogo}
                  />
                  
                </Form.Item>
              </Col>
            </Row>
            

             <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={lang.activity}
                  help={errors.isActive}
                  required
                  validateStatus={errors.isActive ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.isActive}
                    onChange={(e) => setData({ ...data, isActive: e })}
                    checkedChildren="Aktif"
                    unCheckedChildren="Pasif"
                  />
                </Form.Item>
              </Col>

            </Row>
            

            <Row direction="row">
              {/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={lang.roomlimit}
                  help={errors.roomLimit}
                  required
                  validateStatus={errors.roomLimit ? 'error' : 'success'}
                >
                  <Input
                    name="roomLimit"
                    value={data.roomLimit}
                    onChange={(e) => setData({ ...data, roomLimit: parseInt(e.target.value) })}
                    placeholder={lang.roomlimit}
                    type='number'
                  />
                </Form.Item>
              </Col> */}
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{
                      backgroundColor: '#9eb23b',
                      borderColor: 'transparent',
                    }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {lang.save}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default CompaniesDetail;
