import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    alert: null,
};

const alerts = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (state, action) => {
      state.alert = action.payload;
    },
  },
});

export const { setAlert } = alerts.actions;
export default alerts.reducer;
