import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name, mode, disabled } = props;
  let [station, setStation] = useState([]);
  let _params = { sort: '-createdAt', page: 1, pageSize: 100000, fields: ["_id", "name"]};

  useEffect(() => {
    api
      .get(`/rest/dealer?${qs.stringify(_params)}`)
      .then(async ({ data }) => {
        if ( data && data.result ) {
          const dealers = data && data.result && data.result.rows && data.result.rows.sort((a, b) => {
            return a.dealers.localeCompare(b.name);
          });
          setStation(dealers);
        }
      });
  }, [record]);

  return (
    <Select
      value={record[name] || []}
      showSearch={true}
      mode={mode}
      placeholder={"Bayi Seçiniz"}
      onChange={(v) => setRecord({ ...record, [name]: v })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled || false}
    >
      {station &&
        station.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
