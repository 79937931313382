import {
    Button,
    Card,
    Collapse,
    DatePicker,
    message,
    Switch,
    Select,
    Form,
    Col,
    Row,
    Input,
    ConfigProvider,
    Alert
  } from 'antd';
  import moment from 'moment';
  import React, { useEffect, useState } from 'react';
  import { Link, useHistory, useParams } from 'react-router-dom';
  import api from '../service';
  import { LeftOutlined } from '@ant-design/icons';
  import qs from 'qs';
  import { AttendeePicker2, SeatPickers,EventPicker, EventLocationPicker} from '../components';
  import { RoomPicker } from '../components';
  import { useCallback } from 'react';
  import 'moment/locale/tr';
  import locale from 'antd/es/date-picker/locale/tr_TR';
  moment.locale('tr');
  const { RangePicker } = DatePicker;
  const { Option } = Select;


  // ödeme durumları için enum oluştur
  const paymentStatus = {
    0: 'Ödendi',
    1: 'Ödenmedi',
    2: 'Rezervasyon Sonrası Ödeme',
    3: 'Nakit Ödeme',
    4: 'Pos Ödeme',
  };
  
  function EventReservationDetail() {
  
    let newRecord = {
      userId:           '',
      eventId:          '',
      addressId:        null,
      count:            1,
      pay:              null,
      totalprice:       null,
      read:             false,
      
    }
  
  
  
  
    const params = useParams();
    const history = useHistory();
    const id = params.id !== 'add' ? params.id : false;
    let [data, setData] = useState(id ? [] : newRecord);
    let [errors, setErrors] = useState([]);
    let [validationCheck, setValidationCheck] = useState(false);
    const [loading, setLoading] = useState(id ? true : false);
    const [users, setUsers] = useState([]);
    const [rooms, setRooms] = useState([]);
  
  
  
    useEffect(() => {
      getUsers();
      getRooms();
    }, []);
  
  
  
    useEffect(() => {
      if (id) {
        setLoading(true);
        api.get('/rest/eventReservation/' + id).then(({ data: { result, result_message } }) => {
          if (result) {
            setData(result);
            setLoading(false);
          }
            // setData(result);
            // setLoading(false);
          });
      }
    }, [id]);
  
    let validate = useCallback(() => {
      let errors = {};

      if (!data.eventlocation)  errors.eventlocation = 'Lütfen bir etkinlik seçiniz';
      if (!data.eventId) errors.eventId = 'Lütfen bir etkinlik seçiniz';
      if (!data.userId) errors.userId = 'Lütfen bir kullanıcı seçiniz';
      if (!data.pay) errors.pay = 'Lütfen bir ödeme durumu seçiniz';
      if (!data.active) errors.active = 'Lütfen bir durum seçiniz';
      if (!data.count) errors.count = 'Lütfen bir bilet sayısı seçiniz';


      errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
      errors.hasError = errors.all.length > 0;
      return errors;
    }, [data]);
  
    useEffect(() => {
      if (validationCheck) setErrors(validate());
    }, [validationCheck, data, validate]);
  
    
  

    const getRooms = () => {
      api
        .get(
          '/rest/event?' +
            qs.stringify({ page: 1, pageSize: 10000, sort: 'name' }),
        )
        .then(({ data: { result, result_message } }) => {
          setRooms(
            result.rows.map((i) => ({
              value: i._id,
              label: i.name,
            })),
          );
        });
    };
  
    const getUsers = () => {
      api
        .get(
          '/rest/user?' +
            qs.stringify({ page: 1, pageSize: 10000, sort: 'name' }),
        )
        .then(({ data: { result, result_message } }) => {
          setUsers(
            result.rows.map((i) => ({
              value: i._id,
              label: i.name,
            })),
          );
        });
    };
  

  
  
    const save = () => {
			// data.userId = data.userId._id;
      // data.roomId = data.room._id;
      
      setValidationCheck(true);
      let err = validate();
      if (err.hasError) {
        setErrors(err);
        window.scrollTo({ top: 20, behavior: 'smooth' });
      } else {
        if (id) {
          api.put('/rest/eventReservation/' + id, data)
          .then(({ data: { result, result_message } }) => { 
            if (result_message.type === 'success') {
              message.success('Rezervasyon başarıyla güncellendi', 3);
              history.push('/event-reservation');
            } else {
              message.error('Rezervasyon güncellenemedi', 3);
            }
          });
        } else {
          api.post('/rest/eventReservation', data)
          .then(({ data: { result, result_message } }) => { 
            if (result_message.type === 'success') {
              message.success('Rezervasyon başarıyla eklendi', 3);
              history.push('/event-reservation');
            } else {
              message.error('Rezervasyon eklenemedi', 3);
            }
          });
        }
      }
            
    };

  
  
    return (
      <div>
        <div className="list-head">
          <div className="list-title">
            <h1>{module ? module.name: ""}</h1>
          </div>
          <div className="list-buttons">
            <div className="list-buttons py-5">
              <Link to="/event-reservation">
                <Button 
                  type='danger'
                  icon={<LeftOutlined />} 
                  style={{
                    fontSize: '14px',
                    height: '100%',
                    marginRight: '10px',
                  }}
                  >
                    Geri Dön
                  </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="form-wrap">
          <Card title={ id ? "Rezervasyon Düzenle" : "Rezervasyon Ekle"} loading={loading}>
            <Form layout="horizontal" size="large" onFinish={save}>
              <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Konum"
                  required
                  help={errors.eventlocation}
                  validateStatus={errors.eventlocation ? 'error' : 'success'}
                  >
                    <EventLocationPicker
                      setRecord={setData}
                      record={data}
                      name="eventlocation"
                      // value={data.location}
                      onChange={(e) => setData({ ...data, location: e })}
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Etkinlik"
                  required
                  help={errors.eventId}
                  validateStatus={errors.eventId ? 'error' : 'success'}
                >
                  <EventPicker
                    name="eventId"
                    record={data}
                    setRecord={setData}
                    onChange={(e) => setData({ ...data, eventId: e })}
                    />
                    <Alert message="Seçtiğiniz konuma göre odalar fitrelenecektir." type="info" showIcon />
                </Form.Item>
              </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Kullanıcı"
                    required
                    help={errors.userId}
                    validateStatus={errors.userId ? 'error' : 'success'}
                  >
                    <AttendeePicker2
                      name="userId"
                      record={data}
                      setRecord={setData}
                      onChange={(e) => setData({ ...data, userId: e })}
                      placeholder="Kullanıcı Seçiniz"
                      />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Etkinlik Ücreti"
                    required
                    help={errors.totalprice}
                    validateStatus={errors.totalprice ? 'error' : 'success'}
                  >
                    <Input
                      name="totalprice"
                      value={  data.totalprice === 0 ? "Ücretsiz" : data.totalprice }
                      readOnly={true}
                      onChange={(e) => setData({ ...data, totalprice: parseInt(e.target.value) })}
                      placeholder="Etkinlik Ücreti"
                      // type="number"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Ödeme Durumu"
                    required
                    help={errors.pay}
                    validateStatus={errors.pay ? 'error' : 'success'}
                  >
                    {/* <Switch
                      name="pay"
                      checked={data.pay}
                      checkedChildren="Ödendi"
                      unCheckedChildren="Ödenmedi"
                      onChange={(e) => setData({ ...data, pay: e })}
                      /> */}
                    <Select
                      name="pay"
                      value={data.pay}
                      onChange={(e) => setData({ ...data, pay: e })}
                      placeholder="Ödeme Durumu"
                      >
                        <Option value={paymentStatus[0]}>Ödendi</Option>
                        <Option value={paymentStatus[1]}>Ödenmedi</Option>
                        <Option value={paymentStatus[2]}>Rezervasyon Sonrası Ödeme</Option>
                        <Option value={paymentStatus[3]}>Nakit Ödeme</Option>
                        <Option value={paymentStatus[4]}>Pos Ödemesi</Option>
                      </Select>

                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Rezervasyon Durumu"
                    required
                    help={errors.active}
                    validateStatus={errors.active ? 'error' : 'success'}
                  >
                    <Switch
                      name="active"
                      checked={data.active}
                      checkedChildren="Aktif"
                      unCheckedChildren="Pasif"
                      onChange={(e) => setData({ ...data, active: e })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Bilet Sayısı"
                    required
                    help={errors.count}
                    validateStatus={errors.count ? 'error' : 'success'}
                  >
                    <Input
                      name="count"
                      value={data.count}
                      onChange={(e) => setData({ ...data, count: parseInt(e.target.value) })}
                      placeholder="Bilet Sayısı"
                      type="number"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button
                      style={{
                        backgroundColor: '#9eb23b',
                        borderColor: 'transparent',
                      }}
                      type="primary"
                      disabled={loading}
                      htmlType="submit"
                      size="large"
                      block
                    >
                      {' '}
                      {"Kaydet"}{' '}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            
          </Card>
        </div>
      </div>
    );
  }
  
  
  export default EventReservationDetail;
  