import React from 'react';

const qrCodeImage = ({ record }) => {
  const download = (e) => {
    fetch(e.target.href, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${record.unique_id}#${record.connector_id}.png`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      });
  };

  return (
    <div
      style={{
        width: '100%',
        marginBottom: '.7rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {' '}
      <div>
        <img src={record && record.qrMedia && record.qrMedia.url} alt="" />
        <a
          href={record && record.qrMedia && record.qrMedia.url}
          download
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => download(e)}
        >
          <i style={{ fontSize: '17px' }} className="fa-solid fa-download"></i>
        </a>
      </div>
    </div>
  );
};

export default React.memo(qrCodeImage);
