import React, { useState, useEffect } from 'react';
import { Modal, message, Row, Col, Form, Divider, Button } from 'antd';
import api from '../service/index';
import moment from 'moment';

export default (props) => {
  const { record, isModalVisible, setIsModalVisible } = props;
  const [log, setLog] = useState([]);
  const [meter, setMeter] = useState(false);

  const showModal = async () => {
    const data = await api
      .get(`/rest/charge-log-detail/${record.chargeId}`)
      .then(({ data }) => {
        if (data && data.result) {
          return data.result;
        }
      })
      .catch((err) => {
        message.error(err);
      });

    setLog(data);
  };

  useEffect(() => {
    showModal();
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        className="modal__info"
        title="Log"
        width={850}
        visible={isModalVisible}
        footer={null}
        onOk={handleOk}
        onCancel={handleOk}
      >
        <div style={{ padding: '0px 7px', fontWeight: 'bold' }}>
          <Row justify="space-between">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Kullanıcı Ad Soyad'}>
                {log.userName}
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Kart Numarası'}>
                {log.idTag}
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row justify="space-between">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Bayi'}>
                {log.dealerName}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12}}>
              <Form.Item style={{ color: 'red' }} label={'transaction Id'}>
                {log.transactionId}
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row justify="space-between">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Fiyat Adı'}>
                {log.priceName}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Fiyat Tarifesi'}>
                {log.priceAmount}
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Cihaz Unique Id'}>
                {log.unique_id}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Konnektör Id'}>
                {log.connectorId}
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row direction="row">
            <Col xs={{ span: 12 }} md={{ span: 12 }}>
              <Form.Item
                style={{ color: 'red' }}
                label={'Şarj Başlangıç Tarihi'}
              >
                {moment(log.chargeStart).format('DD-MM-YYYY HH:mm:ss')}
              </Form.Item>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Şarj Bitiş Tarihi'}>
                {moment(log.chargeStop).format('DD-MM-YYYY HH:mm:ss')}
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                style={{ color: 'red' }}
                label={'Ölçüm Başlangıç Değer'}
              >
                {log.meterStart} kWh
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Ölçüm Bitiş Değer'}>
                {log.meterStop} kWh
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row direction="row">
            <Col xs={{ span: 12 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'SoC Start'}>
                {log.startSoC} %
              </Form.Item>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'SoC Stop'}>
                {log.soc} %
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Yüklenen Değer'}>
                {log.loadedValue}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Ödeme Tutarı'}>
                {log.price}
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Button
            style={{ float: 'right', margin: '5px' }}
            type="danger"
            onClick={() => (meter === true ? setMeter(false) : setMeter(true))}
          >
            Ölçüm Değerleri
          </Button>
          <br />
          <br />
          <br />
          {meter && (
            <>
              {log &&
                log.meterValues &&
                log.meterValues.map((value, index) => (
                  <>
                    <Row direction="row" style={{ textAlign: 'center' }}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }} offset={5}>
                        <Form.Item
                          style={{ color: 'red' }}
                          label={'Başlangıç Tarihi'}
                        >
                          {moment(value.timestamp).format(
                            'DD-MM-YYYY HH:mm:ss',
                          )}
                        </Form.Item>
                      </Col>
                      <br />
                      <Button
                        style={{
                          float: 'right',
                          margin: '5px',
                          visibility: 'hidden',
                        }}
                        type="danger"
                        onClick={() =>
                          meter === true ? setMeter(false) : setMeter(true)
                        }
                      ></Button>
                      {meter && (
                        <>
                          {value &&
                            value.sampledValue &&
                            value.sampledValue.map((item) => (
                              <>
                                <Col xs={{ span: 24 }} md={{ span: 13 }}>
                                  <Form.Item
                                    style={{ color: 'red' }}
                                    label={'Measurand'}
                                  >
                                    {item.measurand}
                                  </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                  <Form.Item
                                    style={{ color: 'red' }}
                                    label={'Phase'}
                                  >
                                    {item.phase ? item.phase : '-'}
                                  </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 3 }}>
                                  <Form.Item
                                    style={{ color: 'red' }}
                                    label={'Unit'}
                                  >
                                    {item.unit}
                                  </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 3 }}>
                                  <Form.Item
                                    style={{ color: 'red' }}
                                    label={'Value'}
                                  >
                                    {item.value}
                                  </Form.Item>
                                </Col>
                              </>
                            ))}
                        </>
                      )}
                    </Row>
                  </>
                ))}
            </>
          )}

          <Divider style={{ borderWidth: 2, padding: '0px' }} solid />
        </div>
      </Modal>
    </>
  );
};
