import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm, message, Badge, Select } from 'antd';
import moment from 'moment';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getPathName } from '../stores/utils';
import lang from "../lang";

const QR = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [excelData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState("");
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let [user] = useGlobal('user');
  const {auth} = useSelector(state => state.auth)
  let path = props.location.pathname.split('/')[1];
  let pathName = getPathName(path);
  let [rooms, setRooms] = useState([])
  let [room, setRoom] = useState(null)

  let query = [];

  useEffect(() => {
    let run = async() => {
      let _serach = null;
      if (search.length > 0) {
        _serach = search
      }
  
      let restData = await api
        .post(`/rest/access/report`, { room, search: _serach })
        .then(({ data }) => {
          if (data.result) {
            setLoading(false);
            return data.result
          }
        });
      setData(restData);
  
      await api.post("/rest/room/find", {}).then( ({data}) => setRooms(data.result))
    }

    run()
  }, [room, search]);

  let exportToCSV = () => {
    let first = data[0];
    let headers = "FullName; Phone Number; Room; Date; People Count"
    let csv = ""
    
    let items = data.map(item => {
      return `${item.user.nameSurname}; ${item.user.phoneNumber}; ${item.roomType}; ${item.createdAt}; ${item.count || 1}`
    }).join("\n")

    csv = headers + "\n" + items

    // let first = data[0];
    // let headers = "Oda Adı;Oda Özelliği"
    // let csv = ""
    // if(first) {
    //   console.log(first)
    //   let items = data.map(item => {
    //     return `${item.nameSurname};${item.feature}`
    //   }).join("\n")

    //   csv = headers + "\n" + items
    // }
  
    var element = document.createElement("a");
    element.setAttribute("href", `data:text/plain;chartset=utf-8,${csv}`);
    element.setAttribute("download", "rooms.csv");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  

  let columns = [
    {
      title: lang.nameSurname,
      dataIndex: 'user',
      key: 'user',
      sortDirections: ['descend', 'ascend'],
      render (user) {
        return user.nameSurname
      }
    },
    {
      title: lang.phoneNumber,
      dataIndex: 'user',
      key: 'user',
      sortDirections: ['descend', 'ascend'],
      render (user) {
        return user.phoneNumber
      }
    },
    {
      title: lang.room,
      dataIndex: "roomType",
      key: "roomType",
      render (roomType) {
        let room = rooms.find(r => r._id == roomType);

        return room ? room.name : ""
      }
    },
    {
      title: lang.createdAt,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sortDirections: ['descend', 'ascend'],
      render (createdAt) {
        return moment(createdAt).format("HH:mm MM/dd/YYYY")
      }
    },
    {
      title: lang.peopleCount,
      dataIndex: 'count',
      key: 'count',
      sortDirections: ['descend', 'ascend'],
      render (count) {
        return count || 1
      }
    },
  ];



  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            QR
          </h1>
        </div>
        <div className="list-buttons py-5">
        <Button
                  type="danger"
                  icon={<ExportOutlined />}
                  size="large"
                  onClick={exportToCSV}
                  style={{ fontSize: '14px', height: '100%', marginRight: '10px' }}
                >
                  {
                  lang.exportout
                  }
          </Button>

        </div>
      </div>

      <div className="table-wrap">
        <div style={{display:"flex", width: 600 }} >
          <Select value={room} style={{width: 300, marginRight: 20}} onChange={v => setRoom(v)}>
            <Select.Option value={null} name={"All"}>{lang.All}</Select.Option>)
              {rooms.map(r => <Select.Option   key={r._id} value={r._id} name={r.name}>{r.name}</Select.Option>)}
          </Select>
        <Input style={{width: 300}}
            placeholder={lang.search}
            // onChange={onChange}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          pagination={{
            total: totalCount,
          }}
          rowKey="_id"
        />
      </div>
    </div>
  );
};

export default QR;
