import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm, message, Badge } from 'antd';
import moment from 'moment';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getPathName } from '../stores/utils';
import lang from "../lang";

const User = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [excelData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let [user] = useGlobal('user');
  const {auth} = useSelector(state => state.auth)
  let path = props.location.pathname.split('/')[1];
  let pathName = getPathName(path);
  let [rooms, setRooms] = useState([])

  let query = [];

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10000 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name, email, phone';
    }

    let restData = await api
      .get(`/rest/user?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows
          // return data.result.rows.map((item, key) => {
          //   if (item.active)
          //     item.active = <div className="true-circle"></div>;
          //   else item.active = <div className="false-circle"></div>;

          //   item.key = key;
          //   return item;
          // });
        }
      });
    setData(restData);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  useEffect(() => {
    api.get('/rest/room').then(({ data: { result, result_message } }) => {
      console.log("rooms", result.rows)
      setRooms(result.rows);
    })
  }, []);


  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    // api.delete(`/rest/user/${item_id}`, ({ data }) => {});
    // if(user.role !== "personel" && user.role !== "admin"){
    //   message.error("Bu işlemi yapmaya yetkiniz yok");
    //   return;
    // }
    api.delete("/rest/user/" +item_id).then(({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };
  let createQR = async (user_id) => {
    await api.post("/rest/qr/create", { user_id })
  };

  let exportToCSV = () => {
    let first = data[0];
    let headers = ""
    let csv = ""
    if(first) {
      let keys = Object.keys(first);
      headers = keys.join(";");
      let items = data.map(item => {
        return keys.map(key => item[key]).join(";")
      }).join("\n")

      csv = headers + "\n" + items
    }

    // let first = data[0];
    // let headers = "Oda Adı;Oda Özelliği"
    // let csv = ""
    // if(first) {
    //   console.log(first)
    //   let items = data.map(item => {
    //     return `${item.nameSurname};${item.feature}`
    //   }).join("\n")

    //   csv = headers + "\n" + items
    // }
  
    var element = document.createElement("a");
    element.setAttribute("href", `data:text/plain;chartset=utf-8,${csv}`);
    element.setAttribute("download", "rooms.csv");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  

  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/user/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  function turkishSort(a, b) {
    var alfabe =
      'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789';
    if (a.length === 0 || b.length === 0) {
      return a.length - b.length;
    }
    for (var i = 0; i < a.length && i < b.length; i++) {
      var ai = alfabe.indexOf(a[i]);
      var bi = alfabe.indexOf(b[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }

  let columns = [
    {
      title: lang.nameSurname,
      dataIndex: 'nameSurname',
      key: 'nameSurname',
      sorter: (a, b) => turkishSort(a.nameSurname, b.nameSurname),
      sortDirections: ['descend', 'ascend'],
      render (nameSurname) {
        return nameSurname ? nameSurname : <Badge status="error" count="Veri Yok" />
      }
    },
    {
      title: lang.rooms,
      dataIndex: "roomType",
      key: "roomType",
      sorter: (a, b) => turkishSort(a.roomType, b.roomType),
      render (roomType) {
        console.log({rooms, roomType})
        let names = roomType.map(id => {
          let room = rooms.find(r => r._id == id);
          console.log({room})
          if (room)
            return room.name
          else return ""
        }).join(",")

        return names
      }
    },
    {
      title: lang.phoneNumber,
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
      sortDirections: ['descend', 'ascend'],
      render (phoneNumber) {
        return phoneNumber ? phoneNumber : <Badge status="error" count="Veri Yok" />
      }
    },
    {
      title: lang.activity,
      dataIndex: 'isActive',
      key: 'isActive',
      render (isActive) {
        return isActive ? <div className="true-circle"></div> : <div className="false-circle"></div>
      }

    },

    
    {
      title: lang.transaction,
      key: 'action',
      align: 'center',
      width: 150,

      render: (text, record) => (
        <Space size="small">
          { !(auth && auth.find(a => a.number === user.role).restraintList.includes( "edit" )) && (
            <Link to={'/user/edit/' + record._id}>
              <Button 
                icon={<EditOutlined />}>
                  {lang.edit}
              </Button>
            </Link>
          )}
          {
            user.role === "admin" ? (
              !(auth && auth.find(a => a.number === user.role).restraintList.includes( "delete" )) && (
                <Popconfirm
                  onConfirm={() => deleteRow(record._id)}
                  title={lang.delete}
                  okText={lang.delete}
                  cancelText={lang.cancel}
                >
                <Button 
                  type="danger" icon={<DeleteOutlined />}>
                  {lang.delete}
                </Button>
              </Popconfirm>
              )
            ) : (
              <div></div>
            )
          }
          
          
        </Space>
      ),
    },
  ];



  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {lang.users}
          </h1>
        </div>
        <div className="list-buttons py-5">
        <Button
                  type="danger"
                  icon={<ExportOutlined />}
                  size="large"
                  onClick={exportToCSV}
                  style={{ fontSize: '14px', height: '100%', marginRight: '10px' }}
                >
                  {
                  lang.exportout
                  }
          </Button>

          {(!(auth && auth.find(a => a.number === user.role).restraintList.includes( "delete" )) && !isSmall) && 
                <Button
                  type="danger"
                  icon={<DeleteOutlined />}
                  size="large"
                  onClick={deleteSelectedRows}
                  style={{ fontSize: '14px', height: '100%', marginRight: '10px' }}
                >
                 {
                  lang.deleteselected
                 }
                </Button>
           }
         
          {!(auth && auth.find(a => a.number === user.role).restraintList.includes( "add" )) && (
            <Link to="/user/add" style={{ marginRight: '5px' }}>
              <Button
                type="light"
                style={{ fontSize: '14px', height: '100%' }}
                icon={<PlusOutlined />}
                size="large"
              >
                {lang.adduser}
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className="table-wrap">
       <Search
          placeholder="Arama..."
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
          rowKey="_id"
        />
      </div>
    </div>
  );
};

export default User;
