import React, { useState, useEffect} from "react";
import { Segmented } from "antd";

export default (props) => {
  let { record, setRecord, name } = props;

  const chunkSize = 5;
  let myListOfArrays = [];
  for (let i = 0; i < record["activeSlots"].length; i += chunkSize) {
      myListOfArrays.push(record["activeSlots"].slice(i, i + chunkSize));
  }



  const onChange = (value) => (
    console.log("onChange", value)
  )

  


  return myListOfArrays.map((array) => {
    return <>
    <Segmented
      options={array.map((time) => {
        return {
          label: time.start + ' - ' + time.end,
          value: time.start,
          disabled: time.active ? false : true
        }
      })}
      size="middle"
      selected={record[name]}
      onChange={(value) => {onChange(value)}}
      defaultValue={0}
    />
    <div></div>
    </>
  })
}