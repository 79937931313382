import React, { useState } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';
import { useEffect } from 'react';

export default (props) => {
  let { record, setRecord, name, mode } = props;
  let [country, setCountry] = useState([]);
  let _params = { sort: '-createdAt', page: 1, pageSize: 100 };
  let languagesCms = props.languagesCms;

  useEffect(() => {
    api.get(`/rest/country?${qs.stringify(_params)}`).then(({ data }) => {
      if (data && data.result && data.result.length > 0) {
        let dbCountry = data.result.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        setCountry(dbCountry);
      }
    });
  }, []);

  return (
    <Select
      value={(record && record[name]) || []}
      showSearch={true}
      mode={mode}
      placeholder={languagesCms.SELECT_COUNTRY}
      onChange={(v) =>
        setRecord({ ...record, [name]: v, cityId: null, districtId: null })
      }
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {country &&
        country.map((b) => (
          <Select.Option key={b.name} value={b._id}>
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
