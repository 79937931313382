import { DeleteOutlined,EditOutlined,PlusOutlined } from '@ant-design/icons';
import { Badge, Button, Popconfirm, Space, Table, Input } from 'antd';
import qs from 'qs';
import moment from 'moment';
import 'moment/locale/tr';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../components/Loading';
import api from '../service';
import { turkishSort } from '../stores/utils';
import { getPathName } from '../stores/utils';
moment.locale('tr');

function Settings(props) {
	const { Search } = Input;
	const [settings, setSettings] = useState([]);
  const [search, setSearch] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	let path = props.location.pathname.split('/')[1];
	let pathName = getPathName(path);



	const get = async (page, filters, sorter) => {
			api.get(`/rest/settings`).then(({ data }) => {
				if(data && data.result && data.result.rows){
					setSettings(data.result.rows);
				}
			})
			.then(() => {
				setLoading(false);
			})
	}

	



	let rowSelection = {
			onChange: (selectedRowKeys, selectedRows) => {
				setSelectedRows(selectedRows);
			},
			onSelect: (record, selected, rows) => {
				setSelectedRows(rows);
			},
			onSelectAll: (selected, selectedRows, changeRows) => {
				setSelectedRows(selectedRows);
			},
		};
	
	useEffect(() => {
	setLoading(true);
	get();
	}, []);
	
	const deleteRow = async (item_id) => {
		console.log(item_id,"item_id")
	// api.delete(`/rest/settings/${item_id}`);
	// setLoading(true);
	// get();
	};
	
	const deleteSelectedRows = async () => {
		selectedRows.forEach((item) => {
			api.delete(`/rest/settings/`);
			let newData = settings.filter((el) => el._id !== item._id);
			setSettings(newData);
			get();
		});
	};

	const columns = [
		{
			title: 'Başlık',
			dataIndex: 'title',
			key: 'title',
			render (title) {
				return title ? title : '-'
			}
		},
		{
			title: 'İçerik',
			dataIndex: 'content',
			key: 'content',
			render (content) {
				return content ? content : '-'
			}
		},
		{
			title: 'İşlemler',
			dataIndex: 'action',
			key: 'action',
			render: (text, record) => (
				<div className="table-actions">
				<Space size="small">
					<Link to={'/settings/edit/' + record._id}>
						<Button
							icon={<EditOutlined />}>Düzenle</Button>
					</Link>
					<Popconfirm
						id='delete-popconfirm'
						title="Bu kaydı silmek istediğinize emin misiniz? Bu işlem mobil uygulamada da etkili olacaktır."
						onConfirm={() => deleteRow(record._id)}
						okText="Evet"
						cancelText="Hayır"
					>
						<Button
							type="danger"
							icon={<DeleteOutlined />}>Sil</Button>
					</Popconfirm>
				</Space>
			</div>
			)
		}
	]

	let handleTableChange = async (page, filters, sorter) => {
		get(page, filters, sorter);
	};

	let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

	return (
		<div>
			{loading && <Spinner />}
			<div className="list-head">
				<div className="list-title">
					<h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
						{pathName ? pathName : ''}{' '}
					</h1>
				</div>
				<div className="list-buttons py-5">
					<Button
						type="danger"
						icon={<DeleteOutlined />}
						size="middle"
						onClick={deleteSelectedRows}
						style={{
							fontSize: '1rem',
							height: '100%',
							marginRight: '10px',
						}}
					>
						Seçilenleri Sil
					</Button>

					
						<Link to="/settings/add" style={{ marginRight: '5px' }}>
							<Button
								type="text"
								icon={<PlusOutlined />}
								size="middle"
								style={{
									fontSize: '1rem',
									height: '100%',
									marginRight: '10px',
									backgroundColor: '#1486a6',
									color: 'white'
								}}
							>
							Yeni Ekle
							</Button>
						</Link>
				</div>
			</div>

			<div className="table-wrap">
				<div className="table-wrapper">
					<Search
						placeholder="Arama..."
						onChange={onChange}
						onSearch={(v) => {
							setSearch(v);
							get();
						}}
					/>
					<Table
						dataSource={settings}
						columns={columns}
						onChange={handleTableChange}
						pagination={{
							total: totalCount,
							pageSize: 10,
							showSizeChanger: true,
							pageSizeOptions: ['10', '20', '50', '100'],
						}}
						rowSelection={rowSelection}
						rowKey={(record) => record._id}
					/>
				</div>
			</div>
		</div>
	);
}

export default Settings;